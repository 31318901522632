import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { mediaQuery as mQ } from '../../lib';

interface StyleProps {
  isActive: boolean;
}

interface TabProps {
  tabs: string[];
  children: ReactNode[];
  notifyParent?: React.Dispatch<React.SetStateAction<string>>;
}

const Flex = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;

const Tab = styled.div<StyleProps>`
  opacity: 1;
  ${p => p.isActive && 'border-bottom: 4px solid #83eece;'}
  ${p => p.isActive && 'font-family: Galano Grotesque Bold;'}
  color: ${p => (p.isActive ? '#000000' : '#767676')};
  cursor: pointer;
  font-size: var(--fontSize4);
  padding: 11px 0;
  text-align: center;
  width: 140px;
`;

const Divider = styled.div`
  height: 1px;
  border-top: 1px solid #e7e7f4;
  margin: 0 20px;
`;

const Box = styled.div<StyleProps>`
  display: ${p => (p.isActive ? 'block' : 'none')};
  ${mQ.md`
    min-width: 500px;
    min-height: 200px;
  `}
`;

const Wrapper = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 17px #0000000d;
  border-radius: 10px;
  opacity: 1;
`;

const Tabs = ({ tabs, children, notifyParent }: TabProps) => {
  const [active, setActive] = useState(0);

  //enacts optional method to notify parent what tab is selected.
  useEffect(() => {
    if (notifyParent !== undefined) {
      notifyParent(tabs[active]);
    }
  }, [active, setActive, children, notifyParent, tabs]);

  return (
    <Wrapper>
      <Flex>
        {tabs.map((tab, idx) => (
          <Tab
            key={idx}
            isActive={idx === active}
            onClick={() => {
              setActive(idx);
            }}
          >
            {tab}
          </Tab>
        ))}
      </Flex>
      <Divider />
      {children.map((child, idx) => (
        <Box key={idx} isActive={idx === active}>
          {child}
        </Box>
      ))}
    </Wrapper>
  );
};

export default Tabs;
