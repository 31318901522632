import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import Skeleton from 'react-loading-skeleton';
import { mediaQuery as mQ } from '../../lib';
import BackArrow from '../../assets/images/arrow.png';

const Container = styled.div`
  cursor: pointer;
  display: flex;
`;

const BackArrowImg = styled.img`
  width: 15px;
  height: 28px;
  margin-right: 20px;
`;

const Title = styled.h1`
  font-size: var(--fontSize1);
  font-weight: var(--fontWeightMedium);
  line-height: 28px;
  margin: 0;
  ${mQ.sm`
    font-size: 3.6rem;
  `}
`;

const Subtitle = styled.div`
  display: none;
  font-size: var(--fontSize4);
  ${mQ.sm`
    display: block;
    margin-top: 1em;
  `}
`;

interface PageTitleProps {
  title: string;
  subtitle?: string;
}

const PageTitle: React.FC<PageTitleProps> = ({
  title,
  subtitle
}: PageTitleProps): React.ReactElement => {
  const navigate = useNavigate();
  const { trackEvent } = useTracking();
  const titleOnClick = () => {
    trackEvent({ event: 'Page Title Clicked' });
    navigate(-1);
  };
  return (
    <Container onClick={titleOnClick}>
      <BackArrowImg src={BackArrow} />
      <div>
        <Title>{title ? title : <Skeleton width="15em" />}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </div>
    </Container>
  );
};

export default PageTitle;
