import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  ErrorBoundary,
  PageFooter,
  PageHeader,
  mediaQuery as mQ,
  useCriticalError
} from '@bazaar/components';
import { Outlet } from 'react-router-dom';
import { AppDrawerContext } from '../../contexts/AppDrawer';
import { usePartiesAPI } from '../../contexts/PartiesAPI';
import Error from '../pages/Error';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const FullHeight = styled.div`
  flex-grow: 1;
`;
const Container = styled.div`
  flex: 1 0 auto;
  ${mQ.lg`
    margin: 0 auto 0 272px;
  `}
`;
const Page = styled.main`
  min-height: 70vh;
  margin: 0 auto 3rem;
  padding: 16px;
  ${mQ.lg`
    max-width: 680px;
    padding: 24px;
    padding-right: 0;
  `}
`;

const StyledFooter = styled(PageFooter)`
  ${mQ.lg`
    margin-left: 272px;
  `}
`;

const AppHeader = () => {
  const { toggleDrawer } = useContext(AppDrawerContext);
  const { hasError } = useCriticalError();
  const { partiesData } = usePartiesAPI();
  const name = partiesData?.user.name;
  const businessName = partiesData?.parties[0].businesses?.[0].legalName;
  return (
    <PageHeader
      toggleDrawer={toggleDrawer}
      user={{ name, businessName }}
      hideUserDetails={hasError}
      forceRefreshOnError={hasError}
    />
  );
};

const AppLayout = () => {
  return (
    <PageWrapper>
      <FullHeight>
        <Container>
          <ErrorBoundary
            render={() => (
              <PageHeader hideUserDetails={true} forceRefreshOnError={true} />
            )}
          >
            <AppHeader />
          </ErrorBoundary>
          <Page>
            <ErrorBoundary component={Error}>
              <Outlet />
            </ErrorBoundary>
          </Page>
        </Container>
      </FullHeight>
      <StyledFooter
        privacyPolicyHref="https://www.ondeck.com/privacy"
        securityPolicyHref="https://www.ondeck.com/security-policy"
      />
    </PageWrapper>
  );
};

export default AppLayout;
