import React from 'react';
import { string } from 'prop-types';
import { ReactComponent as Icon } from './svg/LockShield.svg';

const LockShield: React.FC = props => <Icon {...props} />;

LockShield.propTypes = {
  fill: string,
  width: string,
  height: string
};

LockShield.defaultProps = {
  fill: '#0074FF',
  width: '39px',
  height: '48px'
};

export default LockShield;
