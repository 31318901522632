import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { usePartiesAPI } from 'contexts/PartiesAPI';
import { Tabs } from '@bazaar/components';
import LoanOTPView from './LoanOTPView';
import { mediaQuery } from '@bazaar/components';
import { Button } from '@bazaar/components';
import { Alert } from '@bazaar/components';
import useFetch from 'use-http';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useProfileAPI } from 'contexts/ProfileAPI';
import { RenewalNotification } from '@bazaar/components';
import { Link } from '@bazaar/components';
import { FormatForPhoneNumber } from 'router/functions/FormatForPhoneNumber';
import { useAuth } from '../../../contexts/Auth';
import { get } from '../../../lib';

const GridContainer = styled.div`
  display: grid;
  grid-row-gap: 2em;
`;

const Wrapper = styled.div`
  margin-top: 3em;
`;

const ErrorMessage = styled.div`
  font-size: var(--fontSize5);
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;

  margin-top: 1em;

  > button {
    margin-bottom: 1em;
    margin-right: 1em;

    &:disabled {
      cursor: not-allowed;
    }
  }

  ${mediaQuery.md`
    flex-direction: row;
    > button {
      margin-bottom: 0em;
    }
  `}
`;

const StyledButton = styled(Button)`
  width: 300px;
  margin-bottom: 2rem;
  :disabled {
    background: #dddddd;
    color: #616161;
    border: none;
  }
`;

interface AlertProps {
  color?: string;
  children: React.ReactNode;
  title?: React.ReactNode;
}

const Payment = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [locDetails, setLOCDetails] = useState<ILOC>();
  const { partiesData } = usePartiesAPI();
  const { addCredentials, loginServerEnabled } = useAuth();
  const { profileDetails } = useProfileAPI();

  const [drawSpinner, setDrawSpinner] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState('');
  const [termLoanDetailsLoaded, setTermLoanDetailsLoaded] = useState(false);
  const [locDetailsLoaded, setLOCDetailsLoaded] = useState(false);
  const componentMounted = useRef(true);
  const [email, setEmail] = useState<string>();
  const [alert, setAlert] = useState<AlertProps>();
  const [customPaymentAmount, setCustomPaymentAmount] = useState<number>(0);
  const [customPaymentDate, setCustomPaymentDate] = useState<string>(
    moment().format()
  );
  const [termLoanDetails, setTermLoanDetails] = useState<
    TermLoan | undefined
  >();

  const setLoanData = (result: TermLoan | LOC, type: string) => {
    if (componentMounted.current) {
      if (type === 'FIXED_TERM') {
        setTermLoanDetails(result as TermLoan);
        setTermLoanDetailsLoaded(true);
      } else {
        setLOCDetails(result as LOC);
        setLOCDetailsLoaded(true);
      }
    }
  };

  const fetchLoanDetails = useCallback(
    (href: string) => get(href, loginServerEnabled, addCredentials),
    [addCredentials, loginServerEnabled]
  );

  const getActiveLoan = useCallback(
    async (type: string, loans: any) => {
      const results: (TermLoan | LOC)[] = await Promise.all(
        loans.map(async (loan: any) => fetchLoanDetails(loan.href))
      );
      const activeLoanIdx: number = results.findIndex(
        (loan: TermLoan | LOC) => loan.status === 'ACTIVE'
      );
      if (loans[activeLoanIdx]) {
        setLoanData(results[activeLoanIdx], type);
      }
    },
    [fetchLoanDetails]
  );

  useEffect(() => {
    const termLoans = partiesData?.parties[0].loans.filter(
      (loan: any) => loan.loanType === 'FIXED_TERM'
    );
    const loC = partiesData?.parties[0].loans.filter(
      (loan: any) => loan.loanType === 'LINE_OF_CREDIT'
    );
    if (termLoans.length > 0) getActiveLoan('FIXED_TERM', termLoans);
    if (loC.length > 0) getActiveLoan('LINE_OF_CREDIT', loC);
    getEmail();
  }, [profileDetails]);

  const getLoanId = () => {
    if (selectedTab === 'Line of Credit') {
      return locDetails?.id;
    }
    if (selectedTab === 'Term Loan') {
      return termLoanDetails?.id;
    }
    return '';
  };

  const { post: submitOTP, response: otpRes } = useFetch(
    window.env.REACT_APP_API_URL + '/otp'
  );

  const getEmail = async () => {
    const email = await profileDetails?.contacts?.find(
      (contact: { type: string }) => contact.type === 'EMAIL_ADDRESS'
    );
    if (email) {
      setEmail(email.value);
    }
  };

  const cancelHandler = () => {
    // send user back to the previous page
    navigate(-1);
  };

  const submitHandler = async () => {
    setDrawSpinner(true);
    let createOTPReq = JSON.stringify({
      email: email,
      loanId: getLoanId(),
      paymentAmount: customPaymentAmount,
      paymentDate: customPaymentDate //hard coded to current day
    });
    await submitOTP(JSON.parse(createOTPReq));
    if (otpRes.ok) {
      navigate('/', {
        state: {
          color: 'green',
          children: intl.formatMessage({
            id:
              selectedTab === 'Term Loan'
                ? 'payment.submit.term.success.msg'
                : 'payment.submit.loc.success.msg'
          }),
          title: intl.formatMessage({
            id: 'payment.submit.success.msg.title'
          })
        }
      });
    } else {
      let errorMsg = (
        <div>
          <ErrorMessage>
            <FormattedMessage
              id="payment.submit.failure.msg"
              values={{
                br: <br />,
                tel: FormatForPhoneNumber
              }}
            />
          </ErrorMessage>
        </div>
      );
      setAlert({ color: 'red', children: errorMsg });
    }
    setDrawSpinner(false);
  };

  return (
    <>
      {/* this is both the title of the page and the back button */}
      <RenewalNotification
        bgColor="blue"
        title={intl.formatMessage({ id: 'payment.title' })}
        dismissMessage=""
        height="150px"
      />
      {alert?.children && (
        <Alert color={alert?.color} title={alert?.title} hideOnDismiss>
          {alert?.children}
        </Alert>
      )}
      <GridContainer id="OTP_Tabs">
        {termLoanDetailsLoaded && locDetailsLoaded && (
          <Tabs
            tabs={['Line of Credit', 'Term Loan']}
            notifyParent={setSelectedTab}
          >
            {[
              <LoanOTPView
                key="loc"
                loanId={locDetails?.id}
                loanType="loc"
                setCustomPaymentAmount={setCustomPaymentAmount}
                setCustomPaymentDate={setCustomPaymentDate}
                setShowSubmitButton={setShowSubmitButton}
                setAlert={setAlert}
              />,
              <LoanOTPView
                key="termloan"
                loanId={termLoanDetails?.id}
                loanType="term"
                setCustomPaymentAmount={setCustomPaymentAmount}
                setCustomPaymentDate={setCustomPaymentDate}
                setShowSubmitButton={setShowSubmitButton}
                setAlert={setAlert}
              />
            ]}
          </Tabs>
        )}
        {termLoanDetailsLoaded && !locDetailsLoaded && (
          <Tabs tabs={['Term Loan']} notifyParent={setSelectedTab}>
            {[
              <LoanOTPView
                key="termloan"
                loanId={termLoanDetails?.id}
                loanType="term"
                setCustomPaymentAmount={setCustomPaymentAmount}
                setCustomPaymentDate={setCustomPaymentDate}
                setShowSubmitButton={setShowSubmitButton}
                setAlert={setAlert}
              />
            ]}
          </Tabs>
        )}
        {locDetailsLoaded && !termLoanDetailsLoaded && (
          <Tabs tabs={['Line of Credit']} notifyParent={setSelectedTab}>
            {[
              <LoanOTPView
                key="loc"
                loanId={locDetails?.id}
                loanType="loc"
                setCustomPaymentAmount={setCustomPaymentAmount}
                setCustomPaymentDate={setCustomPaymentDate}
                setShowSubmitButton={setShowSubmitButton}
                setAlert={setAlert}
              />
            ]}
          </Tabs>
        )}
      </GridContainer>
      <Wrapper>
        {/* user's available actions */}
        <ButtonRow>
          <StyledButton
            id="OTP_CancelButton"
            variant="light"
            onClick={cancelHandler}
            type="button"
          >
            <FormattedMessage id="payment.button.cancel" />
          </StyledButton>
          <StyledButton
            id="OTP_SubmitButton"
            variant="spinner"
            disabled={!showSubmitButton}
            showSpinner={drawSpinner}
            onClick={submitHandler}
          >
            <FormattedMessage id="payment.button.submit" />
          </StyledButton>
        </ButtonRow>
      </Wrapper>
    </>
  );
};

export default Payment;
