import React from 'react';
import { FormatNumberOptions, FormattedNumber } from 'react-intl';

interface FormattedMoneyProps {
  value: number;
  format?: string;
  children?: (formattedNumber: string) => React.ReactElement;
}

const FormattedMoney: React.FC<FormattedMoneyProps> = (
  props: FormatNumberOptions & FormattedMoneyProps
) => {
  // eslint-disable-next-line react/style-prop-object
  return <FormattedNumber style="currency" currency="USD" {...props} />;
};

export default FormattedMoney;
