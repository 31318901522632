import React from 'react';
import styled from 'styled-components';
import {
  useMedia,
  defaultBreakpoints,
  getMediaQuery,
  Logo,
  Link
} from '@ondeck/silkworm';
import { useAuth } from '../contexts/Auth';

const mQ = getMediaQuery(defaultBreakpoints);

interface IWrapperProps {
  bgColor?: string;
}

const Wrapper = styled.div<IWrapperProps>`
  align-items: center;
  background-color: ${p => p.bgColor || 'inherit'};
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  ${mQ.lg`
    padding: 24px;
  `}
`;

const StyledLink = styled(Link)`
  color: var(--color-white);
  cursor: pointer;
  font-family: var(--fontSemiBold);
  &:hover {
    color: var(--color-white);
  }
`;

type TPageHeaderProps = {
  bgColor?: string;
  onFill?: string;
  deckFill?: string;
};

const PageHeader = ({
  bgColor,
  onFill = '#000082',
  deckFill = '#000082'
}: TPageHeaderProps) => {
  const { isAuthenticated, logout } = useAuth();
  const isSmall = useMedia([`(min-width: ${defaultBreakpoints.lg})`]);
  const profileComp = isAuthenticated && (
    <StyledLink light onClick={() => logout('/sign-out')}>
      Sign Out
    </StyledLink>
  );

  return (
    <Wrapper bgColor={bgColor}>
      <Logo
        onFill={onFill}
        deckFill={deckFill}
        height={isSmall ? '24px' : '32px'}
        width={isSmall ? '113px' : '151px'}
      />
      {profileComp}
    </Wrapper>
  );
};

export default PageHeader;
