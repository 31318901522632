import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import { usePrevious } from 'react-hanger';
import scrollIntoViewIfNeeded from 'smooth-scroll-into-view-if-needed';
import AccordionHeading from './AccordionHeading';
import AccordionPanel from './AccordionPanel';

interface AccordionItemProps {
  animationDuration: number;
  animationEasing: string;
  children: ReactNode;
  index: number;
  isActive?: boolean;
  onItemToggle: (index: number, obj: any) => void;
  scrollIntoView?: boolean;
  toggleItemActive: () => void;
  title: string;
  subtitle: string;
}

const AccordionItem: React.FC<AccordionItemProps> = ({
  animationDuration = 300,
  animationEasing = 'ease-in-out',
  children,
  index,
  isActive,
  onItemToggle = () => null,
  scrollIntoView,
  toggleItemActive,
  title,
  subtitle
}: AccordionItemProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const wasActive = usePrevious(isActive);

  useEffect(() => {
    if (wasActive !== isActive && typeof wasActive !== 'undefined') {
      onItemToggle(index, { active: isActive });
    }
  }, [index, isActive, onItemToggle, wasActive]);
  const scrollToContent = useCallback(() => {
    if (!wasActive && isActive && scrollIntoView) {
      scrollIntoViewIfNeeded(containerRef.current as Element, {
        block: 'nearest',
        behavior: 'smooth',
        scrollMode: 'if-needed'
      });
    }
  }, [isActive, scrollIntoView, wasActive]);
  return (
    <div ref={containerRef} id="container">
      <AccordionHeading
        index={index}
        isActive={isActive}
        toggleItemActive={toggleItemActive}
        title={title}
        subtitle={subtitle}
      />
      <AccordionPanel
        isActive={isActive}
        animationDuration={animationDuration}
        animationEasing={animationEasing}
        onAnimationEnd={scrollToContent}
      >
        {children}
      </AccordionPanel>
    </div>
  );
};

export default AccordionItem;
