import React, { AllHTMLAttributes, RefCallback } from 'react';
import styled, { css } from 'styled-components';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

interface InputProps {
  beforeContent?: string;
  children?: React.ReactNode;
  error?: boolean;

  mask?(): string;

  number?: boolean;
  postfix?: string | React.ReactElement;
  prefix?: string | React.ReactElement;
}

const Field = styled.div`
  display: inline-block;
  margin-right: 8px;
  margin-top: 25px;
`;
const Label = styled.label`
  display: inline-block;
  font-size: inherit;
`;
const Wrapper = styled.div`
  align-items: center;
  display: flex;
  margin: 0 0 5px;
  position: relative;
`;
const StyledInput = styled.input<InputProps>`
  border: 1px solid ${p => (p.error ? '#d0021b' : '#ccc')};
  border-radius: 3px;
  box-sizing: border-box;
  color: #4a4a4a;
  display: block;
  font-size: 18px;
  height: 40px;
  line-height: 1.5em;
  padding: 8px ${p => (p.postfix ? '26px' : '8px')} 8px
    ${p => (p.prefix ? '10px' : '8px')};
  width: 100%;
`;
const prePostfixStyles = css<{ beforeContent?: string }>`
  color: #4a4a4a;
  font-size: 2.4rem;
  position: absolute;
  ::before {
    content: '${p => p.beforeContent}';
  }
`;
const Prefix = styled.span`
  ${prePostfixStyles}
  left: 8px;
`;
const Postfix = styled.span`
  ${prePostfixStyles}
  right: 8px;
`;

const Input = React.forwardRef(
  (
    {
      children,
      className,
      id,
      mask,
      number,
      prefix,
      postfix,
      ...inputProps
    }: InputProps & AllHTMLAttributes<HTMLInputElement>,
    forwardRef: any
  ) => (
    <Field className={className}>
      <Label className="label" htmlFor={id}>
        {children}
      </Label>
      <Wrapper>
        {prefix && typeof prefix === 'string' ? (
          <Prefix className="prefix" beforeContent={prefix} />
        ) : (
          <Prefix className="prefix">{prefix}</Prefix>
        )}
        <MaskedInput
          id={id}
          mask={number ? createNumberMask({ prefix: '' }) : mask || false}
          prefix={prefix}
          postfix={postfix}
          {...inputProps}
          // react-hook-form depends on registering individual inputs using refs.
          // So does the library we use to perform masking (react-text-mask)
          // The following lines of code enables us to both forward a ref for react-hook-form
          // (or anything else) to use, and still support masking functionality.
          render={(maskRef: RefCallback<any>, props: any) => (
            <StyledInput
              // Neat.
              ref={inputRef => {
                forwardRef && forwardRef(inputRef);
                maskRef(inputRef);
              }}
              className="styled-input"
              {...props}
            />
          )}
        />
        {postfix && typeof postfix === 'string' ? (
          <Postfix className="postfix" beforeContent={postfix} />
        ) : (
          <Postfix>{postfix}</Postfix>
        )}
      </Wrapper>
    </Field>
  )
);

Input.displayName = 'Input';
export default Input;
