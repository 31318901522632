import React from 'react';
import styled from 'styled-components';

interface UserWidgetStyleProps {
  small?: boolean;
}

const Wrapper = styled.div<UserWidgetStyleProps>`
  display: inline-block;
  height: var(--avatar-size, ${p => (p.small ? '40px' : '96px')});
  width: var(--avatar-size, ${p => (p.small ? '40px' : '96px')});
  padding: var(--avatar-padding, ${p => (p.small ? '2px' : '4px')});
  position: relative;
  ${p => p.onClick && 'cursor: pointer;'}
`;

const StyledProgressBar = styled.div<UserWidgetStyleProps>`
  background: transparent;
  border-color: var(--color-accent-avatar);
  border-radius: 50%;
  border-style: solid;
  border-width: ${p => (p.small ? '2px' : '4px')};
  height: 100%;
  padding: ${p => (p.small ? '2px' : '6px')};
  width: 100%;
`;

const Disc = styled.div<UserWidgetStyleProps>`
  background: linear-gradient(#f9f9f9, #fff);
  border-color: #fff;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.1);
  color: var(--color-accent-avatar);
  display: flex;
  font-size: 24px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  user-select: none;
`;

interface UserWidgetProps {
  name?: string;
  onClick?: (e: React.MouseEvent) => void;
  small?: boolean;
  showProgress?: boolean;
}

const UserWidget: React.FC<UserWidgetProps> = ({
  name,
  showProgress,
  small,
  ...props
}: UserWidgetProps): React.ReactElement => {
  const initial = name?.length ? name[0].toUpperCase() : name;
  return (
    <Wrapper small={small} {...props} data-testid="avatar-wrapper">
      {showProgress ? (
        <StyledProgressBar small={small}>
          <Disc small={small}>{initial}</Disc>
        </StyledProgressBar>
      ) : (
        <Disc small={small}>{initial}</Disc>
      )}
    </Wrapper>
  );
};

UserWidget.defaultProps = {
  name: ''
};

export default UserWidget;
