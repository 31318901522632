import React, { ReactNode } from 'react';
import styled from 'styled-components';
import RadioButton from '../RadioButton/RadioButton';

interface FundingCardProps {
  onClick?: (val?: string) => void;
  checked?: boolean;
  value?: string;
  disabled?: boolean;
  recommended?: boolean;
  labelText?: string;
  hideRadioButton?: boolean;
  children: ReactNode;
}

export const Card = styled.div<FundingCardProps>`
  background: ${p => (p.checked ? '#f3f6fa' : '#fff')};
  border: ${p => (p.checked ? '2px solid #006beb' : '1px solid #C6C2C2')};
  border-radius: 10px;
  margin: 0 20px 15px 0;
  flex: 1;
  min-height: 130px;
  cursor: pointer;
  font-size: var(--fontSize5);
  color: #000;
  overflow: auto;
  ${p =>
    p.disabled &&
    'cursor: default; border: 1px solid #cccccc; background: #dddddd; color: #616161'}
`;

export const FlexWrapper = styled.div`
  display: flex;
  padding: 20px 30px 10px 5px;
`;

const LabelText = styled.div`
  font-weight: var(--fontWeightSemiBold);
`;
const LabelContent = styled.div`
  padding: 8px 0;
`;

const RecommendedLabel = styled.div`
  line-height: 16px;
  width: 115px;
  background: var(--color-blue);
  color: #fff;
  padding: 0 8px;
  font-weight: var(--fontWeightSemiBold);
  font-size: var(--fontSize6);
`;
const Arrow = styled.div`
  width: 0;
  height: 0;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 7px solid #006beb;
`;

const FundingCard: React.FC<FundingCardProps> = ({
  onClick,
  checked,
  value,
  labelText,
  children,
  recommended,
  disabled,
  hideRadioButton
}: FundingCardProps): React.ReactElement => {
  return (
    <Card
      disabled={disabled}
      checked={checked}
      onClick={() => !disabled && onClick && onClick(value)}
    >
      <FlexWrapper>
        <div style={{ minWidth: '25px' }}>
          {!disabled && !hideRadioButton && (
            <RadioButton checked={checked} value={value} />
          )}
        </div>
        <div>
          <LabelText>{labelText}</LabelText>
          <LabelContent>{children}</LabelContent>
        </div>
      </FlexWrapper>
      {!disabled && recommended && (
        <div style={{ display: 'flex' }}>
          <RecommendedLabel>Recommended</RecommendedLabel>
          <Arrow />
        </div>
      )}
    </Card>
  );
};

export default FundingCard;
