import React, { ReactNode } from 'react';
import AnimateHeight from 'react-animate-height';

interface AccordionPanelProps {
  children: ReactNode;
  isActive?: boolean;
  animationEasing: string;
  animationDuration: number;
  onAnimationEnd: () => void;
}

const AccordionPanel: React.FC<AccordionPanelProps> = ({
  children,
  isActive,
  animationEasing,
  animationDuration,
  onAnimationEnd
}: AccordionPanelProps) => {
  const height = isActive ? 'auto' : 0;

  return (
    <AnimateHeight
      easing={animationEasing}
      duration={animationDuration}
      onAnimationEnd={onAnimationEnd}
      height={height}
    >
      <div role="region" tabIndex={-1}>
        {typeof children === 'function' ? children({ isActive }) : children}
      </div>
    </AnimateHeight>
  );
};

export default AccordionPanel;
