import React from 'react';
import styled from 'styled-components';
import InfoIcon from '../../assets/images/Icon_Information.svg';

interface TooltipProps {
  children?: React.ReactNode;
  position?: string;
  tooltipWidth?: string;
}

const StyledImage = styled.img`
  height: 15px;
  width: 15px;
`;

const DownArrow = styled.span`
  visibility: hidden;
  position: absolute;
  margin: 5px -13px;
  bottom: 70%;
  border-width: 5px;
  border-style: solid;
  border-color: var(--color-saturated-blue) transparent transparent transparent;
`;

const TooltipText = styled.span<TooltipProps>`
  font-size: 12px;
  visibility: hidden;
  text-align: left;
  background: var(--color-saturated-blue);
  color: var(--color-white);
  border-radius: 3px;
  padding: 10px;
  position: absolute;
  z-index: 10;
  bottom: 168%;
  min-width: ${props => (props.tooltipWidth ? props.tooltipWidth : '100px')};
  ${props => (props.position === 'right' ? 'right: -45%' : 'left: -45%')};
`;

const StyledTooltip = styled.div`
  display: flex;
  margin: 1px 5px;
  justify-content: center;
  cursor: pointer;
  position: relative;
  height: 15px;
  width: 15px;
  &:hover {
    ${DownArrow}, ${TooltipText} {
      visibility: visible;
    }
  }
`;

const Tooltip = ({
  children,
  position = 'right',
  tooltipWidth,
  ...props
}: TooltipProps): React.ReactElement => {
  return (
    <StyledTooltip {...props}>
      <StyledImage src={InfoIcon} />
      <DownArrow />
      <TooltipText position={position} tooltipWidth={tooltipWidth}>
        {children}
      </TooltipText>
    </StyledTooltip>
  );
};

export default Tooltip;
