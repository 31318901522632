import React from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { mediaQuery as mQ } from '../../lib';

import Card, { CardContent } from '../Card/Card';
import Link from '../Link/Link';
import UserAvatar from '../UserAvatar/UserAvatar';

import BackArrow from '../../assets/images/arrow.png';
import BusinessIcon from '../../assets/images/Icon_Business.svg';

const StyledCard = styled(Card)`
  padding: 0;
  --card-content-padding: 32px 8px 24px;
  ${mQ.md`
    --card-content-padding: 32px 24px;
  `}
`;

const TopContent = styled.div`
  --avatar-size: 80px;
  --avatar-padding: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${mQ.md`
    --avatar-size: 88px;
    flex-direction: row;
  `}
`;

const NameWrapper = styled.div`
  text-align: center;
  margin: 1em 0 0;
  ${mQ.md`
    margin: 0 0 0 1em;
    text-align: left;
  `}
`;

const NameHeading = styled.h2`
  font-size: var(--fontSize4);
  margin: 0;
  ${mQ.md`
    font-size: 3.6rem;
    font-weight: 500;
  `}
`;

const Email = styled.div`
  font-size: var(--fontSize5);
  color: var(--color-muted);
  ${mQ.md`
    font-size: inherit;
  `}
`;

const BusinessLink = styled(Link)`
  align-items: center;
  background-color: var(--color-gray);
  border-radius: 12px;
  color: inherit;
  display: flex;
  margin-top: 1.5em;
  padding: 1em 0.5em;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
  ${mQ.md`
    font-size: var(--fontSize5);
    font-weight: 500;
    margin-top: 2.285em;
    padding: 1em;
  `}
`;

const BusinessNameWrapper = styled.div`
  flex: 1;
  margin-left: 1em;
`;

const ArrowImg = styled.img`
  height: 16px;
  width: 9px;
  transform: scaleX(-1);
`;

interface IdCardProps {
  user: {
    businessName: string;
    email: string;
    name: string;
  };
}

const IdCard: React.FC<IdCardProps> = ({
  user
}: IdCardProps): React.ReactElement => {
  return (
    <StyledCard>
      <CardContent>
        <TopContent>
          {user?.name && user?.email ? (
            <>
              <UserAvatar name={user.name} />
              <NameWrapper>
                <NameHeading>{user.name}</NameHeading>
                <Email>{user.email}</Email>
              </NameWrapper>
            </>
          ) : (
            <>
              <Skeleton circle={true} height="90px" width="90px" />
              <NameWrapper>
                <NameHeading>
                  <Skeleton width="6em" />
                </NameHeading>
                <Email>
                  <Skeleton width="18em" />
                </Email>
              </NameWrapper>
            </>
          )}
        </TopContent>
        <BusinessLink to="/business">
          {user?.businessName ? (
            <>
              <img alt="Business Icon" src={BusinessIcon} />
              <BusinessNameWrapper>{user.businessName}</BusinessNameWrapper>
              <ArrowImg src={BackArrow} />
            </>
          ) : (
            <>
              <Skeleton circle={true} height="40px" width="40px" />
              <BusinessNameWrapper>
                <Skeleton width="16em" />
              </BusinessNameWrapper>
              <ArrowImg src={BackArrow} />
            </>
          )}
        </BusinessLink>
      </CardContent>
    </StyledCard>
  );
};

export default IdCard;
