import styled from 'styled-components';
import React from 'react';

interface BadgeProps {
  className?: string;
  size?: number;
}

const StyledSpan = styled.span<BadgeProps>`
  background-color: #b4b6c6;
  border-radius: 50%;
  display: flex;
  height: ${p => (p.size ? `${p.size}px` : '40px')};
  justify-content: center;
  margin-bottom: 6px;
  width: ${p => (p.size ? `${p.size}px` : '40px')};
  &::before {
    content: 'i';
    color: white;
    font-weight: 600;
    font-size: ${p => (p.size ? `${p.size}px` : '40px')};
    line-height: ${p => (p.size ? `${p.size + 1}px` : '41px')};
  }
`;

const Badge: React.FC<BadgeProps> = ({
  className,
  size
}: BadgeProps): React.ReactElement => (
  <StyledSpan aria-hidden className={className} size={size} role="img" />
);

export default Badge;
