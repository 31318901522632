import React from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import UserAvatar from '../UserAvatar/UserAvatar';
import Link from '../Link/Link';

import useMedia from '../../hooks/useMedia';
import { breakpoints, mediaQuery as mQ } from '../../lib';
import { useTracking } from 'react-tracking';

const IdentityWrapper = styled.div`
  display: inline-block;
  margin: var(--identityWrapperMargin, 20px);
  font-size: var(--fontSize4);
  text-align: center;
  ${mQ.lg`
    font-size: var(--fontSize5);
  `}
`;

const MerchantNameHeading = styled.div`
  margin-top: var(--merchantNameMarginTop, 10px);
  font-size: var(--fontSize3);
  font-weight: var(--fontWeightBold);
  ${mQ.lg`
    font-size: var(--fontSize4);
    font-weight: var(--fontWeightMedium);
    margin-top: var(--merchantNameMarginTop, 16px);
  `}
`;

const BusinessNameHeading = styled.div`
  margin-top: var(--businessNameMarginTop, 2px);
`;

const StyledLink = styled(Link)`
  display: block;
  font-weight: var(--fontWeightSemiBold);
  margin-top: var(--signOutLinkMarginTop, 9px);
`;

const ProfileLink = styled(StyledLink)`
  margin-top: 18px;
`;

interface IdentityProps {
  user?: {
    name?: string;
    businessName?: string;
  };
  viewProfile?: boolean;
}

const Identity: React.FC<IdentityProps> = ({
  user,
  viewProfile
}: IdentityProps): React.ReactElement => {
  let avatarOnClick;
  const location = useLocation();
  const navigate = useNavigate();
  const isSmall = useMedia([`(min-width: ${breakpoints.sm})`], [false], true);
  const { Track, trackEvent } = useTracking({ component: 'Identity Clicked' });

  if (!viewProfile) {
    avatarOnClick = () => {
      if (location.pathname !== '/profile') {
        navigate('/profile');
        trackEvent({ event: 'Identity Link', href: '/profile' });
      }
    };
  }

  return (
    <IdentityWrapper>
      <UserAvatar
        onClick={avatarOnClick}
        name={user?.name}
        small={isSmall}
        showProgress
      />
      <MerchantNameHeading>{user?.name}</MerchantNameHeading>
      <BusinessNameHeading>{user?.businessName}</BusinessNameHeading>
      <Track>
        {viewProfile && (
          <ProfileLink to="/profile">View Your Profile</ProfileLink>
        )}
      </Track>
      <StyledLink to="/sign-out">Sign Out</StyledLink>
    </IdentityWrapper>
  );
};

Identity.defaultProps = {
  user: {
    name: '',
    businessName: ''
  }
};

export default Identity;
