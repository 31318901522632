import React from 'react';
import styled from 'styled-components';
import { LegacyFooter } from '@ondeck/silkworm';
import { GenericError, useCriticalError } from '@bazaar/components';
import PPPPageHeader from '../../components/PPPPageHeader';

const PageWrapper = styled.div`
  height: 100%;
  line-height: 1.5;
`;

const Content = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const Main = styled.main`
  flex: 1 0 auto;
  max-width: 992px;
  margin: 0 auto;
`;

type ContentProps = {
  children: React.ReactNode;
};

const AppContent: React.FC<ContentProps> = ({
  children
}: ContentProps): React.ReactElement => {
  const { hasError } = useCriticalError();
  return (
    <PageWrapper>
      <Content>
        <PPPPageHeader onFill="#fff" bgColor="#0074ff" />
        <Main>{!hasError ? children : <GenericError />}</Main>
        <LegacyFooter
          anchorLink="Back to Top"
          link1Href="https://www.ondeck.com/privacy"
          link1="Privacy Policy"
          link2=" "
          bgColor="#0c112a"
        >
          <p>
            Loans subject to lender approval. OnDeck&#174; is a registered
            trademark. All rights reserved.
          </p>
          <p>4700 W. Daybreak Pkwy., Suite 200, South Jordan, UT 84009</p>
        </LegacyFooter>
      </Content>
    </PageWrapper>
  );
};
export default AppContent;
