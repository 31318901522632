import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Accordion,
  AccordionItem,
  FundingCard,
  mediaQuery as mQ
} from '@bazaar/components';
import styled from 'styled-components';

interface FundingMethodProps {
  fundingMethod?: string;
  drawAmount: number;
  updateFundingMethod: (val: string) => void;
  locDetails?: ILOC;
}

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  ${mQ.md`
     flex-direction: row;
  `}
`;

const FundingMethod = ({
  fundingMethod,
  drawAmount,
  updateFundingMethod,
  locDetails
}: FundingMethodProps) => {
  const [instantFundingEligibility, setInstantFundingEligibility] = useState(
    false
  );
  useEffect(() => {
    let ifsDetails = locDetails?.instantFunding;
    let showInstantFundingCard =
      window.env.REACT_APP_INSTANT_FUNDING_DRAW_DISABLED.toString() ===
        'false' &&
      ifsDetails?.eligible &&
      ifsDetails?.cardInfo &&
      Object.entries(ifsDetails?.cardInfo).length > 0 &&
      !locDetails?.reamortizationStatus;

    if (showInstantFundingCard) {
      updateFundingMethod('INSTANT_FUNDING');
      setInstantFundingEligibility(true);
    }
  }, [locDetails]);
  const onClickFundingMethod = (e: string) => {
    updateFundingMethod(e);
  };
  return (
    <>
      {locDetails && (
        <Accordion style={{ padding: '2.5rem' }}>
          <AccordionItem
            title={<FormattedMessage id="drawFunds.reviewFundingMethod" />}
            subtitle={fundingMethod == 'ACH' ? 'ACH' : 'Instant Funding'}
          >
            <FlexWrapper>
              {instantFundingEligibility && (
                <FundingCard
                  labelText={<FormattedMessage id="drawFunds.instantFunding" />}
                  value="INSTANT_FUNDING"
                  checked={fundingMethod === 'INSTANT_FUNDING'}
                  onClick={onClickFundingMethod}
                  hideRadioButton={drawAmount > 10000}
                  disabled={drawAmount > 10000}
                  recommended
                >
                  <FormattedMessage
                    id={
                      drawAmount > 10000
                        ? 'drawFunds.instantFunding.unableToDraw'
                        : 'drawFunds.instantFunding.description'
                    }
                  />
                </FundingCard>
              )}
              <FundingCard
                labelText={<FormattedMessage id="drawFunds.ach" />}
                value="ACH"
                checked={fundingMethod === 'ACH'}
                onClick={onClickFundingMethod}
                hideRadioButton={
                  !instantFundingEligibility || drawAmount > 10000
                }
              >
                <FormattedMessage id="drawFunds.ach.description" />
              </FundingCard>
            </FlexWrapper>
          </AccordionItem>
        </Accordion>
      )}
    </>
  );
};

export default FundingMethod;
