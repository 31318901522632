import React, { useEffect, useState } from 'react';
import useFetch from 'use-http';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, ODORenewal, useAuth0 } from '@bazaar/components';
import NotificationSection from './NotificationSection';
import { datadogRum } from '@datadog/browser-rum';

const ODORenewalNotification = () => {
  const { getTokenSilently } = useAuth0();
  const intl = useIntl();
  const [renewalEligibilty, setRenewalEligibility] = useState<
    RenewalEligibility
  >();

  interface AlertProps {
    color?: string;
    children: React.ReactNode;
  }

  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationDismissed, setNotificationDismissed] = useState<string>();
  const [alert, setAlert] = useState<AlertProps>();

  const renewalHref = `${window.env.REACT_APP_API_URL}/renewal`;
  const odoTokenHref = `${window.env.REACT_APP_ODO_URL}/apex/renewal/login`;
  const odoRenewalEntry = `${window.env.REACT_APP_ODO_URL}/businesses/edit`;

  const { get: getRenewalEligibility } = useFetch(renewalHref);
  const dismissal = `renewalNotificationDismissed`;

  useEffect(() => {
    const storedDismissed = sessionStorage.getItem(dismissal);
    storedDismissed && setNotificationDismissed(storedDismissed);
  }, [dismissal]);

  useEffect(() => {
    const fetchRenewalEligibility = async () => {
      const renewal = await getRenewalEligibility();
      try {
        setRenewalEligibility(renewal);
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          console.error(error);
        }
        datadogRum.addError(error);
        setShowNotification(true);
      }
    };
    fetchRenewalEligibility();
  }, [getRenewalEligibility]);

  useEffect(() => {
    if (renewalEligibilty) setShowNotification(true);
  }, [renewalEligibilty]);

  const onNotificationDismiss = (e: React.SyntheticEvent) => {
    setNotificationDismissed(dismissal);
  };

  const onClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const token = await getTokenSilently();

    fetch(odoTokenHref, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ jwt: token?.toString() })
    })
      .then(async response => {
        if (response.ok) {
          window.open(odoRenewalEntry, '_self');
        } else {
          createAlert();
          if (process.env.NODE_ENV === 'development') {
            console.error(
              `Fetch failed in renewal/login. The status is ${response.status}`
            );
          }
          datadogRum.addError(
            new Error(`Fetch failed with status ${response.status}`)
          );
        }
      })
      .catch(error => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error);
        }
        datadogRum.addError(error);
        createAlert();
      });
  };

  const createAlert = () => {
    let errorMsg = <FormattedMessage id={'odoRenewal.loginError'} />;
    setAlert({ color: 'red', children: errorMsg });
  };

  useEffect(() => {
    if (notificationDismissed) {
      sessionStorage.setItem(dismissal, notificationDismissed);
    }
  }, [notificationDismissed, dismissal]);

  if (
    showNotification &&
    !notificationDismissed &&
    renewalEligibilty?.termLoanEligible
  ) {
    return (
      <>
        {alert?.children && (
          <Alert color={alert?.color} hideOnDismiss>
            {alert?.children}
          </Alert>
        )}
        <NotificationSection />
        {!notificationDismissed && (
          <ODORenewal
            cta={intl.formatMessage({
              id: 'renewalNotification.callToAction.odoRenewal'
            })}
            dismissMessage={intl.formatMessage({
              id: 'renewalNotification.dismissMessage.odoRenewal'
            })}
            title={intl.formatMessage({
              id: 'renewalNotification.title.odoRenewal'
            })}
            onDismiss={onNotificationDismiss}
            onClick={onClick}
            message={intl.formatMessage({
              id: 'renewalNotification.message.odoRenewal'
            })}
          />
        )}
      </>
    );
  }
  return <NotificationSection />;
};

export default ODORenewalNotification;
