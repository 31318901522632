import React, { useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useTracking } from 'react-tracking';

interface CheckBoxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean;
  value?: string;
}

const Label = styled.label`
  display: flex;
  margin: 0 0 5px;
  position: relative;
`;
const LabelText = styled.span`
  font-size: inherit;
`;

const StyledCheckBox = styled.input`
  cursor: pointer;
  width: 15px;
  height: 15px;
  margin-right: 13px;
`;

const CheckBox: React.FC<CheckBoxProps> = ({
  children,
  checked,
  value,
  onChange,
  ...props
}: CheckBoxProps): React.ReactElement => {
  const checkBoxRef = useRef<HTMLInputElement>(null);
  const { trackEvent } = useTracking({ event: 'CheckBox Clicked' });
  const onChangeHandler = useCallback(
    evt => {
      trackEvent({
        content: checkBoxRef?.current?.value
      });
      onChange && onChange(evt);
    },
    [onChange, trackEvent, checkBoxRef]
  );

  return (
    <Label>
      <div>
        <StyledCheckBox
          ref={checkBoxRef}
          value={value}
          type="checkbox"
          onChange={onChangeHandler}
          checked={checked}
          {...props}
        />
      </div>
      <LabelText>{children}</LabelText>
    </Label>
  );
};

export default CheckBox;
