import React, { useEffect, useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate
} from 'react-router-dom';
import { useTransition, animated } from 'react-spring';
import { useTracking } from 'react-tracking';
import { GenericError, ErrorBoundary } from '@bazaar/components';
import { LoadingSpinner } from '@ondeck/silkworm';
import { AppLayout, PPPLayout } from './layouts';
import {
  BusinessProfile,
  Dashboard,
  Documents,
  PersonalProfile,
  Error,
  Forgiveness,
  ForgotPassword,
  Login,
  Payment,
  DrawFunds,
  ResetPassword
} from './pages';
import { AppDrawerContextProvider } from '../contexts/AppDrawer';
import { PartiesAPIProvider, usePartiesAPI } from '../contexts/PartiesAPI';
import { DatadogSetUser } from '@bazaar/components';
import Analytics from '../lib/analytics';
import { ProfileAPIProvider } from 'contexts/ProfileAPI';
import useFetch from 'use-http';
import { SignOut, useAuth } from '../contexts/Auth';
import LoginDev from './pages/LoginDev/LoginDev.jsx';

const LoanExperience = () => {
  const location = useLocation();
  const { Track, trackEvent } = useTracking({ page: location.pathname });
  const { user, isAuthenticated } = useAuth();
  const { partiesData } = usePartiesAPI();
  const userId =
    user?.userId?.toString() || user?.sub?.toString().split('|').pop();
  const userVars = {
    displayName: partiesData.user?.name,
    email: user?.email
  };
  useEffect(() => {
    trackEvent({ event: 'Page View' });
    isAuthenticated &&
      DatadogSetUser({
        userId,
        name: partiesData.user?.name,
        email: user?.email
      });
    if (userId && Analytics) {
      Analytics.identify(userId, userVars, {
        plugins: {
          fullstory: true
        }
      });
    }
  }, [location, trackEvent, user, partiesData, isAuthenticated]);

  return (
    <Track>
      <AppDrawerContextProvider>
        <ProfileAPIProvider>
          <TransitionRoutes>
            <Route element={<AppLayout />}>
              <Route index key="dashboard" element={<Dashboard />} />
              <Route
                key="profile"
                path="profile"
                element={<PersonalProfile />}
              />

              <Route
                key="business"
                path="business"
                element={<BusinessProfile />}
              />
              <Route key="payment" path="payment" element={<Payment />} />
              <Route key="documents" path="documents" element={<Documents />} />
              <Route key="drawfunds" path="drawfunds" element={<DrawFunds />} />
              <Route
                key="reset-password"
                path="reset-password"
                element={<ForgotPassword />}
              />
              <Route path="error" element={<Error />} />
              <Route path="sign-out" element={<SignOut />} />
              <Route path="*" element={<Error error={{ status: 404 }} />} />
            </Route>
          </TransitionRoutes>
        </ProfileAPIProvider>
      </AppDrawerContextProvider>
    </Track>
  );
};

const PPPForgivenessExperience = () => {
  return (
    <PPPLayout>
      <ErrorBoundary component={GenericError}>
        <Routes>
          <Route key="error" path="/error">
            <GenericError />
          </Route>
          <Route path="/forgiveness">
            <Forgiveness />
          </Route>
          <Navigate to="/" />
        </Routes>
      </ErrorBoundary>
    </PPPLayout>
  );
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const TransitionRoutes = ({ children }: { children: React.ReactElement }) => {
  const location = useLocation();
  const transitions = useTransition(location, location => location.pathname, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 1 }
  });
  return (
    <div>
      {transitions.map(({ item, props, key }) => (
        <animated.div key={key} style={props}>
          <Routes location={item}>{children}</Routes>
        </animated.div>
      ))}
    </div>
  );
};

enum PrivateRouterState {
  PPP,
  LoanExperience,
  Loading
}

const LoansHaveType = (loan: [], type: string) =>
  loan?.some((loan: any) => loan.loanType === type);

export const ExperienceRouter = () => {
  const { partiesData } = usePartiesAPI();
  const [privateRouter, setPrivateRouter] = useState<PrivateRouterState>(
    PrivateRouterState.Loading
  );

  const currentApplicationHref = `${window.env.REACT_APP_BUTTERFLY_URL}/orchestration/current_application`;
  const { get: getCurrentApplication } = useFetch(currentApplicationHref);

  useEffect(() => {
    if (partiesData === null) return;
    if (partiesData.parties?.some((e: any) => e.type === 'CUSTOMER')) {
      const loans = partiesData.parties?.[0].loans;
      if (
        LoansHaveType(loans, 'SBA_PAYCHECK_PROTECTION_PROGRAM') &&
        !(
          LoansHaveType(loans, 'FIXED_TERM') ||
          LoansHaveType(loans, 'LINE_OF_CREDIT')
        )
      ) {
        setPrivateRouter(PrivateRouterState.PPP);
      } else {
        setPrivateRouter(PrivateRouterState.LoanExperience);
      }
    } else {
      getCurrentApplication().then(
        (app: Application) =>
          (window.location.href = `${window.env.REACT_APP_CHECKOUT_UX_URL}/redirect/auth?applicationId=${app.id}`)
      );
    }
  }, [partiesData]);

  switch (privateRouter) {
    case PrivateRouterState.LoanExperience:
      return <LoanExperience />;
    case PrivateRouterState.PPP:
      return <PPPForgivenessExperience />;
    default:
      return <LoadingSpinner>Loading...</LoadingSpinner>;
  }
};

const ApplicationFallback = () => (
  <Routes>
    <Route path="*" element={<AppLayout />}>
      <Error />
    </Route>
  </Routes>
);

const PrivateRouter = () => {
  return (
    <ErrorBoundary component={ApplicationFallback}>
      <PartiesAPIProvider>
        <ProfileAPIProvider>
          <ExperienceRouter />
        </ProfileAPIProvider>
      </PartiesAPIProvider>
    </ErrorBoundary>
  );
};

const PublicRouter = () => {
  return (
    <PPPLayout>
      <Routes>
        <Route path="/*" element={<Login />} />
        <Route
          key="forgot-password"
          path="/forgot-password"
          element={<ForgotPassword />}
        />
        <Route
          key="reset-password"
          path="/reset-password"
          element={<ResetPassword />}
        />
        {process.env.NODE_ENV === 'development' && (
          <Route key="login-dev" path="/login-dev" element={<LoginDev />} />
        )}
      </Routes>
    </PPPLayout>
  );
};

const Router = () => {
  const { isAuthenticated } = useAuth();
  return (
    <BrowserRouter>
      <ScrollToTop />
      {isAuthenticated ? <PrivateRouter /> : <PublicRouter />}
    </BrowserRouter>
  );
};

export default Router;
