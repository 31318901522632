import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useTracking } from 'react-tracking';
import { Provider as UhP } from 'use-http';
import { datadogRum } from '@datadog/browser-rum';
import {
  DefaultStyles,
  CriticalErrorProvider,
  locale,
  messages
} from '@bazaar/components';
import { DefaultTheme as SilkwormStyles } from '@ondeck/silkworm';
import Analytics from './lib/analytics';
import Router from './router';
import { GlobalStyles } from './style';
import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';
import { AuthResolver, useAuth } from './contexts/Auth';

const UseHttpProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    loginServerEnabled,
    isAuthenticated,
    loading,
    addCredentials
  } = useAuth();

  if (loading) {
    return null;
  }
  return (
    <UhP
      options={{
        interceptors: {
          request: async ({ options }: any) => {
            options.headers = {
              ...options.headers,
              'Content-Type': 'application/json',
              Accept: 'application/json'
            };
            if (isAuthenticated) {
              await addCredentials(options);
            }
            return options;
          },
          response: async ({ response }: any) => {
            if (
              loginServerEnabled &&
              (response.status === 401 || response.status === 403)
            ) {
              window.location.href = window.env.REACT_APP_LOGIN_PAGE_URL;
            } else if (response.status >= 300) {
              let errorMessage =
                `Error Status: ${response.status}. ` + `Path: ${response.url}`;
              datadogRum.addError(errorMessage);
            }
            return response;
          }
        }
      }}
    >
      {children}
    </UhP>
  );
};

const optimizely = createInstance({
  sdkKey: window.env.REACT_APP_OPTIMIZELY_KEY
});

const App = () => {
  const [hasError, setHasError] = useState(false);
  const { Track } = useTracking(
    { application: 'Bazaar' },
    {
      dispatch: (payload: any) => {
        const { event, ...props } = payload;
        Analytics.track(event, props, {
          plugins: {
            fullstory: false
          }
        });
      }
    }
  );

  return (
    <Track>
      <IntlProvider
        locale={locale}
        messages={messages[locale]}
        defaultRichTextElements={{
          bold: function boldFormat(txt) {
            return <strong>{txt}</strong>;
          }
        }}
      >
        <CriticalErrorProvider setHasError={setHasError} hasError={hasError}>
          <AuthResolver>
            <OptimizelyProvider
              optimizely={optimizely}
              user={{ id: 'default_user' }}
            >
              <UseHttpProvider>
                <DefaultStyles />
                <SilkwormStyles />
                <GlobalStyles />
                <Router />
              </UseHttpProvider>
            </OptimizelyProvider>
          </AuthResolver>
        </CriticalErrorProvider>
      </IntlProvider>
    </Track>
  );
};

export default App;
