import React from 'react';
import styled from 'styled-components';
import { mediaQuery as mQ } from '../../lib';
import Skeleton from 'react-loading-skeleton';

const GridContainer = styled.dl`
  ${mQ.md`
    display: table;
    min-width: 100%;
    border-collapse: collapse;
  `}
`;

const GridRow = styled.div`
  border-bottom: 1px solid var(--color-separator);
  padding: 16px 0;
  ${mQ.md`
    display: table-row;
    padding: 0;
  `}
`;

const cellStyles = mQ.md`
  color: inherit;
  display: table-cell;
  font-size: var(--fontSize4);
  padding: 16px 0;
`;

const Title = styled.dt`
  color: var(--color-muted);
  ${cellStyles};
`;

const Data = styled.dd`
  font-size: var(--fontSize4);
  margin: 0;
  ${cellStyles};
`;

interface DataGridProps {
  rows: Array<Array<React.ReactNode>>;
}

const GridItemSkeleton = () => {
  return (
    <GridRow>
      <Title>
        <Skeleton width="10em" />
      </Title>
      <Data>
        <Skeleton width="18em" />
      </Data>
    </GridRow>
  );
};

const DataGrid: React.FC<DataGridProps> = ({
  rows = []
}: DataGridProps): React.ReactElement => {
  if (rows.length === 0) {
    return (
      <GridContainer>
        <GridItemSkeleton />
      </GridContainer>
    );
  }

  return (
    <GridContainer>
      {rows.map((rowContent, index) => (
        <GridRow key={index}>
          <Title>
            {rowContent[0] ? (
              typeof rowContent[0] === 'function' ? (
                rowContent[0]()
              ) : (
                rowContent[0]
              )
            ) : (
              <Skeleton width="12em" />
            )}
          </Title>
          <Data>
            {rowContent[1] ? (
              typeof rowContent[1] === 'function' ? (
                rowContent[1]()
              ) : (
                rowContent[1]
              )
            ) : (
              <Skeleton width="12em" />
            )}
          </Data>
        </GridRow>
      ))}
    </GridContainer>
  );
};

export default DataGrid;
