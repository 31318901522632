import React, { ReactElement } from 'react';
import styled from 'styled-components';

const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const StyledTR = styled.tr`
  border-bottom: 1px solid var(--color-separator);
  padding: 1em 0;
`;

const StyledTD = styled.td`
  padding: 1em 0;
  &:nth-last-child(1) {
    text-align: right;
  }
`;

const TableRow = (props: { children: (ReactElement | string)[] }) => {
  return (
    <StyledTR>
      {props.children.map((child, index) => {
        return <StyledTD key={index}>{child}</StyledTD>;
      })}
    </StyledTR>
  );
};

const Table = ({ rows }: { rows: (ReactElement | string)[][] }) => {
  if (rows) {
    return (
      <StyledTable>
        <tbody>
          {rows.map((row, index) => {
            return <TableRow key={index}>{row}</TableRow>;
          })}
        </tbody>
      </StyledTable>
    );
  }
  return null;
};

export default Table;
