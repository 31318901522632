import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  DataGrid,
  IdCard,
  Link
} from '@bazaar/components';
import EditProfileForm from './EditProfileForm';
import { useIntl } from 'react-intl';
import { usePartiesAPI } from '../../../contexts/PartiesAPI';
import { useTracking } from 'react-tracking';
import { useProfileAPI } from 'contexts/ProfileAPI';
import { useAuth } from '../../../contexts/Auth';

const Grid = styled.div`
  display: grid;
  gap: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 1.2em;
  justify-content: center;
`;

const EditBtn = styled(Button)`
  width: 215px;
  height: 44px;
`;

interface FormattedProfile {
  homePhone?: IContact;
  alternatePhone?: IContact;
  email?: IContact;
  personalAddress?: IAddress;
}

const formatProfileDetails = (rawData: Profile | null) => {
  const addresses = rawData?.addresses;
  const contacts = rawData?.contacts ? rawData?.contacts : [];
  const personalAddress = addresses?.find(
    address => address.type === 'PHYSICAL_ADDRESS'
  );
  const [homePhone, alternatePhone] = contacts?.filter(
    contact => contact.type === 'HOME_PHONE'
  );
  const email = contacts?.find(contact => contact.type === 'EMAIL_ADDRESS');
  return {
    email: email,
    personalAddress: personalAddress,
    homePhone: homePhone,
    alternatePhone: alternatePhone
  };
};

const CredentialsCard = () => {
  const { loginServerEnabled } = useAuth();
  return (
    <Card>
      <CardHeader>Privacy and Sign In</CardHeader>
      <CardContent>
        {loginServerEnabled ? (
          <Link
            href={`${window.env.REACT_APP_ODO_URL}/reset_password`}
            target="_self"
          >
            Reset my password
          </Link>
        ) : (
          <Link to="/reset-password">Reset my password</Link>
        )}
      </CardContent>
    </Card>
  );
};

const Profile = () => {
  const { partiesData } = usePartiesAPI();
  const { profileDetails, setProfileDetails } = useProfileAPI();
  const { trackEvent, Track } = useTracking({ component: 'Personal Profile' });
  const business = partiesData.parties[0].businesses[0].legalName;

  const [
    formattedProfile,
    setFormattedProfile
  ] = useState<FormattedProfile | null>(null);
  // const { get: getProfileDetails, cache } = useFetch(profileHref);
  const initialRenderTime = useMemo(() => Date.now(), []);

  const getFormattedProfileDetails = useCallback(() => {
    const result = formatProfileDetails(profileDetails);
    trackEvent({
      event: 'Page Loaded',
      readyTime: (Date.now() - initialRenderTime) / 1000
    });
    setFormattedProfile(result);
  }, [profileDetails]);

  useEffect(() => {
    getFormattedProfileDetails();
  }, [getFormattedProfileDetails]);

  const [editingForm, setEditingForm] = useState(false);
  const personalAddress = formattedProfile?.personalAddress;
  const displayAddress = personalAddress?.city
    .concat(', ')
    .concat(personalAddress.state)
    .concat(' ')
    .concat(personalAddress.postalCode);
  const addressContents = () => (
    <>
      {personalAddress?.lines.map((line, lineNumber) => (
        <div key={lineNumber}>{line}</div>
      ))}
      {personalAddress?.city && <div>{displayAddress}</div>}
    </>
  );

  const intl = useIntl();
  const doneEditing = () => {
    setEditingForm(false);
    // cache.delete(`url:${profileHref}||method:GET||body:`);
  };
  let profileData = [];

  profileData.push([
    'Email',
    formattedProfile?.email?.value && `${formattedProfile?.email.value}`
  ]);

  formattedProfile?.homePhone &&
    profileData.push(['Home Phone', `${formattedProfile?.homePhone.value}`]);

  formattedProfile?.alternatePhone &&
    profileData.push([
      'Alternate Phone',
      `${formattedProfile?.alternatePhone.value}`
    ]);

  profileData.push([
    'Home Address',
    formattedProfile?.personalAddress && addressContents
  ]);
  return (
    <Track>
      <Grid>
        <IdCard
          user={{
            name: profileDetails?.fullName,
            email: formattedProfile?.email?.value,
            businessName: business
          }}
        />
        <Card>
          <CardHeader>
            {profileDetails?.id ? 'Profile' : <Skeleton width="8em" />}
          </CardHeader>
          <CardContent>
            {editingForm ? (
              <EditProfileForm
                done={doneEditing}
                email={formattedProfile?.email}
                homePhone={formattedProfile?.homePhone}
                alternatePhone={formattedProfile?.alternatePhone}
                address={formattedProfile?.personalAddress}
                setProfileDetails={setProfileDetails}
              />
            ) : (
              <>
                <DataGrid rows={profileData} />
                {/*<ButtonWrapper>*/}
                {/*  <EditBtn*/}
                {/*    variant="square"*/}
                {/*    unfilled*/}
                {/*    onClick={() => setEditingForm(true)}*/}
                {/*  >*/}
                {/*    {intl.formatMessage({ id: 'common.edit' })}*/}
                {/*  </EditBtn>*/}
                {/*</ButtonWrapper>*/}
              </>
            )}
          </CardContent>
        </Card>
        <CredentialsCard />
      </Grid>
    </Track>
  );
};

export default Profile;
