import React, { AllHTMLAttributes } from 'react';
import styled from 'styled-components';

interface DropDownProps
  extends AllHTMLAttributes<HTMLSelectElement | HTMLDivElement> {
  options: { value: string; label: string }[];
}

const Label = styled.label`
  display: inline-block;
  font-size: 14px;
`;

const Wrapper = styled.div`
  margin: 5px 0;
`;

const CustomSelect = styled.select`
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  color: #4a4a4a;
  height: 40px;
`;

const DropDown = React.forwardRef(
  (
    { options, label, name, onChange, value, ...props }: DropDownProps,
    forwardRef: any
  ): React.ReactElement => {
    return (
      <div {...props}>
        {label && <Label className="label">{label}</Label>}
        <Wrapper>
          <CustomSelect
            ref={forwardRef}
            name={name}
            onChange={onChange}
            value={value}
          >
            {options &&
              options.map((option, idx) => {
                return (
                  <option key={idx} value={option.value}>
                    {option.label}
                  </option>
                );
              })}
          </CustomSelect>
        </Wrapper>
      </div>
    );
  }
);

DropDown.displayName = 'DropDown';

export default DropDown;
