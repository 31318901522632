import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { createBrowserHistory } from 'history';
import { useLocation } from 'react-router-dom';
import {
  SideNav,
  DropdownLink,
  NavItem,
  Link,
  useMedia
} from '@bazaar/components';
import { useTracking } from 'react-tracking';
import { useIntl, FormattedMessage, IntlShape } from 'react-intl';

import { usePartiesAPI } from './PartiesAPI';
import { breakpoints } from '../style';

interface IAppDrawerContext {
  toggleDrawer?: () => void;
}

const activeLink = {
  fontWeight: 'var(--fontWeightBold)'
};

const StyledLink = styled(Link)`
  &:focus {
    text-decoration: none;
    --color-anchor-hover: #000;
  }
`;

const ContactBody = styled.div`
  line-height: 2.5;
  background-color: var(--color-gray);
  padding: 0 18px;
`;

const ContactInfoLink = styled(Link)`
  --color-anchor: #006beb;
  --color-anchor-hover: #006beb;
`;
export const AppDrawerContext = React.createContext<IAppDrawerContext>({});

export const contactUs = (intl: IntlShape) => {
  const email = 'customerservice@ondeck.com';
  return (
    <ContactBody>
      <ContactInfoLink
        href={intl.formatMessage({
          id: 'contactInfo.phone.helpline.link'
        })}
        target="_self"
      >
        <FormattedMessage id="contactInfo.phone.helpline" />
      </ContactInfoLink>
      <br />
      <ContactInfoLink href={`mailto:${email}`} target="_self">
        {email}
      </ContactInfoLink>
      <div>
        <FormattedMessage id="contactOndeck.hoursAvailable.message" />
      </div>
    </ContactBody>
  );
};
const AppDrawerContents = ({ showDrawNav }: { showDrawNav: boolean }) => {
  const intl = useIntl();
  const pathname = useLocation().pathname;
  return (
    <>
      <NavItem>
        <StyledLink style={pathname === '/' ? activeLink : {}} to="/">
          <FormattedMessage id="navigation.link.dashboard" />
        </StyledLink>
      </NavItem>
      {showDrawNav && (
        <NavItem>
          <StyledLink
            style={pathname === '/drawfunds' ? activeLink : {}}
            to="/drawfunds"
          >
            <FormattedMessage id="navigation.link.drawFunds" />
          </StyledLink>
        </NavItem>
      )}
      <NavItem>
        <StyledLink
          style={pathname === '/payment' ? activeLink : {}}
          to="/payment"
        >
          <FormattedMessage id="navigation.link.makePayment" />
        </StyledLink>
      </NavItem>
      <NavItem>
        <StyledLink
          style={pathname === '/documents' ? activeLink : {}}
          to="/documents"
        >
          <FormattedMessage id="navigation.link.documents" />
        </StyledLink>
      </NavItem>
      <DropdownLink render={() => contactUs(intl)}>
        <FormattedMessage id="navigation.link.contactUs" />
      </DropdownLink>
    </>
  );
};

export const AppDrawerContextProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const { trackEvent } = useTracking({ component: 'Mobile Drawer' });
  const isLarge = useMedia([`(max-width: ${breakpoints.lg})`], [false], true);
  const { partiesData } = usePartiesAPI();
  const user = {
    businessName: partiesData.parties[0].businesses[0].legalName,
    name: partiesData.user.name
  };
  const history = createBrowserHistory();
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => setOpen(!open);
  const showDrawNav: boolean = partiesData.parties[0].loans.some(
    (loan: any) => loan.loanType == 'LINE_OF_CREDIT'
  );

  useEffect(() => {
    return history.listen(() => {
      setOpen(false);
    });
  }, [history]);

  useEffect(() => {
    if (open && isLarge) {
      setOpen(false);
    }
  }, [isLarge, open]);

  useEffect(() => {
    if (open) {
      trackEvent({ event: 'Side Menu Open' });
    }
  }, [open, trackEvent]);

  return (
    <AppDrawerContext.Provider value={{ toggleDrawer }}>
      <SideNav open={open} onClose={toggleDrawer} user={user}>
        <AppDrawerContents showDrawNav={showDrawNav} />
      </SideNav>
      {children}
    </AppDrawerContext.Provider>
  );
};
