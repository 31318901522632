import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Button, Input, DropDown } from '@bazaar/components';
import { Row, Col } from 'react-grid-system';
import { useIntl } from 'react-intl';
import {
  phoneMaskedInput,
  phoneNumberRegex,
  cityRegex,
  zipCodeRegex,
  states
} from '../../../lib/constants';
import { usePartiesAPI } from '../../../contexts/PartiesAPI';
import useFetch from 'use-http';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

interface EditProfileInputs {
  homePhone: string;
  alternatePhone: string;
  email: string;
  street: string;
  unit: string;
  city: string;
  zip: string;
  state: string;
}

const InputWrapper = styled.div`
  margin-bottom: 0.5em;
`;

const CustomInput = styled(Input)`
  font-size: 14px;
  width: 100%;
  label {
    margin-bottom: 8px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 1.2em;
  justify-content: center;
`;

const EditBtn = styled(Button)`
  width: 215px;
  height: 44px;
`;

const ErrorBox = styled.div`
  padding: 0px 5px;
`;

const ErrorCode = styled.p`
  color: #d0021b;
  margin: 0px;
  word-break: break-word;
`;

const EditProfileForm = (props: {
  done: () => void;
  email?: IContact;
  homePhone?: IContact;
  alternatePhone?: IContact;
  address?: IAddress;
  setProfileDetails: React.Dispatch<React.SetStateAction<IProfile | null>>;
}) => {
  const intl = useIntl();
  const invalidText = intl.formatMessage({ id: 'common.incorrectFormat' });
  const requiredText = intl.formatMessage({ id: 'common.required' });
  const schema = yup
    .object()
    .shape({
      homePhone: yup
        .string()
        .required(requiredText)
        .matches(phoneNumberRegex, invalidText),
      street: yup.string().required(requiredText),
      zip: yup
        .string()
        .required(requiredText)
        .matches(zipCodeRegex, invalidText),
      city: yup.string().required(requiredText).matches(cityRegex, invalidText)
    })
    .when((values: EditProfileInputs, schema: any) => {
      if (props.alternatePhone?.value && values.alternatePhone != '') {
        return schema.shape({
          alternatePhone: yup.string().matches(phoneNumberRegex, invalidText)
        });
      }
    });
  const { partiesData } = usePartiesAPI();
  const personUUID = partiesData.user.id;
  const street =
    props.address && props.address.lines.length >= 1
      ? props.address.lines[0]
      : '';
  const unit =
    props.address && props.address.lines.length >= 2
      ? props.address.lines[1]
      : '';

  const {
    register,
    handleSubmit,
    formState: { dirtyFields, errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      homePhone: props.homePhone?.value,
      alternatePhone: props.alternatePhone?.value,
      street: street,
      unit: unit,
      city: props.address?.city,
      zip: props.address?.postalCode,
      state: props.address?.state
    }
  });
  const patchAddressHref = `${window.env.REACT_APP_API_URL}/persons/${personUUID}/addresses`;
  const patchContactHref = `${window.env.REACT_APP_API_URL}/persons/${personUUID}/contacts`;
  const { patch: patchProfileAddress } = useFetch(patchAddressHref);
  const { patch: patchProfileContact } = useFetch(patchContactHref);
  const patchUserContact = async (
    id?: string,
    type?: string,
    value?: string
  ) => {
    let jsonContact = `{
      "id": "${id}",
      "type": "${type}",
      "value": "${value}"
    }`;
    const newProfileDetails = await patchProfileContact(
      `/${id}`,
      JSON.parse(jsonContact)
    );
    if (newProfileDetails) {
      props.setProfileDetails(newProfileDetails);
    }
  };
  const patchUserAddress = async (
    id?: string,
    type?: string,
    city?: string,
    lineOne?: string,
    lineTwo?: string,
    postalCode?: string,
    state?: string
  ) => {
    let jsonAddress = `{
      "city": "${city}",
      "country": "USA",
      "id": "${id}",
      "lineOne": "${lineOne}",
      "lineTwo": "${lineTwo}",
      "postalCode": "${postalCode}",
      "state": "${state}",
      "type": "${type}"
    }`;
    const newProfileDetails = await patchProfileAddress(
      `/${id}`,
      JSON.parse(jsonAddress)
    );
    if (newProfileDetails) {
      props.setProfileDetails(newProfileDetails);
    }
  };

  const Error = ({ errorMsg }: { errorMsg: string }) => {
    return (
      <ErrorBox>
        <ErrorCode>{errorMsg}</ErrorCode>
      </ErrorBox>
    );
  };

  const onSubmit = async (data: EditProfileInputs) => {
    if (dirtyFields.homePhone) {
      await patchUserContact(
        props.homePhone?.id,
        props.homePhone?.type,
        data.homePhone
      );
    }
    if (dirtyFields.alternatePhone) {
      await patchUserContact(
        props.alternatePhone?.id,
        props.alternatePhone?.type,
        data.alternatePhone
      );
    }
    if (
      dirtyFields.street ||
      dirtyFields.unit ||
      dirtyFields.city ||
      dirtyFields.zip ||
      dirtyFields.state
    ) {
      await patchUserAddress(
        props.address?.id,
        props.address?.type,
        data.city,
        data.street,
        data.unit,
        data.zip,
        data.state
      );
    }
    props.done();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputWrapper>
        <CustomInput
          id="email"
          name="email"
          type="text"
          value={props.email?.value}
          disabled
        >
          {intl.formatMessage({ id: 'profile.inputLabel.email' })}
        </CustomInput>
        <CustomInput
          id="homePhone"
          name="homePhone"
          type="text"
          ref={register}
          mask={phoneMaskedInput}
          error={errors.homePhone}
        >
          {intl.formatMessage({ id: 'profile.inputLabel.homePhone' })}
        </CustomInput>
        {errors.homePhone?.message && (
          <Error errorMsg={errors.homePhone.message} />
        )}
        {props.alternatePhone?.value && (
          <>
            <CustomInput
              id="alternatePhone"
              name="alternatePhone"
              type="text"
              ref={register}
              mask={phoneMaskedInput}
              error={errors.alternatePhone}
            >
              {intl.formatMessage({ id: 'profile.inputLabel.alternatePhone' })}
            </CustomInput>
            {errors.alternatePhone?.message && (
              <Error errorMsg={errors.alternatePhone.message} />
            )}
          </>
        )}
        <Row>
          <Col xs={12} sm={9}>
            <CustomInput
              id="street"
              name="street"
              type="text"
              ref={register}
              error={errors.street}
            >
              {intl.formatMessage({ id: 'profile.inputLabel.street' })}
            </CustomInput>
            {errors.street?.message && (
              <Error errorMsg={errors.street.message} />
            )}
          </Col>
          <Col xs={12} sm={3}>
            <CustomInput id="unit" name="unit" type="text" ref={register}>
              {intl.formatMessage({ id: 'profile.inputLabel.unit' })}
            </CustomInput>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={3}>
            <CustomInput
              id="zip"
              name="zip"
              type="text"
              ref={register}
              error={errors.zip}
            >
              {intl.formatMessage({ id: 'profile.inputLabel.zip' })}
            </CustomInput>
            {errors.zip?.message && <Error errorMsg={errors.zip.message} />}
          </Col>
          <Col xs={12} sm={6}>
            <CustomInput
              id="city"
              name="city"
              type="text"
              ref={register}
              error={errors.city}
            >
              {intl.formatMessage({ id: 'profile.inputLabel.city' })}
            </CustomInput>
            {errors.city?.message && <Error errorMsg={errors.city.message} />}
          </Col>
          <Col xs={12} sm={3}>
            <DropDown
              style={{ marginTop: '25px' }}
              ref={register}
              name="state"
              options={states}
              label="State"
            />
          </Col>
        </Row>
      </InputWrapper>
      <ButtonWrapper>
        <EditBtn variant="square" unfilled onClick={props.done}>
          {intl.formatMessage({ id: 'common.cancel' })}
        </EditBtn>
        <EditBtn variant="square" type="submit">
          {intl.formatMessage({ id: 'common.save' })}
        </EditBtn>
      </ButtonWrapper>
    </form>
  );
};

export default EditProfileForm;
