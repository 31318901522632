import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';

import Card, { CardHeader, CardContent } from '../Card/Card';
import FormattedMoney from '../FormattedMoney/FormattedMoney';
import Tooltip from '../Tooltip/Tooltip';
import ProgressBar from '../ProgressBar/ProgressBar';
import { useTracking } from 'react-tracking';
import { mediaQuery as mQ } from '../../lib';

const StyledCard = styled(Card)`
  --card-content-padding: 0.5em 1.5em 1.5em;
  box-shadow: none;
`;

const StyledCardHeader = styled(CardHeader)`
  padding: 1.3em 1.5em;
`;

const Heading = styled.h4`
  margin: 0.6em 0;
`;

const ContentRow = styled.div`
  display: flex;
  font-size: var(--fontSize4);
  justify-content: space-between;
  margin: 1.6rem 0;
`;

const HeaderContent = styled.div`
  margin-bottom: 1.2em;
  font-size: 0.75em;
  font-weight: var(--fontWeightBody);
  ${mQ.md`
     margin: 0.6em 0;
  `}
`;

const AmountLabel = styled.div`
  color: var(--color-muted);
  font-size: 0.75em;
`;

type TermLoan = {
  approvedAmount: number;
  balanceOutstanding: number;
  balancePaid: number;
  estimatedPaymentsRemaining: number;
  estimatedPayoffAmount?: number;
  estimatedPayoffDate?: string;
  id: string;
  type: 'FIXED_TERM';
  percentagePaidDown: number;
  term: string;
};

const TermLoanDetails: React.FC<TermLoan> = ({
  approvedAmount,
  balanceOutstanding,
  balancePaid,
  id,
  percentagePaidDown
}: TermLoan): React.ReactElement => {
  const intl = useIntl();
  const percentageComplete =
    percentagePaidDown < 1
      ? percentagePaidDown
      : Math.floor(percentagePaidDown);
  const { Track } = useTracking({ component: 'Loan Details' });
  return (
    <Track>
      <StyledCard>
        <StyledCardHeader>
          {approvedAmount ? (
            <Heading>
              <FormattedNumber value={approvedAmount} maximumFractionDigits={2}>
                {(num: string) => (
                  <FormattedMessage
                    id="loanDetails.termLoan.display"
                    values={{ num }}
                  />
                )}
              </FormattedNumber>
            </Heading>
          ) : (
            <div style={{ width: '35%' }}>
              <Skeleton />
            </div>
          )}
          <HeaderContent>
            {id ? (
              <FormattedMessage
                id="loanDetails.loanId.display"
                values={{
                  id
                }}
              />
            ) : (
              <div style={{ width: '150px', height: '20px' }}>
                <Skeleton />
              </div>
            )}
          </HeaderContent>
        </StyledCardHeader>
        <CardContent>
          <ProgressBar label value={percentageComplete} />
          <ContentRow>
            {typeof balancePaid !== 'undefined' ? (
              <div>
                <FormattedMoney value={balancePaid} />
                <AmountLabel>
                  <FormattedMessage id="loanDetails.totalPaid.message" />
                </AmountLabel>
              </div>
            ) : (
              <div style={{ width: '25%' }}>
                <Skeleton />
                <AmountLabel>
                  <Skeleton />
                </AmountLabel>
              </div>
            )}
            {balanceOutstanding ? (
              <div>
                <div style={{ display: 'flex' }}>
                  <FormattedMoney value={balanceOutstanding} />
                  <Tooltip tooltipWidth="257px">
                    {intl.formatMessage({
                      id: 'loanDetails.currentBalance.tooltip.message'
                    })}
                  </Tooltip>
                </div>
                <AmountLabel>
                  <FormattedMessage id="loanDetails.currentBalance.message" />
                </AmountLabel>
              </div>
            ) : (
              <div>
                <Skeleton width="6em" />
                <AmountLabel>
                  <Skeleton />
                </AmountLabel>
              </div>
            )}
          </ContentRow>
        </CardContent>
      </StyledCard>
    </Track>
  );
};

export default TermLoanDetails;
