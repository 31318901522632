import React, { useContext, useEffect, useState } from 'react';
import { Auth0Provider, useAuth0 } from '@bazaar/components';
import { makeServer } from '../server';

interface IAuthContext {
  loginServerEnabled: boolean;
  isAuthenticated: boolean;
  loading: boolean;
  user?: User;
  addCredentials: (options: any) => void;
  logout: Function;
}

const AuthContext = React.createContext<IAuthContext>({
  loginServerEnabled: true,
  isAuthenticated: false,
  loading: true,
  addCredentials: () => {},
  logout: () => {}
});

export const useAuth = () => useContext(AuthContext);

export const AuthResolver = ({ children }: { children: React.ReactNode }) => {
  const [loginServerEnabled, setLoginServerEnabled] = useState(true);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();

  useEffect(() => {
    if (process.env.REACT_APP_MOCK_SERVER_ENABLED === 'true') {
      const server = makeServer({ environment: 'development' });
      return server.shutdown;
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname === '/login-dev') {
      setLoading(false);
    } else {
      fetch(`${window.env.REACT_APP_API_URL}/users/profile`, {
        credentials: 'include'
      }).then(response => {
        response.json().then(rsBody => {
          if (rsBody.monorepoLogin) {
            window.location.href = window.env.REACT_APP_MONOREPO_URL;
          } else if (response.status === 403) {
            window.location.href = window.env.REACT_APP_LOGIN_PAGE_URL;
          } else if (response.status === 401) {
            setLoginServerEnabled(false);
          } else {
            setAuthenticated(true);
            setLoading(false);
            setUser(rsBody);
          }
        });
      });
    }
  }, []);

  return loginServerEnabled ? (
    <AuthProvider
      loginServerEnabled={loginServerEnabled}
      isAuthenticated={isAuthenticated}
      loading={loading}
      user={user}
    >
      {children}
    </AuthProvider>
  ) : (
    <Auth0Provider
      domain={window.env.REACT_APP_AUTH0_DOMAIN}
      audience={window.env.REACT_APP_AUTH0_AUDIENCE}
      clientID={window.env.REACT_APP_AUTH0_CLIENT_ID}
      connection={window.env.REACT_APP_AUTH0_CONNECTION}
      baseURL={window.env.REACT_APP_BASE_URL}
      externalRedirect={window.env.REACT_APP_AUTH0_EXTERNAL_APP}
      responseType="code"
      scope="profile"
    >
      <AuthProvider
        loginServerEnabled={loginServerEnabled}
        isAuthenticated={isAuthenticated}
        loading={loading}
      >
        {children}
      </AuthProvider>
    </Auth0Provider>
  );
};

const AuthProvider = ({
  children,
  loginServerEnabled,
  isAuthenticated,
  loading,
  user
}: {
  children: React.ReactNode;
  loginServerEnabled: boolean;
  isAuthenticated: boolean;
  loading: boolean;
  user?: User;
}) => {
  const auth0Context = useAuth0();
  const context: IAuthContext = auth0Context
    ? {
        ...auth0Context,
        loginServerEnabled,
        addCredentials: async (options: any) => {
          const token = await auth0Context.getTokenSilently();
          options.headers = {
            ...options.headers,
            Authorization: `Bearer ${token}`
          };
        }
      }
    : {
        loginServerEnabled,
        isAuthenticated,
        loading,
        user,
        addCredentials: (options: any) => {
          options.credentials = 'include';
        },
        logout: () => {
          fetch(`${window.env.REACT_APP_API_URL}/users/logout`, {
            method: 'POST',
            credentials: 'include'
          }).then(() => {
            window.location.href = window.env.REACT_APP_LOGIN_PAGE_URL;
          });
        }
      };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

export const SignOut = () => {
  const { logout } = useAuth();

  useEffect(() => {
    if (sessionStorage.getItem('notificationDismissed'))
      sessionStorage.removeItem('notificationDismissed');
    return logout('/sign-out');
  }, []);

  return null;
};
