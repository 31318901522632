import React, { createContext, useContext, useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';

interface ICriticalErrorContext {
  setHasError?: (b: boolean) => void;
  hasError?: boolean;
}

const CriticalErrorContext = createContext<
  React.PropsWithChildren<ICriticalErrorContext>
>({});

export const CriticalErrorProvider = ({
  children,
  setHasError,
  hasError
}: React.PropsWithChildren<ICriticalErrorContext>): React.ReactNode => {
  useEffect(() => {
    const errorHandler = (event: ErrorEvent) => {
      const error = event.error || new Error('Unknown error');
      if (process.env.NODE_ENV === 'development') {
        console.error(error);
      }
      datadogRum.addError(error);
    };

    window.addEventListener('error', errorHandler);
    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, []);

  return (
    <CriticalErrorContext.Provider
      value={{
        setHasError,
        hasError
      }}
    >
      {children}
    </CriticalErrorContext.Provider>
  );
};

export const useCriticalError = (): ICriticalErrorContext =>
  useContext(CriticalErrorContext);
