import React, { useEffect } from 'react';
import {
  NotFoundPage,
  ErrorPage as KegoErrorPage,
  useCriticalError
} from '@bazaar/components';
import { Routes, Route } from 'react-router-dom';

interface ErrorPageProps {
  error?: ResponseError;
}

const ErrorPage: React.FC<React.PropsWithChildren<ErrorPageProps>> = ({
  error
}: ErrorPageProps) => {
  const { setHasError } = useCriticalError();
  useEffect(() => {
    setHasError(true);
    return () => setHasError(false);
  }, [setHasError]);
  if (error?.status && error.status >= 400 && error.status <= 404) {
    return <NotFoundPage />;
  }
  return (
    <Routes>
      <Route path="/" element={<KegoErrorPage />} />
      <Route element={<KegoErrorPage showReturnHomeButton />} />
    </Routes>
  );
};

export default ErrorPage;
