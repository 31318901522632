import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Slider } from '@reach/slider';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import Input from '../Input/Input';
import SliderGlobalStyle from '../SliderInput/SliderInput.style';
import FormattedMoney from '../FormattedMoney/FormattedMoney';

const defaultMaskOptions = {
  prefix: '',
  allowDecimal: false,
  integerLimit: 7 // limit length of integer numbers
};

interface SliderInputProps {
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
  value?: number;
  currencyMask?: boolean;
  prefix?: string;
  onChange: (value: number) => void;
  errorMessage?: string;
}

const Flex = styled.div`
  display: flex;
  flex-direction: column;
`;

const SliderBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const SliderLabels = styled.div`
  display: flex;
  margin-bottom: 17px;
  font-size: 12px;
`;

const LabelLeft = styled.div``;

const LabelRight = styled.div`
  margin-left: auto;
`;

const CustomInput = styled(Input)`
  .prefix {
    background: #000082 0% 0% no-repeat padding-box;
    border-radius: 5px 0px 0px 5px;
    color: #ffffff;
    border: 1px solid #000082;
    width: 52px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 0;
  }
  .styled-input {
    width: 167px;
    border-radius: 0px 5px 5px 0px;
  }
  .styled-input:focus {
    outline: 1px solid #000082;
  }
  .label {
    display: none;
  }
  margin-top: 21px;
`;

const ErrorBox = styled.div`
  padding: 0px 5px;
  font-size: var(--fontSize6);
`;

const ErrorCode = styled.p`
  color: #d0021b;
  margin: 0px;
  word-break: break-word;
`;

const Error = ({ errorMsg }: { errorMsg: string }) => {
  return (
    <ErrorBox>
      <ErrorCode>{errorMsg}</ErrorCode>
    </ErrorBox>
  );
};

const SliderInput = ({
  min = 0,
  max = 1000,
  step = 1000,
  disabled,
  value,
  currencyMask = true,
  prefix = '$',
  onChange,
  errorMessage
}: SliderInputProps) => {
  return (
    <Flex>
      <SliderGlobalStyle />
      <SliderLabels>
        <LabelLeft>
          Min: <FormattedMoney value={min} />
        </LabelLeft>
        <LabelRight>
          Max: <FormattedMoney value={max} />
        </LabelRight>
      </SliderLabels>
      <SliderBox>
        <Slider
          onChange={newValue => onChange(newValue)}
          value={value}
          min={min}
          max={max}
          step={step}
          disabled={disabled}
        />
      </SliderBox>
      <CustomInput
        onChange={(e: any) =>
          onChange(parseFloat(e.target.value.replace(',', '')))
        }
        prefix={(<span>{prefix}</span>) as ReactElement & string}
        value={value || 0}
        mask={currencyMask && createNumberMask(defaultMaskOptions)}
      />
      {errorMessage && <Error errorMsg={errorMessage} />}
    </Flex>
  );
};

export default SliderInput;
