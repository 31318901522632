import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Link,
  Button,
  BigHeading,
  defaultBreakpoints,
  getMediaQuery
} from '@ondeck/silkworm';

const mQ = getMediaQuery(defaultBreakpoints);

const HomeButton = styled(Button)`
  padding: 0px 54px;
`;

const PageWrapper = styled.div`
  margin: 60px auto;
  padding: 30px;
  width: 100%;
  ${mQ.md`
    min-width: 992px;
  `}
`;

const GenericError: React.FC = (): React.ReactElement => {
  const intl = useIntl();
  return (
    <PageWrapper>
      <BigHeading>
        <FormattedMessage id="genericError.heading" />
      </BigHeading>
      <p>
        <FormattedMessage id="genericError.body" />{' '}
        <FormattedMessage
          id="genericError.email.message"
          values={{
            email: function renderEmail(txt: string): React.ReactElement {
              return (
                <Link
                  href={`mailto:${intl.formatMessage({
                    id: 'genericError.email.mailto'
                  })}`}
                  target="_self"
                >
                  {txt}
                </Link>
              );
            }
          }}
        />{' '}
        <FormattedMessage
          id="genericError.phone"
          values={{
            tel: function renderTel(txt: string): React.ReactElement {
              return (
                <Link href={`tel:${txt}`} target="_self">
                  {txt}
                </Link>
              );
            }
          }}
        />
        .
      </p>
      <p>
        <FormattedMessage id="genericError.body2" />
      </p>
      <Link to="/">
        <HomeButton>
          <FormattedMessage id="common.home" />
        </HomeButton>
      </Link>
    </PageWrapper>
  );
};
export default GenericError;
