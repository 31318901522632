import React from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';

import InfoIcon from '../../assets/images/Icon_Information.svg';

const StyledImage = styled.img`
  height: 16px;
  width: 16px;
  display: block;
  margin-bottom: 6px;
`;

export const InfoIconBadge = (): React.ReactElement => (
  <StyledImage src={InfoIcon} />
);

const InfoBoxWrapper = styled.div`
  --link-font-weight: var(--fontWeightBody);
  background-color: var(--color-blue-bg);
  border-radius: 0 10px 10px 10px;
  color: var(--color-muted);
  font-size: var(--fontSize6);
  padding: 16px;
  margin: var(--infobox-margin, auto 0 0);
`;

const StyledBadge = styled(InfoIconBadge)`
  flex-shrink: 0;
`;

interface InfoBoxProps {
  children: React.ReactNode;
  hideBadge?: boolean;
  loading?: boolean;
}

const InfoBox: React.FC<InfoBoxProps> = ({
  children,
  hideBadge,
  loading
}: InfoBoxProps): React.ReactElement => (
  <InfoBoxWrapper>
    {loading ? (
      <>
        <Skeleton circle={true} height="16px" width="16px" />
        <Skeleton />
        <Skeleton width="50%" />
      </>
    ) : (
      <>
        {!hideBadge && <StyledBadge />}
        <div>{children}</div>
      </>
    )}
  </InfoBoxWrapper>
);

export default InfoBox;
