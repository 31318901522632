import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { datadogRum } from '@datadog/browser-rum';

declare global {
  interface Window {
    env: {
      REACT_APP_ENV: string;
      REACT_APP_API_URL: string;
      REACT_APP_BUTTERFLY_URL: string;
      REACT_APP_BASE_URL: string;
      REACT_APP_AUTH0_DOMAIN: string;
      REACT_APP_AUTH0_CLIENT_ID: string;
      REACT_APP_AUTH0_AUDIENCE: string;
      REACT_APP_AUTH0_CONNECTION: string;
      REACT_APP_AUTH0_EXTERNAL_APP: string;
      REACT_APP_CHECKOUT_UX_URL: string;
      REACT_APP_INSTANT_FUNDING_DRAW_DISABLED: string;
      REACT_APP_MIXPANEL_DOMAIN: string;
      REACT_APP_MIXPANEL_TOKEN: string;
      REACT_APP_FULLSTORY_TOKEN: string;
      REACT_APP_SENTRY_API_URL: string;
      REACT_APP_OFFER_ORCHESTRATOR_V2_API_URL: string;
      REACT_APP_OPTIMIZELY_KEY: string;
      REACT_APP_ODO_URL: string;
      REACT_APP_LOGIN_PAGE_URL: string;
      REACT_APP_LOGIN_PAGE_JS_SRC: string;
      REACT_APP_LOGIN_SERVER_URL: string;
      REACT_APP_LOGIN_SERVER_CLIENT_ID: string;
      REACT_APP_LOGIN_SERVER_CAPTCHA_KEY: string;
      REACT_APP_DATADOG_RUM_APPLICATION_ID: string;
      REACT_APP_DATADOG_RUM_CLIENT_TOKEN: string;
      REACT_APP_DATADOG_RUM_ENABLED: boolean;
      REACT_APP_ADOBE_TAG: string;
      REACT_APP_MONOREPO_URL: string;
    };
  }
}
if (window.env.REACT_APP_DATADOG_RUM_ENABLED) {
  datadogRum.init({
    applicationId: window.env.REACT_APP_DATADOG_RUM_APPLICATION_ID,
    clientToken: window.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'ondeck_direct_ux',
    env: window.env.REACT_APP_ENV,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackSessionAcrossSubdomains: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingUrls: [
      window.env.REACT_APP_OFFER_ORCHESTRATOR_V2_API_URL,
      window.env.REACT_APP_API_URL,
      window.env.REACT_APP_BUTTERFLY_URL,
      window.env.REACT_APP_SENTRY_API_URL
    ]
  });
  datadogRum.startSessionReplayRecording();
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
