import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import Card, { CardHeader, CardContent } from '../Card/Card';
import FormattedMoney from '../FormattedMoney/FormattedMoney';
import ProgressBar from '../ProgressBar/ProgressBar';
import { useTracking } from 'react-tracking';
import Button from '../Button/Button';
import Tooltip from '../Tooltip/Tooltip';
import { mediaQuery as mQ } from '../../lib';

const StyledCard = styled(Card)`
  --card-content-padding: 0.5em 1.5em 1.5em;
  box-shadow: none;
`;

const StyledCardHeader = styled(CardHeader)`
  padding: 1.3em 1.5em;
`;

const Heading = styled.h4`
  margin: 0.6em 0;
`;

const ContentRow = styled.div`
  display: flex;
  font-size: var(--fontSize4);
  justify-content: space-between;
  margin: 1.6rem 0;
`;

const HeaderContent = styled.div`
  margin-bottom: 1.2em;
  font-size: 0.75em;
  font-weight: var(--fontWeightBody);
  ${mQ.md`
     margin: 0.6em 0;
  `}
`;

const AmountLabel = styled.div`
  color: var(--color-muted);
  font-size: 0.75em;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  ${mQ.md`
     flex-direction: row;
  `}
`;

const InfoWrapper = styled.div`
  ${mQ.md`
     width: 60%;
  `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  ${mQ.md`
    justify-content: end;
    width: 40%;
  `}
`;

type LOC = {
  creditLimit: number;
  balanceOutstanding: number;
  availableCredit: number;
  id: string;
};

const LOCDetails: React.FC<LOC> = ({
  creditLimit,
  balanceOutstanding,
  availableCredit,
  id
}: LOC): React.ReactElement => {
  const intl = useIntl();
  const availableCreditPercentage = (availableCredit / creditLimit) * 100;
  const { Track } = useTracking({ component: 'LOC Details' });
  return (
    <Track>
      <StyledCard>
        <StyledCardHeader>
          {creditLimit ? (
            <Heading>
              <FormattedNumber value={creditLimit} maximumFractionDigits={2}>
                {(num: string) => (
                  <FormattedMessage id="loc.display" values={{ num }} />
                )}
              </FormattedNumber>
            </Heading>
          ) : (
            <div style={{ width: '35%' }}>
              <Skeleton />
            </div>
          )}
          <HeaderContent>
            {id ? (
              <FormattedMessage
                id="loanDetails.loanId.display"
                values={{
                  id
                }}
              />
            ) : (
              <div style={{ width: '150px', height: '20px' }}>
                <Skeleton />
              </div>
            )}
          </HeaderContent>
        </StyledCardHeader>
        <CardContent>
          <Row>
            <InfoWrapper>
              <ProgressBar value={availableCreditPercentage} />
              <ContentRow>
                {typeof availableCredit !== 'undefined' ? (
                  <div>
                    <FormattedMoney value={availableCredit} />
                    <AmountLabel>
                      <FormattedMessage id="loc.availableCredit.message" />
                    </AmountLabel>
                  </div>
                ) : (
                  <div style={{ width: '25%' }}>
                    <Skeleton />
                    <AmountLabel>
                      <Skeleton />
                    </AmountLabel>
                  </div>
                )}
                {typeof balanceOutstanding !== 'undefined' ? (
                  <div>
                    <div style={{ display: 'flex' }}>
                      <FormattedMoney value={balanceOutstanding} />
                      <Tooltip tooltipWidth="257px">
                        {intl.formatMessage({
                          id: 'loc.currentBalance.tooltip.message'
                        })}
                      </Tooltip>
                    </div>
                    <AmountLabel>
                      <FormattedMessage id="loc.currentBalance.message" />
                    </AmountLabel>
                  </div>
                ) : (
                  <div>
                    <Skeleton width="6em" />
                    <AmountLabel>
                      <Skeleton />
                    </AmountLabel>
                  </div>
                )}
              </ContentRow>
            </InfoWrapper>
            {id && (
              <ButtonWrapper>
                <Button onClick={() => (window.location.href = '/drawfunds')}>
                  {intl.formatMessage({
                    id: 'loc.drawFunds'
                  })}
                </Button>
              </ButtonWrapper>
            )}
          </Row>
        </CardContent>
      </StyledCard>
    </Track>
  );
};

export default LOCDetails;
