import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { mediaQuery as mQ } from '../../lib';
import Logo from '../../assets/Logo';
import Link from '../Link/Link';
import { useTracking } from 'react-tracking';

const Footer = styled.footer`
  background-color: var(--color-primary-dark, #010135);
  color: var(--color-white, #fff);
`;

const Wrapper = styled.div`
  padding: 32px 16px 75px;
  max-width: var(--viewportLg);
  margin: 0 auto;
`;

const TopContents = styled.div`
  ${mQ.sm`
    display: flex;
    align-item: center;
    justify-content: space-between;
  `}
`;

const OndeckInfo = styled.div`
  font-size: var(--mediumFontSize, 17px);
  font-weight: var(--fontWeightMedium, 500);
  line-height: normal;
  margin-top: 25px;
  ${mQ.sm`
    margin-top: 0;
    text-align: right;
  `}
`;

const Rule = styled.div`
  border-top: 1px solid #fff;
  margin: 23px 0;
`;

const FooterLink = styled(Link)`
  color: var(--color-white);
  &:focus,
  &:hover {
    color: inherit;
    opacity: 0.7;
    text-decoration: none;
  }
`;

const PolicyLink = styled(FooterLink)`
  display: inline-block;
  font-weight: var(--fontWeightBold, 700);
  margin-right: 36px;
`;

const Disclaimer = styled.div`
  font-size: var(--fontSize6);
  margin-top: 24px;
`;

interface PageFooterProps extends React.HTMLAttributes<HTMLElement> {
  privacyPolicyHref: string;
  securityPolicyHref: string;
}

const PageFooter: React.FC<PageFooterProps> = ({
  className,
  privacyPolicyHref,
  securityPolicyHref
}: PageFooterProps): React.ReactElement => {
  const intl = useIntl();
  const { Track } = useTracking({ component: 'Page Footer' });
  return (
    <Track>
      <Footer className={className}>
        <Wrapper>
          <TopContents>
            <Logo height="27px" width="126px" onFill="#fff" deckFill="#fff" />
            <OndeckInfo>
              <FooterLink
                href={intl.formatMessage({
                  id: 'contactInfo.phone.helpline.link'
                })}
                target="_self"
              >
                {intl.formatMessage({
                  id: 'contactInfo.phone.helpline'
                })}
              </FooterLink>
              <div>
                {intl.formatMessage({
                  id: 'contactInfo.businessHours'
                })}
              </div>
            </OndeckInfo>
          </TopContents>
          <Rule />
          <PolicyLink href={privacyPolicyHref} target="_blank">
            {intl.formatMessage({
              id: 'common.privacyPolicy'
            })}
          </PolicyLink>
          <PolicyLink href={securityPolicyHref} target="_blank">
            {intl.formatMessage({
              id: 'common.securityPolicy'
            })}
          </PolicyLink>
          <Disclaimer>
            {intl.formatMessage({
              id: 'legal.loanDisclaimer.state'
            })}
          </Disclaimer>
          <Disclaimer>
            {intl.formatMessage({
              id: 'legal.copyrightFormality'
            })}
          </Disclaimer>
        </Wrapper>
      </Footer>
    </Track>
  );
};

export default PageFooter;
