import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  Exclamation,
  CheckmarkFill,
  Input,
  LockShield,
  useAuth0,
  Button
} from '@bazaar/components';

const StyledExclamation = styled(Exclamation)`
  width: 18px;
  margin-right: 17px;
  display: block;
`;
const Container = styled.div`
  background-color: #ffffff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.13);
  height: fit-content;
  margin: 20px auto;
  width: 477px;
  & input:disabled {
    font-style: italic;
    opacity: 0.5;
  }
`;
const ContainerBox = styled.div`
  padding: 70px 50px;
  min-height: 624px;
`;
const Heading = styled.h1`
  color: #000;
  font-size: 36px;
  margin: 30px 0 20px 0;
`;
const CustomInput = styled(Input)`
  font-size: 18px;
  width: 100%;
`;
const ErrorBox = styled.div`
  padding: 27px 16px 23px;
  display: flex;
  border-bottom: solid 1px #ccc;
`;
const ErrorCode = styled.p`
  margin: 0px;
`;
const ErrorMsg = styled.p`
  color: #d41818;
  margin: 10px 0px 0px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: center;
`;
const LoginBtn = styled(Button)`
  width: 152px;
  height: 40px;
  white-space: nowrap;
  text-align: center;
  padding: 0;
`;
const StyledLabelWrapper = styled.div`
  width: 388px;
  height: 90px;
  line-height: 30px;
`;
const SubmittedText = styled.div`
  margin-top: 1em;
  position: relative;
  padding-left: 40px;
  .icon {
    position: absolute;
    left: 0;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 40px;
  position: absolute;
  font-size: var(--fontSize5);
  font-weight: var(--fontWeightBody);
  font-family: 'Galano Grotesque';
  height: 20px;
  min-width: 45px;
  padding: 0px;
  &:focus {
    outline: none;
  }
`;

interface ForgotPasswordFormInputs {
  email: string;
}

const ForgotPassword = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const schema = Yup.object().shape({
    email: Yup.string().email().required()
  });
  const { register, errors, handleSubmit } = useForm<ForgotPasswordFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange'
  });
  const [submitted, setSubmitted] = useState(false);
  const { changePassword, changePasswordError, isAuthenticated } = useAuth0();
  const onSubmit = (data: ForgotPasswordFormInputs) => {
    changePassword(data.email);
    setSubmitted(true);
  };
  const changeRequestSucceeded = submitted && !changePasswordError;

  return (
    <Container>
      {changePasswordError && (
        <ErrorBox>
          <StyledExclamation />
          <ErrorCode>
            {intl.formatMessage({ id: 'forgotPassword.connection.error' })}
          </ErrorCode>
        </ErrorBox>
      )}
      <ContainerBox>
        <LockShield />
        <Heading>
          {intl.formatMessage({ id: 'forgotPassword.heading' })}
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CustomInput
            id="email"
            name="email"
            type="text"
            disabled={changeRequestSucceeded}
            ref={register}
          >
            <StyledLabelWrapper>
              {intl.formatMessage({ id: 'forgotPassword.label' })}
            </StyledLabelWrapper>
          </CustomInput>

          {errors.email && errors.email.type === 'required' && (
            <ErrorMsg>{intl.formatMessage({ id: 'common.required' })}</ErrorMsg>
          )}
          {errors.email && errors.email.type === 'email' && (
            <ErrorMsg>
              {intl.formatMessage({ id: 'login.email.invalidEntry' })}
            </ErrorMsg>
          )}

          {changeRequestSucceeded ? (
            <SubmittedText data-testid="submittedCopy">
              <CheckmarkFill height="30" width="30" className="icon" />
              <FormattedMessage id="forgotPassword.submittedCopy" />
            </SubmittedText>
          ) : (
            <StyledButton variant="link" onClick={() => navigate(-1)}>
              &lsaquo; {intl.formatMessage({ id: 'common.back' })}
            </StyledButton>
          )}
          <ButtonWrapper>
            {changeRequestSucceeded ? (
              <>
                <LoginBtn onClick={() => navigate(-1)}>
                  {intl.formatMessage({
                    id: isAuthenticated
                      ? 'forgotPassword.buttonAfterSubmittedAuthenticated'
                      : 'forgotPassword.buttonAfterSubmitted'
                  })}
                </LoginBtn>
              </>
            ) : (
              <LoginBtn type="submit">
                {intl.formatMessage({
                  id: 'forgotPassword.button'
                })}
              </LoginBtn>
            )}
          </ButtonWrapper>
        </form>
      </ContainerBox>
    </Container>
  );
};

export default ForgotPassword;
