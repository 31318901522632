import React from 'react';
import { ReactComponent as Icon } from './svg/CheckmarkFill.svg';

const CheckmarkFill = (props: {
  height: string;
  width: string;
}): React.ReactNode => <Icon {...props} />;

CheckmarkFill.defaultProps = {
  height: '30px',
  width: '30px'
};

export default CheckmarkFill;
