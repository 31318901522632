import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { browserVersion, isSafari, isIE } from 'react-device-detect';

import {
  Button,
  Link,
  getMediaQuery,
  defaultBreakpoints
} from '@ondeck/silkworm';
import {
  Input,
  useAuth0,
  Exclamation,
  LockShield,
  InfoBox
} from '@bazaar/components';

const mQ = getMediaQuery(defaultBreakpoints);

const StyledExclamation = styled(Exclamation)`
  width: 18px;
  margin-right: 17px;
  display: block;
`;

const Container = styled.div`
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.13);
  background-color: #fff;
  height: fit-content;
  margin: 20px auto;
  width: 100%;
  ${mQ.md`
    width: 477px;
  `}
`;

const ErrorBox = styled.div`
  padding: 27px 16px 23px;
  display: flex;
  border-bottom: solid 1px #ccc;
`;

const NoLoanError = styled(ErrorBox)`
  flex-direction: column;
  div {
    display: flex;
  }
  > p {
    margin: 0px 0px 0px 35px;
  }
`;

const ErrorCode = styled.p`
  color: #d0021b;
  margin: 0px;
  word-break: break-word;
`;

const LoginBox = styled.div`
  padding: 70px 50px;
  min-height: 624px;
`;

const Heading = styled.h1`
  color: #000;
  font-size: 36px;
  margin: 30px 0 20px 0;
`;

const InputsWrapper = styled.div`
  margin-bottom: 0.5em;
`;

const CustomInput = styled(Input)`
  font-size: 18px;
  width: 100%;
  label {
    margin-bottom: 8px;
  }
`;

const ErrorMsg = styled.p`
  color: #d41818;
  margin: 10px 0px 0px;
`;

const ForgotPasswordLink = styled(Link)`
  color: #0444db;
  font-weight: 600;
  &:hover {
    color: #0444db;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 50px;
  margin-bottom: 20px;
  justify-content: center;
`;

const LoginBtn = styled(Button)`
  width: 152px;
  height: 40px;
`;

interface LoginFormInputs {
  email: string;
  password: string;
}

const Login = ({ queryParameters: { noloan = false } = {} }) => {
  const intl = useIntl();
  const { register, errors, handleSubmit } = useForm<LoginFormInputs>();
  const [loginError, setLoginError] = useState(false);
  const { login, callbackResponse } = useAuth0();

  useEffect(() => {
    if (callbackResponse.code) setLoginError(true);
  }, [callbackResponse]);

  const isBrowserValid = () => {
    return !(
      (isSafari && parseInt(browserVersion) <= 13) ||
      (isIE && parseInt(browserVersion) <= 10)
    );
  };

  const onSubmit = (data: LoginFormInputs) => {
    const { email, password } = data;
    login(email, password);
  };

  const phonenumber = intl.formatMessage({
    id: 'common.contactNumber'
  });
  return (
    <Container>
      {noloan && !loginError && (
        <>
          <NoLoanError>
            <div>
              <StyledExclamation />
              <ErrorCode>
                {intl.formatMessage({
                  id: 'login.error.incompleteProfile.error'
                })}
              </ErrorCode>
            </div>
            <p>
              {intl.formatMessage(
                {
                  id: 'login.error.incompleteProfile.contact'
                },
                {
                  phonenumber: (
                    <Link
                      key="common.contactNumber"
                      href={`tel:+${phonenumber}`}
                      target="_self"
                    >
                      {phonenumber}
                    </Link>
                  )
                }
              )}
            </p>
          </NoLoanError>
        </>
      )}
      {loginError && (
        <ErrorBox>
          <StyledExclamation />
          <ErrorCode>
            {callbackResponse.code === 'access_denied'
              ? intl.formatMessage({ id: 'login.error.accessDenied.message' })
              : callbackResponse.code === 'too_many_attempts'
              ? intl.formatMessage({
                  id: 'login.error.tooManyAttempts.message'
                })
              : callbackResponse.description}
          </ErrorCode>
        </ErrorBox>
      )}
      <LoginBox>
        <LockShield />
        <Heading>{intl.formatMessage({ id: 'login.message' })}</Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputsWrapper>
            <CustomInput
              id="email"
              name="email"
              type="text"
              ref={register({
                required: 'Required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'invalid email address'
                }
              })}
            >
              {intl.formatMessage({ id: 'login.email' })}
            </CustomInput>
            {errors.email && errors.email.type === 'required' && (
              <ErrorMsg>
                {intl.formatMessage({ id: 'common.required' })}
              </ErrorMsg>
            )}
            {errors.email && errors.email.type === 'pattern' && (
              <ErrorMsg>
                {intl.formatMessage({ id: 'login.email.invalidEntry' })}
              </ErrorMsg>
            )}
            <CustomInput
              id="password"
              name="password"
              type="password"
              ref={register({ required: true })}
            >
              {intl.formatMessage({ id: 'login.password' })}
            </CustomInput>
            {errors.password && (
              <ErrorMsg>
                {intl.formatMessage({ id: 'common.required' })}
              </ErrorMsg>
            )}
          </InputsWrapper>
          <ForgotPasswordLink to="/forgot-password">
            Forgot Password?
          </ForgotPasswordLink>
          <ButtonWrapper>
            <LoginBtn type="submit">
              {intl.formatMessage({ id: 'login.signIn' })}
            </LoginBtn>
          </ButtonWrapper>
        </form>
        {!isBrowserValid() && (
          <InfoBox>
            {intl.formatMessage({ id: 'login.browserWarning' })}
          </InfoBox>
        )}
      </LoginBox>
    </Container>
  );
};

export default Login;
