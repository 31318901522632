import React from 'react';

interface LogoProps {
  onFill?: string;
  deckFill?: string;
  width?: string;
  height?: string;
}

const Logo: React.FC<LogoProps> = ({
  onFill,
  deckFill,
  width,
  height
}: LogoProps): React.ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 75 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>OnDeck</title>
    <path
      d="M12.07 10.294c0 3.424-2.588 5.635-6 5.635-3.4 0-5.964-2.2-5.964-5.6 0-3.423 2.588-5.635 6-5.635 3.4 0 5.965 2.2 5.965 5.6zM6.06 13.071c1.6 0 2.73-1.177 2.73-2.777s-1.095-2.73-2.695-2.73c-1.6 0-2.73 1.177-2.73 2.777.012 1.6 1.095 2.73 2.695 2.73zM16.87 15.659h-3.188V4.976h3.189v1.212A4.375 4.375 0 0 1 20.2 4.694c2.482 0 3.87 1.67 3.87 4.506v6.447h-3.188V9.706c0-1.365-.764-2.2-2.011-2.2-1.236 0-2.012.835-2.012 2.176v5.977h.012z"
      fill={onFill}
    />
    <path
      d="M34.224 14.4c-.765.965-1.906 1.541-3.377 1.541-3.165 0-5.13-2.459-5.13-5.623 0-3.165 2.048-5.624 5.212-5.624 1.447 0 2.542.53 3.295 1.447V.06h3.188v15.588h-3.188V14.4zm-2.612-1.282c1.623 0 2.73-1.212 2.73-2.836 0-1.576-1.071-2.753-2.648-2.753-1.623 0-2.718 1.177-2.718 2.8.012 1.565 1.06 2.789 2.636 2.789zM39.024 10.318c0-3.377 2.482-5.624 5.811-5.624 3.2 0 5.553 2.259 5.553 5.388 0 .365-.023.753-.047 1.094h-8.035c.341 1.212 1.341 2.048 2.753 2.048 1.023 0 1.835-.389 2.565-1.212l1.964 1.917c-1.259 1.389-2.823 2.012-4.682 2.012-3.365 0-5.882-2.247-5.882-5.623zm3.329-1.212h4.718c-.26-1.047-1.177-1.753-2.33-1.753-1.235 0-2.059.718-2.388 1.753zM57.541 13.047c1 0 1.835-.53 2.459-1.341l2.153 1.918c-1.153 1.447-2.73 2.305-4.659 2.305-3.376 0-5.894-2.247-5.894-5.623 0-3.377 2.518-5.624 5.894-5.624 1.906 0 3.53.789 4.659 2.26L60 8.928c-.6-.811-1.447-1.34-2.459-1.34-1.576 0-2.67 1.152-2.67 2.729 0 1.576 1.094 2.73 2.67 2.73zM67.93 11.07l-1.306.871v3.718h-3.189V.059h3.189v8.565l6.752-4.577h.13v3.53l-2.824 1.882 3.565 6.07v.13h-3.635z"
      fill={deckFill}
    />
  </svg>
);

Logo.defaultProps = {
  onFill: '#0074FF',
  deckFill: '#000082',
  width: '170',
  height: '36'
};

export default Logo;
