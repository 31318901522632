import React from 'react';
import styled from 'styled-components';
import Button from '../Button/Button';

const Title = styled.h4`
  color: var(--color-white);
  font-weight: var(--fontWeightMedium);
  font-size: var(--fontSize1);
  margin: 0;
`;

const TextWrapper = styled.div`
  background-color: #0064dd;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 1em 2em;
  margin-bottom: 2em;
`;

const CTAButton = styled(Button)`
  border: 2px solid #ffffff;
  border-radius: 24px;
  opacity: 1;
  background-color: inherit;
  padding: 12px 24px;
  min-width: fit-content;
  &:hover {
    background-color: #ffffff;
    color: #0064dd;
  }
`;

const Message = styled.div`
  font-size: var(--fontSize4);
  color: var(--color-white);
  padding: 1em 1em 1em;
`;

const CloseIcon = styled.div<{ color?: string }>`
  position: relative;
  border-radius: 50%;
  background: var(--label-${p => p.color});
  cursor: pointer;
  width: 17px;
  height: 17px;
  align-self: flex-end;
  :before,
  :after {
    position: absolute;
    right: 44%;
    top: 20%;
    content: ' ';
    height: 10px;
    width: 2px;
    background-color: #fff;
  }
  :before {
    transform: rotate(45deg);
  }
  :after {
    transform: rotate(-45deg);
  }
`;

const ODORenewal = ({
  onClick,
  onDismiss,
  cta,
  title,
  message
}: {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onDismiss?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  cta?: string;
  title?: string;
  message: string;
}): React.ReactElement => {
  return (
    <TextWrapper>
      <CloseIcon onClick={onDismiss} />
      {title && <Title>{title}</Title>}
      {message && <Message>{message}</Message>}
      {cta && <CTAButton onClick={onClick}>{cta}</CTAButton>}
    </TextWrapper>
  );
};

export default ODORenewal;
