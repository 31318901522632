import React, { HTMLAttributes, useCallback, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';

const anchorStyles = `
  color: var(--color-anchor);
  cursor: pointer;
  font-weight: var(--link-font-weight, var(--fontWeightSemiBold));
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
    color: var(--color-anchor-hover);
  }
`;

const StyledLink = styled(RouterLink)`
  ${anchorStyles}
`;

const StyledAnchor = styled.a`
  ${anchorStyles}
`;

interface LinkProps extends HTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode;
  href?: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
  to?: string;
}
const Link: React.FC<LinkProps> = ({
  children,
  onClick,
  ...props
}: LinkProps): React.ReactElement => {
  const { trackEvent } = useTracking({ event: 'Link Clicked' });
  const linkRef = useRef<HTMLAnchorElement>(null);
  const onClickHandler = useCallback(
    evt => {
      trackEvent({
        content: linkRef?.current?.textContent,
        href: props.to || props.href
      });
      onClick && onClick(evt);
    },
    [onClick, props.href, props.to, trackEvent]
  );

  if (props.to) {
    return (
      <StyledLink
        ref={linkRef}
        to={props.to}
        onClick={onClickHandler}
        {...props}
      >
        {children}
      </StyledLink>
    );
  }
  return (
    <StyledAnchor
      ref={linkRef}
      target={props.target || '_blank'}
      onClick={onClickHandler}
      {...props}
    >
      {children}
    </StyledAnchor>
  );
};

export default Link;
