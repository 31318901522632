import React, { createContext, useContext, useEffect, useState } from 'react';
import { usePartiesAPI } from './PartiesAPI';
import useFetch from 'use-http';

const UserContext = createContext({});

export const ProfileAPIProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const { partiesData } = usePartiesAPI();
  const [profileDetails, setProfileDetails] = useState<Profile | null>(null);
  const personUUID = partiesData?.user?.id;
  const profileHref = `${window.env.REACT_APP_API_URL}/persons/${personUUID}`;
  const { get: getProfileDetails, response } = useFetch(profileHref, {
    cacheLife: 5000
  });

  useEffect(() => {
    fetchProfileDetails();
    // eslint-disable-next-line
  }, [])

  async function fetchProfileDetails() {
    if (partiesData) {
      const hrefResponse = await getProfileDetails();
      setProfileDetails(hrefResponse);
      const { ok, status, statusText } = response;
      if (!ok) {
        throw new Error(
          `The Parties API returned an error ${status}: ${statusText}`
        );
      }
    }
  }

  return (
    <UserContext.Provider value={{ profileDetails, setProfileDetails }}>
      {children}
    </UserContext.Provider>
  );
};

export const useProfileAPI = (): any => useContext(UserContext);
