import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import {
  Card,
  CardContent,
  CardHeader,
  DataGrid,
  Button,
  PageTitle
} from '@bazaar/components';
import { usePartiesAPI } from '../../../contexts/PartiesAPI';
import useFetch from 'use-http';
import { useTracking } from 'react-tracking';
import EditBusinessProfileForm from './EditBusinessProfileForm';
import { datadogRum } from '@datadog/browser-rum';

const Grid = styled.div`
  display: grid;
  gap: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 1.2em;
  justify-content: center;
`;

const EditBtn = styled(Button)`
  width: 215px;
  height: 44px;
`;

interface FormattedBusinessProfile {
  taxId?: string;
  legalEntityType?: string;
  phone?: IContact;
  businessAddress?: IAddress;
  subtitleAddress?: string;
  bankAcc?: string;
}

const formatBusinessDetails = (rawData?: BusinessProfile) => {
  const identifiers = rawData?.identifiers;
  const maskIdentifier = (type: string) =>
    identifiers
      ?.find(identifier => identifier.type === type)
      ?.value.replaceAll('*', '\u00B7');
  const entityType = rawData?.legalEntityType
    ?.split('_')
    .map(segment => {
      if (['LLC', 'LLP', 'LP'].includes(segment)) {
        return segment;
      }
      return `${segment[0]}${segment.substring(1).toLowerCase()}`;
    })
    .join(' ');
  const phoneNumber = rawData?.contacts?.find(
    contact => contact.type === 'PHONE_NUMBER'
  );
  const businessAddress = rawData?.addresses?.find(
    address => address.type === 'PHYSICAL_ADDRESS'
  );
  const subtitleAddress = businessAddress?.lines.join(', ');
  return {
    taxId: maskIdentifier('TAX_ID'),
    legalEntityType: entityType,
    phone: phoneNumber,
    businessAddress: businessAddress,
    subtitleAddress: subtitleAddress,
    bankAcc: maskIdentifier('ABN')
  };
};

const Profile = () => {
  const { partiesData } = usePartiesAPI();
  const { trackEvent, Track } = useTracking({ component: 'Business Profile' });
  const initialRenderTime = useMemo(() => Date.now(), []);

  const [businessDetails, setBusinessDetails] = useState<BusinessProfile>();
  const [formattedBusinessProfile, setFormattedBusinessProfile] = useState<
    FormattedBusinessProfile
  >();
  const businessEndpoint = partiesData.parties[0].businesses[0].href;
  const { get: getBusinessDetails, cache } = useFetch(businessEndpoint);

  const getFormattedBusinessDetails = useCallback(() => {
    const result = formatBusinessDetails(businessDetails);
    setFormattedBusinessProfile(result);
  }, [businessDetails]);

  useEffect(() => {
    const fetchBusinessDetails = async () => {
      const response = await getBusinessDetails();
      trackEvent({
        event: 'Page Loaded',
        readyTime: (Date.now() - initialRenderTime) / 1000
      });
      setBusinessDetails(response);
    };
    fetchBusinessDetails();
  }, [trackEvent, getBusinessDetails, initialRenderTime]);

  useEffect(() => {
    getFormattedBusinessDetails();
  }, [getFormattedBusinessDetails]);

  const intl = useIntl();
  const [editingForm, setEditingForm] = useState(false);
  const legalName = businessDetails?.legalName;
  const doneEditing = () => {
    setEditingForm(false);
    cache.delete(`url:${businessEndpoint}||method:GET||body:`);
  };
  const address = formattedBusinessProfile?.businessAddress;
  const addressContents = () =>
    address && (
      <>
        {address.lines.map((line, lineNumber) => (
          <div key={lineNumber}>{line}</div>
        ))}
        <div>{`${address.city}, ${address.state} ${address.postalCode}`}</div>
      </>
    );

  return (
    <Track>
      <Grid>
        <PageTitle
          title={businessDetails?.legalName}
          subtitle={formattedBusinessProfile?.subtitleAddress}
        />
        <Card>
          <CardHeader>
            {businessDetails?.id ? 'Business Info' : <Skeleton width="8em" />}
          </CardHeader>
          <CardContent>
            {editingForm ? (
              <EditBusinessProfileForm
                done={doneEditing}
                legalName={legalName}
                taxId={formattedBusinessProfile?.taxId}
                legalEntityType={formattedBusinessProfile?.legalEntityType}
                phone={formattedBusinessProfile?.phone}
                businessAddress={address}
                bankAcc={formattedBusinessProfile?.bankAcc}
                setBusinessDetails={setBusinessDetails}
              />
            ) : (
              <>
                <DataGrid
                  rows={[
                    ['Legal Business Name', legalName],
                    ['Tax ID', formattedBusinessProfile?.taxId],
                    ['Entity Type', formattedBusinessProfile?.legalEntityType],
                    ['Phone', formattedBusinessProfile?.phone?.value],
                    ['Business Address', address && addressContents],
                    ['Business Bank Account', formattedBusinessProfile?.bankAcc]
                  ]}
                />
                {/*<ButtonWrapper>*/}
                {/*  <EditBtn*/}
                {/*    variant="square"*/}
                {/*    unfilled*/}
                {/*    onClick={() => setEditingForm(true)}*/}
                {/*  >*/}
                {/*    {intl.formatMessage({ id: 'common.edit' })}*/}
                {/*  </EditBtn>*/}
                {/*</ButtonWrapper>*/}
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Track>
  );
};

export default Profile;
