import React, { useState } from 'react';
import styled from 'styled-components';
import { animated, useTransition } from 'react-spring';

import useMedia from '../../hooks/useMedia';
import { breakpoints, mediaQuery as mQ } from '../../lib';

import Button from '../Button/Button';

import BlueBackground from '../../assets/images/Background_Blob-Blue.svg';
import GreenBackground from '../../assets/images/Background_Blob-Green.svg';
import { useTracking } from 'react-tracking';

const backgroundBlobs = {
  blue: BlueBackground,
  green: GreenBackground
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImgDiv = styled.div<{ bgColor: 'green' | 'blue' }>`
  background-position: center top;
  background-image: url(${p => backgroundBlobs[p.bgColor]});
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  right: 0;
  z-index: -1;
  width: 100%;
  height: 360px;
  ${mQ.lg`
    max-width: 655px;
    left: auto;
    right: auto;
  `}
`;

const ImageWrapper = styled.figure`
  display: none;
  margin: 0;
  ${mQ.sm`
    display: block;
  `}
`;

const TextWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3em;
  max-width: 512px;
  ${mQ.sm`
    .imgWrapper + & {
      flex-basis: 60%;
    }
    margin-top: 3em;
  `}
`;

const Title = styled.h4`
  color: var(--color-brand);
  font-weight: var(--fontWeightMedium);
  font-size: var(--fontSize1);
  margin: 0;
`;

const Message = styled.p`
  font-size: var(--fontSize4);
`;

const CTAButton = styled(Button)`
  box-shadow: 0px 2px 15px 2px rgba(0, 0, 0, 15%);
  cursor: pointer;
  margin-top: 1.6rem;
`;

const DismissMessage = styled(Button)`
  font-size: var(--fontSize5);
  font-weight: var(--fontWeightBold);
  margin-top: 1.6rem;
  padding: 0 10px 24px;
`;

const RenewalNotification = ({
  bgColor = 'blue',
  cta,
  dismissMessage = 'Dismiss',
  imageSrc,
  message,
  onClick,
  onDismiss,
  title,
  hideOnDismiss,
  height
}: {
  bgColor?: 'blue' | 'green';
  cta?: string;
  dismissMessage?: string;
  imageSrc?: string;
  message?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onDismiss?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  title?: string;
  hideOnDismiss?: boolean;
  height?: string;
}): React.ReactElement => {
  const [hidden, setHidden] = useState(false);
  const isMobile = useMedia([`(min-width: ${breakpoints.sm})`], [false], true);

  const mobileTransitions = useTransition(hidden, null, {
    from: { marginBottom: '0px', opacity: 0 },
    enter: () => async next => {
      await next({ opacity: 1 });
      await next({ marginBottom: '-20px' });
    },
    leave: { opacity: 0 }
  });
  const desktopTransitions = useTransition(hidden, null, {
    from: { opacity: 0, marginBottom: '0px', height: '0px' },
    enter: () => async next => {
      await next({ opacity: 1, height: height || '320px' });
      await next({ marginBottom: '-24px' });
    },
    leave: () => async next => {
      await next({ marginBottom: '-16px' });
      await next({ opacity: 0, height: '0px', marginBottom: '0px' });
    },
    config: {
      tension: 290
    }
  });
  const transitions = isMobile ? mobileTransitions : desktopTransitions;
  const { Track } = useTracking({ component: 'Renewal Notification' });
  return (
    <Track>
      <ImgDiv bgColor={bgColor} />
      {transitions.map(({ item, key, props }) => {
        return (
          !item && (
            <animated.div key={key} style={props}>
              <Wrapper>
                {imageSrc && (
                  <ImageWrapper className="imgWrapper">
                    <img alt="" src={imageSrc} />
                  </ImageWrapper>
                )}
                <TextWrapper>
                  {title && <Title>{title}</Title>}
                  {message && <Message>{message}</Message>}
                  {cta && <CTAButton onClick={onClick}>{cta}</CTAButton>}
                  {dismissMessage && (
                    <div>
                      <DismissMessage
                        variant="link"
                        onClick={e => {
                          onDismiss && onDismiss(e);
                          hideOnDismiss && setHidden(hideOnDismiss);
                        }}
                      >
                        {dismissMessage}
                      </DismissMessage>
                    </div>
                  )}
                </TextWrapper>
              </Wrapper>
            </animated.div>
          )
        );
      })}
    </Track>
  );
};

export default RenewalNotification;
