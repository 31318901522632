import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedDate, FormattedMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';

import Button from '../Button/Button';
import Card, { CardHeader, CardContent } from '../Card/Card';
import FormattedMoney from '../FormattedMoney/FormattedMoney';
import Label from '../Label/Label';
import { useTracking } from 'react-tracking';

import { breakpoints } from '../../lib';

import DownloadIcon from '../../assets/images/Icon_Download.svg';
import useMedia from '../../hooks/useMedia';

const Heading = styled.h4`
  margin: 0;
`;

const DownloadLink = styled.a.attrs({
  target: '_blank'
})`
  color: var(--color-blue);
  font-size: var(--fontSize6);
  font-weight: var(--fontWeightSemiBold);
  text-decoration: none;
  > img {
    margin-left: 8px;
    vertical-align: sub;
  }
`;

const TransactionsListUL = styled.ul`
  --label-font-size: 1em;
  --label-font-weight: 400;
  font-size: var(--fontSize5);
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid var(--color-separator);
`;

const TransactionsListLI = styled.li<{ isPending?: boolean }>`
  border-bottom: 1px solid var(--color-separator);
  color: ${p => (p.isPending ? '#767676' : '#000')};
  padding: 1em 0;
  display: flex;
  justify-content: space-between;
  > div {
    text-align: center;
    width: 100%;
  }
  > div:first-child {
    text-align: left;
    font-weight: var(--fontWeightSemiBold);
  }
  > div:last-child {
    text-align: right;
    padding-bottom: 1em;
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 3em;
`;

const PendingLabel = styled(Label)`
  --label-bg-color: transparent;
  --label-color: #767676;
`;

const CustomWrapper = styled.div`
  > div:last-child {
    font-weight: var(--fontWeightBody);
    font-size: var(--fontSize6);
  }
`;

enum TransactionStatus {
  C = 'Completed',
  P = 'Pending',
  E = 'Error',
  X = 'Cancelled'
}

interface Transaction {
  amount: number;
  beginningBalance: number;
  clearedDate: string;
  currency: string;
  initiatedDate: string;
  interest?: number;
  loanId: string;
  loanType: string;
  principal?: number;
  remainingBalance: number;
  status: keyof typeof TransactionStatus;
  transactionSetId: string;
  type: string;
}

interface ActivityProps {
  downloadURL?: string;
  onDownloadLinkClick?: (e: React.SyntheticEvent) => void;
  transactions?: Transaction[];
}

const TransactionListItem = (props: Transaction) => {
  const isSmall = useMedia([`(min-width: ${breakpoints.sm})`], [false], true);
  const pending = TransactionStatus[props.status] === 'Pending';
  const completed = TransactionStatus[props.status] === 'Completed';
  const error = TransactionStatus[props.status] === 'Error';
  const cancelled = TransactionStatus[props.status] === 'Cancelled';
  return (
    <TransactionsListLI isPending={pending}>
      {isSmall ? (
        <CustomWrapper>
          {/* //converting string to date has dumb time zone issue, so we must use a specific format */}
          <FormattedDate
            value={
              pending
                ? props.initiatedDate.replace(/-/g, '/').replace(/T.+/, '')
                : props.clearedDate.replace(/-/g, '/').replace(/T.+/, '')
            }
          />
          <div>{props.type}</div>
        </CustomWrapper>
      ) : (
        <>
          <div>
            {/* //converting string to date has dumb time zone issue, so we must use a specific format */}
            <FormattedDate
              value={
                pending
                  ? props.initiatedDate.replace(/-/g, '/').replace(/T.+/, '')
                  : props.clearedDate.replace(/-/g, '/').replace(/T.+/, '')
              }
            />
          </div>
          <div>{props.type}</div>
        </>
      )}
      <div>{TransactionStatus[props.status]}</div>
      <div>
        {props.loanType == 'FIXED_TERM' ? 'Term Loan' : 'Line of Credit'}
      </div>
      <div>
        {completed ? (
          <Label color="green">
            <FormattedMoney value={props.amount} />
          </Label>
        ) : error || cancelled ? (
          <Label color="red">
            <FormattedMoney value={props.amount} />
          </Label>
        ) : (
          <PendingLabel>
            <FormattedMoney value={props.amount} />
          </PendingLabel>
        )}
      </div>
    </TransactionsListLI>
  );
};

const TransactionsListContents = ({
  transactions
}: {
  transactions: Transaction[];
}) => {
  const [displayedTransactions, setDisplayedTransactions] = useState(5);
  const loadMoreTransactions = () => {
    setDisplayedTransactions(displayedTransactions + 10);
  };

  return (
    <>
      <TransactionsListUL>
        {transactions.slice(0, displayedTransactions).map(transaction => {
          return (
            <TransactionListItem
              key={transaction.transactionSetId}
              {...transaction}
            />
          );
        })}
      </TransactionsListUL>
      {displayedTransactions < transactions?.length && (
        <ButtonWrapper>
          <Button onClick={loadMoreTransactions} variant="light">
            <FormattedMessage id="transactionHistory.loadMore.action" />
          </Button>
        </ButtonWrapper>
      )}
    </>
  );
};

const TransactionsListSkeleton = () => {
  return (
    <>
      <TransactionsListUL>
        <TransactionsListLI>
          <Skeleton width="100px" />
          <Skeleton width="100px" />
          <Skeleton width="100px" />
          <Skeleton width="100px" />
        </TransactionsListLI>
      </TransactionsListUL>
      <ButtonWrapper>
        <Skeleton width="10em" />
      </ButtonWrapper>
    </>
  );
};

const Activity: React.FC<ActivityProps> = ({
  downloadURL,
  onDownloadLinkClick,
  transactions
}: ActivityProps): React.ReactElement => {
  const { Track } = useTracking({
    component: 'Activity Component'
  });
  return (
    <Track>
      <Card>
        <CardHeader style={{ flexDirection: 'row' }}>
          {transactions ? (
            <>
              <Heading>
                <FormattedMessage id="transactionHistory.heading" />
              </Heading>
              <DownloadLink href={downloadURL} onClick={onDownloadLinkClick}>
                <span>
                  <FormattedMessage id="transactionHistory.downloadCSV.action" />
                </span>
                <img alt="Download CSV" src={DownloadIcon} />
              </DownloadLink>
            </>
          ) : (
            <>
              <div style={{ width: '33%' }}>
                <Skeleton />
              </div>
              <div style={{ width: '33%' }}>
                <Skeleton />
              </div>
            </>
          )}
        </CardHeader>
        <CardContent>
          {transactions ? (
            <TransactionsListContents transactions={transactions} />
          ) : (
            <TransactionsListSkeleton />
          )}
        </CardContent>
      </Card>
    </Track>
  );
};

export default Activity;
