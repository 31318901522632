import { default as Analytics, AnalyticsInstance } from 'analytics';
import fullStoryPlugin from '@analytics/fullstory';
import mixpanelPlugin from '@analytics/mixpanel';

const IS_DEV = process.env.NODE_ENV === 'development';

const analytics: AnalyticsInstance = Analytics({
  app: 'bazaar',
  debug: IS_DEV,
  plugins: [
    ...(window.env.REACT_APP_FULLSTORY_TOKEN
      ? [
          fullStoryPlugin({
            org: window.env.REACT_APP_FULLSTORY_TOKEN
          })
        ]
      : []),
    mixpanelPlugin({
      token: window.env.REACT_APP_MIXPANEL_TOKEN
    })
  ]
});

export default analytics;
