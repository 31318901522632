import React, {
  ChangeEventHandler,
  FocusEventHandler,
  InputHTMLAttributes,
  MouseEventHandler,
  Ref
} from 'react';
import styled from 'styled-components';

import {
  ActiveModifiers,
  DayPicker,
  SelectSingleEventHandler
} from 'react-day-picker';
import { format, isValid, parse } from 'date-fns';
import { usePopper } from 'react-popper';

import { Placement } from '@popperjs/core';

interface DatePickerProps extends InputHTMLAttributes<HTMLInputElement> {
  popPosition?: Placement | undefined;
}

const Field = styled.div`
  margin-top: 25px;
`;

const PopupAnchor = styled.div`
  width: 100%;
`;

const Popup = styled.div`
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0px 1px 10px rgb(0 0 0 / 4%), 0px 4px 5px rgb(0 0 0 / 6%),
    0px 2px 4px -1px rgb(0 0 0 / 9%);
  height: max-content;
  z-index: 1;
`;

const StyledInput = styled.input`
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
  color: #4a4a4a;
  display: block;
  font-size: 18px;
  height: 40px;
  padding: 8px;
  width: 100%;
`;

const DatePicker = React.forwardRef(
  (
    { popPosition }: DatePickerProps,
    forwardRef: Ref<HTMLInputElement>
  ): React.ReactElement => {
    // establish state for date chosen by user, the input element value, and the visible state of the pop-up
    const [selectedDate, setSelectedDate] = React.useState<Date>();
    const [inputValue, setInputValue] = React.useState<string>('');
    const [popperOpen, setPopperOpen] = React.useState(false);

    /**
     * per react-popper docs, registering the div element is state is a proper way to connect
     * the element with the popperjs functionality
     */
    const [
      popperElement,
      setPopperElement
    ] = React.useState<HTMLDivElement | null>(null);

    // fieldRef used to detect when a user clicks inside or outside the component
    const fieldRef = React.useRef<HTMLDivElement>(null);

    // achorRef used for establihsing where pop-up will render
    const anchorRef = React.useRef<HTMLDivElement>(null);

    // initialize PopperJS hook for DayPicker element pop-up
    const popper = usePopper(anchorRef.current, popperElement, {
      placement: popPosition,
      modifiers: [{ name: 'offset', options: { offset: [0, 20] } }]
    });

    // inputBlurHandler responds to the loss of focus on the central input element
    const inputBlurHandler: FocusEventHandler<HTMLInputElement> = event => {
      // attempt to convert current field value to a Date
      const inputDate = new Date(event.target.value);

      if (isValid(inputDate)) {
        // if field represents a valid date, update the formatting
        setInputValue(format(inputDate, 'MM/dd/yyyy'));
        setSelectedDate(inputDate);
      }
    };

    // inputCLickHandler responds to the user clicking the central input field
    const inputClickHandler: MouseEventHandler<HTMLInputElement> = () => {
      // open or close the date picker pop-up element
      setPopperOpen(!popperOpen);
    };

    // inputChangeHandler reponds to any change in the central input value
    const inputChangeHandler: ChangeEventHandler<HTMLInputElement> = event => {
      // update store value with each change
      setInputValue(event.currentTarget.value);

      // attempt to convert string value to valid Date
      const date = parse(event.currentTarget.value, 'MM/dd/yyyy', new Date());

      if (isValid(date)) {
        // if Date is valid, update the selected date
        setSelectedDate(date);
      } else {
        // if date is invalid, clear out selected date
        setSelectedDate(undefined);
      }
    };

    const daySelectHandler: SelectSingleEventHandler = (
      day: Date | undefined,
      selectedDay: Date,
      activeModifiers: ActiveModifiers,
      event: React.MouseEvent
    ) => {
      // store new date selection
      setSelectedDate(selectedDay);

      if (selectedDay) {
        // if date is valid update text field to reflect selection; close pop-up
        setInputValue(format(selectedDay, 'MM/dd/yyyy'));
        setPopperOpen(false);
      } else {
        // if date is invalid then clear text field
        setInputValue('');
      }
    };

    // these actions taken on-mount
    React.useEffect(() => {
      // listen for escape key and close the pop-up menu if caught
      const handleEscape = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          setPopperOpen(false);
        }
      };

      // watch for mouse clicks outside of element and close pop-up menu if open
      const handleMouse = (event: MouseEvent) => {
        if (fieldRef?.current?.contains(event.target as Node) === false) {
          setPopperOpen(false);
        }
      };

      // add event listeners
      window.addEventListener('keydown', handleEscape);
      window.addEventListener('mousedown', handleMouse);

      return () => {
        // tear down event listeners
        window.removeEventListener('keydown', handleEscape);
        window.removeEventListener('mousedown', handleMouse);
      };
    }, []);

    return (
      <Field ref={fieldRef}>
        <PopupAnchor ref={anchorRef}>
          <StyledInput
            onBlur={inputBlurHandler}
            onClick={inputClickHandler}
            onChange={inputChangeHandler}
            ref={forwardRef}
            placeholder="Select a Date"
            value={inputValue}
          />
        </PopupAnchor>
        {popperOpen && (
          <Popup
            id="popper"
            style={popper.styles.popper}
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
          >
            <DayPicker
              defaultMonth={selectedDate}
              initialFocus={popperOpen}
              mode="single"
              selected={selectedDate}
              onSelect={daySelectHandler}
            />
          </Popup>
        )}
      </Field>
    );
  }
);

DatePicker.displayName = 'DatePicker';
DatePicker.defaultProps = {
  popPosition: 'right'
};

export default DatePicker;
