import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 900;
`;

interface Props {
  onClose?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  style: Record<string, unknown>;
}

const OverlayBackground = ({ onClose, style }: Props) => (
  <StyledDiv onClick={onClose} style={style} />
);

export default OverlayBackground;
