import React from 'react';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';

import OverlayBackground from './OverlayBackground';
import { TransitionStatus } from 'react-transition-group/Transition';

interface DrawerProps {
  children?: React.ReactNode;
  onClose?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  open: boolean;
  position: 'left' | 'right';
}

const Wrapper = styled.div<Partial<DrawerProps>>`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  ${({ position }) => {
    switch (position) {
      case 'left':
        return 'left: -272px;';
      case 'right':
        return 'right: 0;';
    }
  }}
  height: 100%;
  overflow: auto;
  width: 272px;
  z-index: 1000;
  background: var(--drawerBackground, #f5f8fc);
`;

const baseOverlayStyles = {
  transition: 'opacity 300ms ease-in',
  opacity: 0,
  visibility: 'hidden'
};
const baseDrawerStyles = {
  transition: 'transform 300ms ease-in-out',
  transform: 'translateX(100%)'
};
type TransitionStylesObject = {
  [index in TransitionStatus]: Record<string, unknown>;
};

const overlayStyles: TransitionStylesObject = {
  entering: { opacity: 1, visibility: 'visible' },
  entered: { opacity: 1, visibility: 'visible' },
  exiting: { opacity: 0, visibility: 'visible' },
  exited: { opacity: 0 },
  unmounted: {}
};
const rightDrawerStyles: TransitionStylesObject = {
  entering: { transform: 'translateX(0)' },
  entered: {
    transform: 'translateX(0)',
    boxShadow: '-8px 8px 21px -2px rgba(0, 0, 0, .07)'
  },
  exiting: { transform: 'translateX(100%)' },
  exited: { transform: 'translateX(100%)' },
  unmounted: {}
};
const leftDrawerStyles: TransitionStylesObject = {
  entering: { transform: 'translateX(100%)' },
  entered: {
    transform: 'translateX(100%)',
    boxShadow: '8px 8px 21px -2px rgba(0, 0, 0, .07)'
  },
  exiting: { transform: 'translateX(-100%)' },
  exited: { transform: 'translateX(-100%)' },
  unmounted: {}
};

const Drawer: React.FC<DrawerProps> = ({
  children,
  onClose,
  open,
  position = 'right',
  ...props
}: DrawerProps): React.ReactElement => {
  return (
    <Transition in={open} timeout={300}>
      {state => (
        <>
          <OverlayBackground
            onClose={onClose}
            style={{ ...baseOverlayStyles, ...overlayStyles[state] }}
          />
          <Wrapper
            position={position}
            style={{
              ...baseDrawerStyles,
              ...(position === 'right'
                ? rightDrawerStyles[state]
                : leftDrawerStyles[state])
            }}
            {...props}
          >
            {children}
          </Wrapper>
        </>
      )}
    </Transition>
  );
};

export default Drawer;
