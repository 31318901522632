import React from 'react';
import { string } from 'prop-types';
import { ReactComponent as Icon } from './svg/Exclamation.svg';

const Exclamation: React.FC = props => <Icon {...props} />;

Exclamation.propTypes = {
  fill: string,
  width: string,
  height: string
};

Exclamation.defaultProps = {
  fill: '#d0021b',
  width: '18px',
  height: '18px'
};

export default Exclamation;
