import React from 'react';
import { datadogRum } from '@datadog/browser-rum';

interface User {
  userId: number;
  name: string;
  email: string;
}

export const DatadogSetUser = (user: User) => {
  datadogRum.setUser({
    id: user?.userId,
    name: user?.name,
    email: user?.email
  });
};
