import moment, { MomentInput } from 'moment';
import { RecentDocumentType } from 'lib/constants';

export const filterByDate = (rows: DocumentRow[]): DocumentFilter<string> => {
  // Get a list of all years containing statements
  const yearsWithStatements = rows
    .map(row => row[2])
    .reduce((years, currentDate) => {
      const year = moment(currentDate as MomentInput)
        .year()
        .toString();
      if (!years.includes(year)) {
        years.push(year);
      }
      return years;
    }, [] as string[]);

  return {
    value: 'ALL',
    options: [
      { value: 'ALL', label: 'All' },
      { value: 'LAST_90_DAYS', label: 'Last 90 days' },
      { value: 'LAST_6_MONTHS', label: 'Last 6 months' },
      // Add options for each applicable year of statements
      ...yearsWithStatements.map(year => {
        return { value: year, label: year };
      })
    ],
    func: (documents: DocumentRow[], filter: string) => {
      // Year option (e.g. "2021") is selected
      if (yearsWithStatements.includes(filter)) {
        return documents.filter(([_, __, createdOn]) => {
          return (
            moment(createdOn as MomentInput)
              .year()
              .toString() === filter
          );
        });
      }
      switch (filter) {
        case 'LAST_90_DAYS':
          return documents.filter(([_, __, createdOn]) => {
            return (
              moment()
                .startOf('day')
                .diff(moment(createdOn as MomentInput), 'days') < 90
            );
          });
        case 'LAST_6_MONTHS':
          return documents.filter(([_, __, createdOn]) => {
            return (
              moment()
                .startOf('day')
                .diff(moment(createdOn as MomentInput), 'months') < 6
            );
          });
        default:
          return documents;
      }
    }
  };
};

export const filterByType: DocumentFilter<'SIGNED_CONTRACT' | 'Type'> = {
  value: 'Type',
  options: [
    { value: 'Type', label: 'Type' },
    { value: 'SIGNED_CONTRACT', label: 'Loan Agreements' }
  ],
  func: (documents: DocumentRow[], filter: 'SIGNED_CONTRACT' | 'Type') => {
    switch (filter) {
      case 'SIGNED_CONTRACT':
        return documents.filter(([_, docType]) => {
          return docType === RecentDocumentType['SIGNED_CONTRACT'];
        });
      case 'Type':
        return documents;
    }
  }
};

export const filterByLoanId = (rows: DocumentRow[]): DocumentFilter<string> => {
  const loanIdsSet = new Set();
  rows.map((row: any) => {
    const id = row[0].props.children[1].props.children;
    id && loanIdsSet.add(id);
  });
  const loanIds = Array.from(loanIdsSet) as string[];
  return {
    value: 'Loan Number',
    options: [
      { value: 'Loan Number', label: 'Loan Number' },
      ...loanIds.map(id => {
        return { value: id, label: id };
      })
    ],
    func: (documents: DocumentRow[], filter: string) => {
      if (loanIds.includes(filter)) {
        return documents.filter((element: any) => {
          const id = element[0].props.children[1].props.children;
          return id === filter;
        });
      }
      return documents;
    }
  };
};
