import React from 'react';
import { string } from 'prop-types';
import { ReactComponent as Icon } from './svg/Download.svg';

const Download: React.FC = props => <Icon {...props} />;

Download.propTypes = {
  fill: string,
  width: string,
  height: string
};

Download.defaultProps = {
  fill: '#b4b6c6',
  width: '18px',
  height: '18px'
};

export default Download;
