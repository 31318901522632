import styled from 'styled-components';
import { mediaQuery as mQ } from '../../lib';

export const CardHeader = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: var(--fontWeightBold, 700);
  padding: 2em 1.5em 0;
  ${mQ.md`
    align-items: center;
    flex-direction: row;
  `}
`;

export const CardContent = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  padding: var(--card-content-padding, 24px);
`;

export const Card = styled.div`
  background: var(--cardBackground, #fff);
  border-radius: 10px;
  box-shadow: 8px 8px 17px -2px rgba(0, 0, 0, 0.05);
  display: flex;
  font-size: var(--fontSize4, 1.6rem);
  flex-direction: column;
  height: 100%;
`;

export default Card;
