import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef
} from 'react';
import styled from 'styled-components';
import { useFetch, CachePolicies } from 'use-http';
import { Activity, Tabs, Alert } from '@bazaar/components';
import { useTracking } from 'react-tracking';
import { usePartiesAPI } from '../../../contexts/PartiesAPI';
import DocumentsSection from './DocumentsSection';
import NotificationSection from './NotificationSection';
import TermLoanView from './TermLoan/TermLoanView';
import LOCView from './LOC/LOCView';
import { get, handleTransactionDownloadJWT } from 'lib';
import { useLocation } from 'react-router-dom';
import { OptimizelyFeature } from '@optimizely/react-sdk';
import ODORenewalNotification from './ODORenewalNotification';
import { useAuth } from '../../../contexts/Auth';

const GridContainer = styled.div`
  display: grid;
  grid-row-gap: 2em;
`;

interface AlertProps {
  color?: string;
  title?: React.ReactNode;
  children: React.ReactNode;
}

const Dashboard = () => {
  const { partiesData } = usePartiesAPI();
  const { addCredentials, loginServerEnabled } = useAuth();
  const { trackEvent } = useTracking();
  const [termLoanDetailsLoaded, setTermLoanDetailsLoaded] = useState(false);
  const [termPaymentsLoaded, setTermPaymentsLoaded] = useState(false);
  const [locDetailsLoaded, setLOCDetailsLoaded] = useState(false);
  const [locPaymentsLoaded, setLOCPaymentsLoaded] = useState(false);
  const [documentsLoaded, setDocumentsLoaded] = useState(false);
  const [transactionsLoaded, setTransactionsLoaded] = useState(false);
  const initialRenderTime = useMemo(() => Date.now(), []);
  const [termLoanDetails, setTermLoanDetails] = useState<
    TermLoan | undefined
  >();
  const [locDetails, setLOCDetails] = useState<LOC | undefined>();
  const [termLoanHref, setTermLoanHref] = useState<string>();
  const [locHref, setLOCHref] = useState<string>();
  const location = useLocation();
  const componentMounted = useRef(true);
  const [alert, setAlert] = useState<AlertProps>();

  useEffect(() => {
    if (
      (termLoanDetailsLoaded || locDetailsLoaded) &&
      (termPaymentsLoaded || locPaymentsLoaded) &&
      documentsLoaded &&
      transactionsLoaded
    ) {
      const readyTime = (Date.now() - initialRenderTime) / 1000;
      trackEvent({ event: 'Page Loaded', readyTime });
    }
  });
  const fetchLoanDetails = useCallback(
    (href: string) => get(href, loginServerEnabled, addCredentials),
    [addCredentials, loginServerEnabled]
  );

  const setLoanData = (href: string, result: TermLoan | LOC, type: string) => {
    if (componentMounted.current) {
      if (type === 'FIXED_TERM') {
        setTermLoanHref(href);
        setTermLoanDetails(result as TermLoan);
        setTermLoanDetailsLoaded(true);
      } else {
        setLOCHref(href);
        setLOCDetails(result as LOC);
        setLOCDetailsLoaded(true);
      }
    }
  };

  const getActiveLoan = useCallback(
    async (type: string, loans: any) => {
      const results: (TermLoan | LOC)[] = await Promise.all(
        loans.map(async (loan: any) => fetchLoanDetails(loan.href))
      );
      const activeLoanIdx: number = results.findIndex(
        (loan: TermLoan | LOC) => loan.status === 'ACTIVE'
      );
      if (loans[activeLoanIdx]) {
        setLoanData(loans[activeLoanIdx].href, results[activeLoanIdx], type);
      }
    },
    [fetchLoanDetails]
  );
  useEffect(() => {
    const alertValue = location.state as AlertProps;
    if (alertValue) {
      setAlert(alertValue);
      window.history.replaceState({}, location.state as string);
    }
    const termLoans = partiesData.parties[0].loans.filter(
      (loan: any) => loan.loanType === 'FIXED_TERM'
    );
    const loC = partiesData.parties[0].loans.filter(
      (loan: any) => loan.loanType === 'LINE_OF_CREDIT'
    );
    if (termLoans.length > 0) getActiveLoan('FIXED_TERM', termLoans);
    if (loC.length > 0) getActiveLoan('LINE_OF_CREDIT', loC);
    return () => {
      componentMounted.current = false;
    };
  }, [partiesData.parties[0]]);

  const [transactions, setTransactions] = useState<LoanActivity[]>();
  const { get: getLoanActivity, response: loanActivityResponse } = useFetch(
    `${window.env.REACT_APP_API_URL}/loans/transactions`,
    {
      data: [],
      cachePolicy: CachePolicies.NO_CACHE
    },
    []
  );

  useEffect(() => {
    const fetchLoanActivity = async () => {
      const loanActivityData: LoanActivity[] = await getLoanActivity();
      if (componentMounted.current) {
        if (loanActivityResponse.ok) {
          setTransactionsLoaded(true);
          setTransactions(loanActivityData);
        } else {
          setTransactions([]);
        }
      }
    };
    fetchLoanActivity();
  }, [getLoanActivity, loanActivityResponse]);

  const transactionsCSVHref = `${termLoanHref}/transactions.csv`;
  const sanitizedLegalName = partiesData.parties?.[0].businesses?.[0].legalName.replace(
    /\W/g,
    '_'
  );
  const { get: getTransactionsToken } = useFetch(
    `${window.env.REACT_APP_API_URL}/loans/transactions/token`,
    {
      cachePolicy: CachePolicies.NO_CACHE
    }
  );
  const handleTransactionsDownloadLink = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const startTime = Date.now();
    await handleTransactionDownloadJWT(
      `${sanitizedLegalName}_Transaction_History.csv`,
      getTransactionsToken
    );
    trackEvent({
      component: 'Transaction History',
      event: 'Transaction History Download',
      readyTime: (Date.now() - startTime) / 1000
    });
  };

  return (
    <>
      {alert?.children && (
        <Alert
          id="Dashboard_TopAlert"
          color={alert?.color}
          title={alert?.title}
          hideOnDismiss
        >
          {alert?.children}
        </Alert>
      )}
      <OptimizelyFeature feature="apex-odo-authentication">
        {isEnabled =>
          isEnabled ? <ODORenewalNotification /> : <NotificationSection />
        }
      </OptimizelyFeature>
      <GridContainer>
        {termLoanDetailsLoaded && locDetailsLoaded && (
          <Tabs tabs={['Line of Credit', 'Term Loan']}>
            {[
              <LOCView
                key="loc"
                done={setLOCPaymentsLoaded}
                loanByIdHref={locHref}
                locDetails={locDetails}
              />,
              <TermLoanView
                key="termloan"
                done={setTermPaymentsLoaded}
                loanByIdHref={termLoanHref}
                termLoanDetails={termLoanDetails}
              />
            ]}
          </Tabs>
        )}
        {termLoanDetailsLoaded && !locDetailsLoaded && (
          <Tabs tabs={['Term Loan']}>
            {[
              <TermLoanView
                key="termloan"
                done={setTermPaymentsLoaded}
                loanByIdHref={termLoanHref}
                termLoanDetails={termLoanDetails}
              />
            ]}
          </Tabs>
        )}
        {locDetailsLoaded && !termLoanDetailsLoaded && (
          <Tabs tabs={['Line of Credit']}>
            {[
              <LOCView
                key="loc"
                done={setLOCPaymentsLoaded}
                loanByIdHref={locHref}
                locDetails={locDetails}
              />
            ]}
          </Tabs>
        )}

        <Activity
          downloadURL={transactionsCSVHref}
          onDownloadLinkClick={handleTransactionsDownloadLink}
          transactions={transactions}
        />
        <DocumentsSection done={setDocumentsLoaded} />
      </GridContainer>
    </>
  );
};
export default Dashboard;
