import React, { ReactNode, useCallback, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useTracking } from 'react-tracking';
import BtnArrow from '../../assets/images/Btn_arrow.svg';

const ArrowBtn = styled.img`
  transform: rotate(180deg);
  position: absolute;
  top: 35%;
  right: 8%;
`;

const primary = css`
  background-color: var(--button-primary-bg);
  border: 1px solid var(--button-light-border);
  color: var(--button-primary-content);
  &:hover {
    background-color: var(--button-primary-bg-hover);
  }
`;

const light = css`
  background-color: var(--button-light-bg);
  border: 2px solid var(--button-light-border);
  color: var(--button-light-content);
  &:hover {
    border: 2px solid var(--button-light-border-hover);
  }
  &:disabled {
    border: 2px solid var(--button-light-border);
    color: var(--color-gray);
  }
`;

const link = css`
  background-color: transparent;
  border: none;
  color: var(--color-anchor);
  display: inline-block;
  &:hover,
  &:focus {
    color: var(--color-anchor-hover);
    outline: none;
  }
`;

const square = css<ButtonProps>`
  border-radius: 3px;
  border: 1px solid var(--color-brand);
  background-color: ${props =>
    props.unfilled ? 'var(--button-light-bg)' : 'var(--button-primary-bg)'};
  color: ${props =>
    props.unfilled ? 'var(--color-brand)' : 'var(--color-white)'};
  cursor: pointer;
  &:hover {
    border-color: var(--color-anchor-hover);
  }
`;

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const spinner = css<ButtonProps>`
  background-color: var(--button-primary-bg);
  border: 1px solid var(--button-light-border);
  color: var(--button-primary-content);
  position: relative;
  &:hover {
    background-color: var(--button-primary-bg-hover);
  }
  :disabled {
    background: #dddddd;
    color: #616161;
    border: none;
    cursor: default;
  }
  :after {
    ${p => (p.showSpinner ? `content: ''` : '')};
    position: absolute;
    width: 14px;
    height: 14px;
    right: 8%;
    border: 3px solid transparent;
    border-top-color: #616161;
    border-right-color: #616161;
    border-left-color: #616161;
    border-radius: 50%;
    animation: ${spin} 1s linear infinite;
  }
`;

const styles = {
  light,
  primary,
  link,
  square,
  spinner
};

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'light' | 'link' | 'square' | 'spinner';
  unfilled?: boolean;
  showSpinner?: boolean;
  children: ReactNode;
}

const StyledButton = styled.button<ButtonProps>`
  cursor: pointer;
  border-radius: 30px;
  font-size: var(--fontSize4);
  font-weight: var(--fontWeightBold);
  font-family: inherit;
  height: 47px;
  min-width: 192px;
  padding: 10px 24px;
  &:focus {
    outline: 1px dashed var(--color-blue-outline);
  }
  ${props => props.variant && styles[props.variant]}
`;

const Button = ({
  variant = 'primary',
  unfilled,
  showSpinner,
  onClick,
  children,
  ...props
}: ButtonProps): React.ReactElement => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { trackEvent } = useTracking({ event: 'Button Clicked' });
  const onClickHandler = useCallback(
    evt => {
      trackEvent({
        content: buttonRef?.current?.textContent
      });
      onClick && onClick(evt);
    },
    [onClick, trackEvent, buttonRef]
  );
  return (
    <StyledButton
      variant={variant}
      unfilled={unfilled}
      showSpinner={showSpinner}
      ref={buttonRef}
      onClick={onClickHandler}
      {...props}
    >
      {children}
      {variant === 'spinner' && !showSpinner && (
        <ArrowBtn alt="arrow-btn" src={BtnArrow} />
      )}
    </StyledButton>
  );
};

export default Button;
