import styled from 'styled-components';

interface LabelProps {
  color?: string;
  variant?: 'bubble';
}

const Label = styled.span<LabelProps>`
  ${({ color }) => {
    if (color) {
      return `--label-bg-color: var(--label-${color}-bg);
          --label-color: var(--label-${color});`;
    }
    return `--label-bg-color: var(--label-blue-bg);
          --label-color: var(--label-blue);`;
  }}
  ${({ variant }) => {
    if (variant === 'bubble') {
      return `
      &:after {
        background: inherit;
        content: '';
        height: 10px;
        width: 10px;
        position: absolute;
        bottom: -5px;
        left: 50%;
        margin-left: -5px;
        transform: rotate(45deg);
      }
      `;
    }
  }}
  background-color: var(--label-bg-color);
  color: var(--label-color);
  border-radius: 22px;
  font-size: var(--label-font-size, 1rem);
  font-weight: var(--fontWeightSemiBold);
  padding: 0.5em 0.8em;
  text-align: center;
`;

export default Label;
