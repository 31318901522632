import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  Exclamation,
  CheckmarkFill,
  Input,
  LockShield,
  useAuth0,
  Button
} from '@bazaar/components';
import { passwordRegex } from '../../lib/constants';

const StyledExclamation = styled(Exclamation)`
  width: 35px;
  margin-right: 17px;
  display: block;
`;
const Container = styled.div`
  background-color: #ffffff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.13);
  height: fit-content;
  margin: 20px auto;
  width: 477px;
  & input:disabled {
    font-style: italic;
    opacity: 0.5;
  }
`;
const ContainerBox = styled.div`
  padding: 50px;
  min-height: 624px;
`;
const Heading = styled.h1`
  color: #000;
  font-size: 36px;
  margin-top: 30px;
`;
const CustomInput = styled(Input)`
  font-size: 18px;
  width: 100%;
`;
const ErrorBox = styled.div`
  padding: 27px 16px 23px;
  display: flex;
  border-bottom: solid 1px #ccc;
`;
const ErrorCode = styled.p`
  margin: 0px;
  color: var(--color-red);
`;
const ErrorMsg = styled.p`
  color: var(--color-red);
  margin: 0px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: center;
`;
const LoginBtn = styled(Button)`
  width: 152px;
  height: 40px;
  white-space: nowrap;
  text-align: center;
  padding: 0;
`;
const StyledLabelWrapper = styled.div`
  line-height: 30px;
`;
const SubmittedText = styled.div`
  margin-top: 1em;
  position: relative;
  padding-left: 40px;
  .icon {
    position: absolute;
    left: 0;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 40px;
  position: absolute;
  font-size: var(--fontSize5);
  font-weight: var(--fontWeightBody);
  font-family: 'Galano Grotesque';
  height: 20px;
  min-width: 45px;
  padding: 0px;
  &:focus {
    outline: none;
  }
`;

interface ResetPasswordFromInputs {
  email: string;
  currentPassword: string;
  newPassword: string;
}

const ResetPassword = () => {
  const params = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const intl = useIntl();
  const requiredText = intl.formatMessage({ id: 'common.required' });
  const schema = Yup.object().shape({
    email: Yup.string().email().required(requiredText),
    currentPassword: Yup.string().required(requiredText),
    newPassword: Yup.string()
      .required(requiredText)
      .matches(passwordRegex, "doesn't match requirements")
  });
  const { register, errors, handleSubmit } = useForm<ResetPasswordFromInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      email: params.get('email') || ''
    }
  });
  const [submitted, setSubmitted] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const {
    resetPassword,
    resetPasswordError,
    resetPasswordSuccess
  } = useAuth0();
  const onSubmit = async (data: ResetPasswordFromInputs) => {
    setShowSpinner(true);
    await resetPassword(data.email, data.currentPassword, data.newPassword);
    setShowSpinner(false);
    setSubmitted(true);
  };
  const changeRequestSucceeded = submitted && resetPasswordSuccess;
  return (
    <Container>
      {resetPasswordError && (
        <ErrorBox>
          <StyledExclamation />
          <ErrorCode>
            {resetPasswordError.code === 'MZXOF'
              ? intl.formatMessage({ id: 'resetPassword.error.weak.password' })
              : intl.formatMessage({
                  id: 'resetPassword.error.unsuccessful.login'
                })}
          </ErrorCode>
        </ErrorBox>
      )}
      <ContainerBox>
        <div style={{ display: 'flex' }}>
          <LockShield />
          <p style={{ paddingLeft: '15px' }}>
            Please reset your password to continue.{' '}
          </p>
        </div>
        <Heading>
          {intl.formatMessage({ id: 'forgotPassword.heading' })}
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CustomInput
            id="email"
            name="email"
            type="text"
            disabled={changeRequestSucceeded}
            ref={register}
          >
            <StyledLabelWrapper>
              {intl.formatMessage({ id: 'resetPassword.input.email' })}
            </StyledLabelWrapper>
          </CustomInput>
          {errors.email?.message && <ErrorMsg>{errors.email.message}</ErrorMsg>}
          <CustomInput
            id="currentPassword"
            name="currentPassword"
            type="password"
            disabled={changeRequestSucceeded}
            ref={register}
          >
            <StyledLabelWrapper>
              {intl.formatMessage({
                id: 'resetPassword.input.currentPassword'
              })}
            </StyledLabelWrapper>
          </CustomInput>
          {errors.currentPassword?.message && (
            <ErrorMsg>{errors.currentPassword.message}</ErrorMsg>
          )}
          <CustomInput
            id="newPassword"
            name="newPassword"
            type="password"
            disabled={changeRequestSucceeded}
            ref={register}
          >
            <StyledLabelWrapper>
              {intl.formatMessage({ id: 'resetPassword.input.newPassword' })}
            </StyledLabelWrapper>
            <p
              style={{
                fontSize: '12px',
                color: 'var(--color-muted)',
                fontStyle: 'italic'
              }}
            >
              {intl.formatMessage({ id: 'resetPassword.password.requirement' })}
            </p>
          </CustomInput>
          {errors.newPassword?.message && (
            <ErrorMsg>{errors.newPassword.message}</ErrorMsg>
          )}
          {changeRequestSucceeded ? (
            <SubmittedText data-testid="submittedCopy">
              <CheckmarkFill height="30" width="30" className="icon" />
              <FormattedMessage id="resetPassword.success" />
            </SubmittedText>
          ) : (
            <StyledButton
              type="button"
              variant="link"
              onClick={() => navigate(-1)}
            >
              {intl.formatMessage({ id: 'common.back' })}
            </StyledButton>
          )}
          <ButtonWrapper>
            {changeRequestSucceeded ? (
              <>
                <LoginBtn type="button" onClick={() => navigate(-1)}>
                  {intl.formatMessage({
                    id: 'forgotPassword.buttonAfterSubmitted'
                  })}
                </LoginBtn>
              </>
            ) : (
              <LoginBtn
                type="submit"
                variant="spinner"
                showSpinner={showSpinner}
              >
                {intl.formatMessage({
                  id: 'forgotPassword.button'
                })}
              </LoginBtn>
            )}
          </ButtonWrapper>
        </form>
      </ContainerBox>
    </Container>
  );
};

export default ResetPassword;
