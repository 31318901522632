import React, { ReactElement, ReactNode, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import Drawer from '../Drawer/Drawer';
import Link from '../Link/Link';
import UserAvatar from '../UserAvatar/UserAvatar';
import Logo from '../../assets/Logo';
import CloseIcon from '../../assets/images/hamburger-close.svg';
import DownCarrot from '../../assets/images/carrot-down.svg';
import useMedia from '../../hooks/useMedia';
import { breakpoints, mediaQuery as mQ } from '../../lib';

interface SideNavProps {
  children?: React.ReactNode;
  onClose?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  open: boolean;
  user?: {
    name?: string;
    businessName?: string;
  };
}

const DrawerWrapper = styled(Drawer)`
  --drawerBackground: #fff;
`;

const SideNavWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 272px;
  z-index: 1000;
  background: #fff;
  box-shadow: 8px 8px 21px -2px rgba(0, 0, 0, 0.07);
`;

const LogoContainer = styled.div`
  display: flex;
  padding: 1.6rem;
  > * + * {
    margin-left: 16px;
  }
  ${mQ.lg`
    justify-content: center;
    padding: 2.4rem;
    padding-left: 3.6rem;
  `}
`;

const CarrotWrapper = styled.div<SideNavProps>`
  transform: ${props => (props.open ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s linear;
`;

const ProfileInfoContainer = styled.div`
  display: flex;
  padding: 1.6rem;
  .meta {
    line-height: 1.5;
    margin-left: 1.2rem;
  }
  .customer-name {
    font-size: var(--fontSize4);
    font-weight: var(--fontWeightSemiBold);
  }
  .edit-profile-link {
    margin-top: 0.8em;
  }
`;

const NavLinksContainer = styled.div`
  --color-anchor: #000;
  --color-anchor-hover: #006beb;
  --link-font-weight: var(--fontWeightMedium);
  flex: 1;
`;

export const NavItem = styled.div`
  padding: 18px 0;
  border-top: 1px solid #b4b6c6;
  font-size: var(--fontSize4);
  padding-left: 18px;
  &:last-child {
    border-bottom: 1px solid #b4b6c6;
  }
`;

const SignOutContainer = styled(NavItem)`
  --color-anchor: #000;
  --color-anchor-hover: #006beb;
  --link-font-weight: var(--fontWeightMedium);
  border: none;
`;

const DropdownWrapper = styled.div`
  border-top: 1px solid #b4b6c6;
  &:last-child {
    border-bottom: 1px solid #b4b6c6;
  }
`;

const DropdownItemWrapper = styled.div<SideNavProps>`
  overflow: hidden;
  height: ${props => (props.open ? '100%' : '0')};
  transform: ${props => (props.open ? 'scaleY(1)' : 'scaleY(0)')};
  transform-origin: top center;
  transition: all 0.3s ease-out;
`;

const DropdownTitle = styled.div<{ changeBgColor: boolean }>`
  display: flex;
  font-weight: var(--link-font-weight, var(--fontWeightSemiBold));
  justify-content: space-between;
  font-size: var(--fontSize4);
  padding: 18px;
  cursor: pointer;
  background-color: ${p => (p.changeBgColor ? 'var(--color-gray)' : '#fff')};
`;

const CloseButton = styled.img`
  margin-right: 13px;
`;

export const DropdownItem = styled(NavItem)`
  background-color: var(--color-gray);
  --link-font-weight: var(--fontWeightBody);
  padding-left: 25px;
  &:last-child {
    border-bottom: none;
  }
`;

export const DropdownLink = ({
  children,
  render
}: {
  children: ReactElement;
  render: () => ReactNode;
}) => {
  const [open, setOpen] = useState(false);
  const navLink = children?.props;
  return (
    <DropdownWrapper>
      <DropdownTitle
        changeBgColor={
          navLink && navLink.id === 'navigation.link.contactUs' && open
        }
        onClick={() => setOpen(!open)}
      >
        {children}
        <CarrotWrapper open={open}>
          <img alt="Down Carrot" src={DownCarrot} />
        </CarrotWrapper>
      </DropdownTitle>
      <DropdownItemWrapper open={open}>{render()}</DropdownItemWrapper>
    </DropdownWrapper>
  );
};

const SideNav = ({ children, user, onClose, ...props }: SideNavProps) => {
  const isSmall = useMedia([`(min-width: ${breakpoints.lg})`], [false], true);
  const intl = useIntl();

  const contents = (
    <>
      <LogoContainer>
        {isSmall && (
          <CloseButton alt="Close Button" src={CloseIcon} onClick={onClose} />
        )}
        <Link to="/">
          <Logo
            height={isSmall ? '24px' : '32px'}
            width={isSmall ? '113px' : '151px'}
          />
        </Link>
      </LogoContainer>{' '}
      <ProfileInfoContainer>
        <UserAvatar small name={user?.name} />
        <div className="meta">
          <div className="customer-name">{user?.name}</div>
          <span>{user?.businessName}</span>
          <div className="edit-profile-link">
            <Link to="/profile">
              {intl.formatMessage({
                id: 'navigation.link.editProfile'
              })}
            </Link>
          </div>
        </div>
      </ProfileInfoContainer>
      <NavLinksContainer>{children}</NavLinksContainer>
      <SignOutContainer>
        <Link to="/sign-out">
          {intl.formatMessage({
            id: 'navigation.link.signOut'
          })}
        </Link>
      </SignOutContainer>
    </>
  );
  if (isSmall) {
    return (
      <DrawerWrapper {...props} position="left">
        {contents}
      </DrawerWrapper>
    );
  }
  return <SideNavWrapper>{contents}</SideNavWrapper>;
};

export default SideNav;
