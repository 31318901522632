import styled from 'styled-components';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTransition, animated } from 'react-spring';

interface AlertProps {
  color?: string;
  hideOnDismiss?: boolean;
  hideOnTimer?: number;
  children: ReactNode;
  title?: ReactNode;
}

const Card = styled.div<{ color?: string }>`
  ${({ color }) => {
    if (color) {
      return `--label-bg-color: var(--label-${color}-bg);
          --label-color: var(--label-${color});
          --label-border-color: var(--label-${color}-border)`;
    }
    return `--label-bg-color: var(--label-yellow-bg);
          --label-color: var(--label-yellow);
          --label-border-color: var(--label-yellow-border)`;
  }}
  
    }
  }}
  background-color: var(--label-bg-color);
  color: var(--label-color);
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--label-border-color);
  font-size: 12px;
  font-weight: var(--fontWeightBody);
  padding: 2rem 3.5rem 2rem 1.7rem;
  margin: 1.5rem 0.5rem;
  position: relative;
  line-height: 1.7em;
`;

const CloseIcon = styled.div<{ color?: string }>`
  position: absolute;
  right: 1%;
  top: 8%;
  border-radius: 50%;
  background: var(--label-${p => p.color});
  cursor: pointer;
  width: 17px;
  height: 17px;
  :before,
  :after {
    position: absolute;
    right: 44%;
    top: 20%;
    content: ' ';
    height: 10px;
    width: 2px;
    background-color: #fff;
  }
  :before {
    transform: rotate(45deg);
  }
  :after {
    transform: rotate(-45deg);
  }
`;

const Title = styled.div`
  font-weight: var(--fontWeightBold);
`;

const Alert = ({
  color,
  hideOnDismiss,
  hideOnTimer,
  children,
  title
}: AlertProps) => {
  const [hidden, setHidden] = useState(false);
  const transitions = useTransition(hidden, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });

  useEffect(() => {
    if (hideOnTimer) {
      setTimeout(() => {
        setHidden(true);
      }, hideOnTimer);
    }
  }, [hideOnTimer]);
  return (
    <div>
      {transitions.map(({ item, props, key }) => (
        <animated.div key={key} style={props}>
          {!item && (
            <Card color={color}>
              {hideOnDismiss && (
                <CloseIcon
                  color={color}
                  onClick={() => setHidden(hideOnDismiss)}
                  aria-label="close-icon"
                />
              )}
              {title ? (
                <Title>
                  {title}
                  <br />
                </Title>
              ) : (
                <></>
              )}
              {children}
            </Card>
          )}
        </animated.div>
      ))}
    </div>
  );
};
export default Alert;
