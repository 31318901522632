import React from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import { Button, GenericMessage } from '@ondeck/silkworm';
import { FormattedMessage, useIntl } from 'react-intl';
import ErrorImage from '../../assets/images/error.svg';
import Link from '../Link/Link';

const PageWrapper = styled.div`
  padding: 30px;
  width: 100%;
`;

const PhoneLink = styled(Link)`
  color: var(--fontColorsBrand);
  text-decoration: none;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
`;

const EmailLink = styled(Link)`
  color: var(--fontColorsBrand);
  text-decoration: none;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
`;

interface ErrorPageProps {
  showReturnHomeButton?: boolean;
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  showReturnHomeButton = false
}: ErrorPageProps): React.ReactElement => {
  const intl = useIntl();
  const phoneNumber = intl.formatMessage({ id: 'common.contactNumber' });
  const emailAddress = intl.formatMessage({ id: 'errorPage.emailAddress' });
  return (
    <PageWrapper>
      <GenericMessage
        image={ErrorImage}
        title={intl.formatMessage({ id: 'errorPage.title' })}
      >
        <p>
          <FormattedMessage id="errorPage.content.paragraph.1" />
          <EmailLink href={`mailto:${emailAddress}`} target="_self">
            <FormattedMessage id="errorPage.content.paragraph.2" />
          </EmailLink>
          <FormattedMessage id="errorPage.content.paragraph.3" />
          <PhoneLink href={`tel:${phoneNumber}`} target="_self">
            <NumberFormat
              displayType="text"
              value={phoneNumber}
              format={intl.formatMessage({ id: 'common.numberFormat' })}
            />
          </PhoneLink>
          <FormattedMessage id="errorPage.content.paragraph.4" />
        </p>
        {showReturnHomeButton && (
          <ReturnHomeButton
            text={intl.formatMessage({ id: 'errorPage.button.returnHome' })}
          />
        )}
      </GenericMessage>
    </PageWrapper>
  );
};

type ButtonProps = { text?: string; href?: string };

export const ReturnHomeButton: React.FC<ButtonProps> = ({
  text
}: ButtonProps) => {
  return <Button onClick={() => (window.location.href = '/')}>{text}</Button>;
};

export default ErrorPage;
