import React from 'react';

export const handleTransactionDownloadJWT = async (
  business: string,
  getTransactionsToken: (route?: string | undefined) => Promise<any>
) => {
  const jwt = await getTransactionsToken();
  const downloadHref = `${window.env.REACT_APP_API_URL}/loans/transactions/csv/jwt/${jwt}`;
  const link = window.document.createElement('a');
  link.href = downloadHref;
  link.download = business;
  window.document.body.appendChild(link);
  link.click();
  link.remove();
};

export const handleDocumentDownloadJWT = (
  document: RecentDocument,
  getDocumentToken: (route?: string | undefined) => Promise<any>
) => {
  return async (clickEvent?: React.MouseEvent) => {
    if (clickEvent) {
      clickEvent.preventDefault();
    }
    const jwt = await getDocumentToken(`${document.documentId}`);
    const downloadHref = `${window.env.REACT_APP_API_URL}/documents/jwt/${jwt}`;
    const link = window.document.createElement('a');
    link.href = downloadHref;
    link.download = document.fileName;
    window.document.body.appendChild(link);
    link.click();
    link.remove();
  };
};

export const get = async (
  href: string,
  loginServerEnabled: boolean,
  addCredentials: Function
) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  };
  await addCredentials(options);
  const response = await fetch(href, options);
  if (
    loginServerEnabled &&
    (response.status === 401 || response.status === 403)
  ) {
    window.location.href = window.env.REACT_APP_LOGIN_PAGE_URL;
  }
  return response.json();
};
