import React from 'react';
import { Link } from '@bazaar/components';

export function FormatForPhoneNumber(txt: string) {
  return (
    <Link style={{ fontWeight: 'bold', color: 'inherit' }} href={`tel:${txt}`}>
      {txt}
    </Link>
  );
}
