import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { useIntl, FormattedMessage } from 'react-intl';

import Link from '../Link/Link';

import customerServiceIcon from '../../assets/images/CustomerServiceAgentAvatar.png';

const ContactOnDeckWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  letter-spacing: 0;
  margin: var(--contactOndeckWrapperMargin, 20px);
`;

const CustomerServiceImage = styled.img`
  height: 107px;
`;

const ContactBody = styled.div`
  line-height: 1.5;
  margin-top: 1em;
  text-align: center;
`;

const ContactInfoHeader = styled.div`
  font-weight: var(--fontWeightBold);
  font-size: var(--fontSize4);
  text-align: center;
`;

const ContactInfoLink = styled(Link)`
  display: inline-block;
`;

const ContactOnDeck = (): React.ReactElement => {
  const intl = useIntl();
  const email = 'customerservice@ondeck.com';

  const { Track } = useTracking({ component: 'Contact OnDeck' });
  return (
    <Track>
      <ContactOnDeckWrapper>
        <CustomerServiceImage
          src={customerServiceIcon}
          alt="customer service representative"
        />
        <ContactBody>
          <ContactInfoHeader>
            <FormattedMessage id="contactOndeck.hereToHelp.message" />
          </ContactInfoHeader>
          <div>
            <FormattedMessage id="contactOndeck.contactCS.message" />
          </div>
          <div>
            <FormattedMessage id="contactOndeck.hoursAvailable.message" />
          </div>
        </ContactBody>
        <ContactBody>
          <ContactInfoLink
            href={intl.formatMessage({
              id: 'contactInfo.phone.helpline.link'
            })}
            target="_self"
          >
            <FormattedMessage id="contactInfo.phone.helpline" />
          </ContactInfoLink>
          <br />
          <ContactInfoLink href={`mailto:${email}`} target="_self">
            {email}
          </ContactInfoLink>
        </ContactBody>
      </ContactOnDeckWrapper>
    </Track>
  );
};

export default ContactOnDeck;
