import React from 'react';
import styled from 'styled-components';
import { FormattedDate, FormattedMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';

import Button from '../Button/Button';
import Card, { CardHeader, CardContent } from '../Card/Card';
import Media from '../Media/Media';
import Label from '../Label/Label';
import Link from '../Link/Link';

import DocumentsIcon from '../../assets/images/Icon_Document.svg';
import { useTracking } from 'react-tracking';

const Heading = styled.h4`
  margin: 0;
`;

const StyledLabel = styled(Label)`
  text-transform: uppercase;
`;

const DocumentsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  & li {
    border-bottom: 1px solid var(--color-separator);
    padding: 1em 0;
  }
  & li:first-child {
    border-top: 1px solid var(--color-separator);
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 3em;
`;

const ListItemContent = styled(Media)`
  display: flex;
  align-items: center;
  --media-body-color: var(--color-muted);
  --media-body-size: 1.4rem;
  --media-heading-color: var(--color-body);
  --media-heading-size: 1.4rem;
  --media-heading-weight: 600;
`;

declare interface IRecentDocument {
  createdOn: string;
  documentId: number;
  documentType: 'MONTHLY_STATEMENT' | 'SIGNED_CONTRACT';
  fileName: string;
  href: string;
  onDownloadLinkClick?: () => Promise<void>;
}

const DocumentListItem: React.FC<IRecentDocument> = ({
  fileName,
  createdOn,
  href,
  onDownloadLinkClick
}: IRecentDocument): React.ReactElement => {
  const date = new Date(createdOn);
  return (
    <li>
      <ListItemContent>
        <Media image src={DocumentsIcon} />
        <Media
          body
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Link href={href} onClick={onDownloadLinkClick}>
            <Media heading>{fileName}</Media>
          </Link>
          <FormattedDate
            value={date}
            month="2-digit"
            day="2-digit"
            year="numeric"
          />
        </Media>
      </ListItemContent>
    </li>
  );
};

const DocumentListItemSkeleton = () => {
  return (
    <li>
      <ListItemContent style={{ display: 'flex', flexDirection: 'row' }}>
        <Skeleton width="48px" height="60px" style={{ marginRight: '20px' }} />
        <Media body style={{ flexGrow: 1 }}>
          <Media heading>
            <Skeleton />
          </Media>
          <Skeleton width="50%" />
        </Media>
      </ListItemContent>
    </li>
  );
};

const RecentDocuments = ({
  documents,
  recentlyUpdated,
  viewAllPath
}: {
  documents?: IRecentDocument[];
  recentlyUpdated?: boolean;
  viewAllPath: string;
}): React.ReactElement => {
  const { Track } = useTracking({ component: 'Recent Documents' });
  const navigate = useNavigate();
  return (
    <Track>
      <Card>
        <CardHeader>
          {documents ? (
            <>
              <Heading>Documents</Heading>
              {recentlyUpdated && (
                <StyledLabel color="green">
                  <FormattedMessage id="common.new" />
                </StyledLabel>
              )}
            </>
          ) : (
            <div style={{ width: '50%' }}>
              <Skeleton />
            </div>
          )}
        </CardHeader>
        <CardContent>
          <DocumentsList>
            {(documents &&
              documents.map((doc, idx) => (
                <DocumentListItem {...doc} key={idx} />
              ))) || <DocumentListItemSkeleton />}
          </DocumentsList>
          {documents && (
            <ButtonWrapper>
              <Button
                onClick={() => {
                  navigate(viewAllPath);
                }}
                variant="light"
              >
                View all
              </Button>
            </ButtonWrapper>
          )}
        </CardContent>
      </Card>
    </Track>
  );
};

export default RecentDocuments;
