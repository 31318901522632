import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import useFetch from 'use-http';
import {
  mediaQuery as mQ,
  FormattedMoney,
  Button,
  Card,
  CardHeader,
  SliderInput,
  Tooltip
} from '@bazaar/components';
import Skeleton from 'react-loading-skeleton';

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  ${mQ.md`
     flex-direction: row;
  `}
`;

const Outline = styled.div<{ revealPricing: boolean }>`
  padding: 3rem 2rem;
  border: 1px solid #c6c2c2;
  border-radius: ${p => (p.revealPricing ? '10px 10px 0px 0px' : '10px')};
  flex: 1;
  ${p =>
    p.revealPricing &&
    mQ.md`
      border-radius: 10px 0px 0px 10px
  `}
  ${mQ.md`
      max-width: 55%;
  `};
`;

const Divider = styled.div`
  height: 1px;
  border-top: 1px solid #e7e7f4;
  margin: 2.5rem 0.5rem;
`;

const PurpleOutline = styled.div`
  padding: 3rem 1.5rem;
  border: 2px solid #000082;
  border-radius: 0px 0px 10px 10px;
  flex: 1;
  ${mQ.md`
     border-radius: 0px 10px 10px 0px;
     max-width: 45%
  `};
`;

const PricingInfo = styled.div`
  text-align: center;
  padding: 1rem;
  > span:first-child {
    font-weight: var(--fontWeightSemiBold);
    font-size: var(--fontSize5);
  }
  > span:last-child {
    display: block;
    font-weight: var(--fontWeightBody);
    font-size: var(--fontSize2);
    min-height: 25px;
  }
`;
const PricingInfoContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
  font-size: var(--fontSize5);
  > span:last-child {
    font-weight: var(--fontWeightSemiBold);
    align-items: end;
    display: flex;
    margin-left: 2rem;
  }
`;

interface ReviewPricingProps {
  drawAmount: number;
  error?: string;
  fundingMethod?: string;
  locDetails?: ILOC;
  drawDetails?: IDraw;
  updateDrawAmount: (val: number) => void;
  updateFundingMethod: (val: string) => void;
  updateDrawDetails: (val: IDraw) => void;
  createAlert: (color: string, val: string) => void;
}

const ReviewPricing = ({
  createAlert,
  drawAmount,
  error,
  fundingMethod,
  locDetails,
  drawDetails,
  updateDrawAmount,
  updateFundingMethod,
  updateDrawDetails
}: ReviewPricingProps) => {
  const [drawOverLimit, setDrawOverLimit] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [revealPricing, setRevealPricing] = useState(false);
  const onChangeHandler = (e: any) => {
    updateDrawAmount(e);
  };
  useEffect(() => {
    if (fundingMethod === 'INSTANT_FUNDING' && drawAmount > 10000) {
      setDrawOverLimit(true);
      updateFundingMethod('ACH');
    }
    if (drawAmount <= 10000) setDrawOverLimit(false);
  }, [fundingMethod, drawAmount]);
  useEffect(() => {
    if (locDetails?.drawOnHold) {
      createAlert('red', 'draw on hold');
    }
  }, [locDetails]);

  const drawPreviewHref = `${window.env.REACT_APP_API_URL}/locs/${locDetails?.id}/draw/preview`;
  const { post: drawPreview, response: drawPreviewRes } = useFetch(
    drawPreviewHref
  );
  const onCalculate = async () => {
    setSpinner(true);
    setRevealPricing(true);
    let drawPreviewReq = `{
      "apr": ${locDetails?.annualPercentageRate},
      "drawAmount": ${drawAmount},
      "outstandingPrincipal": ${locDetails?.outstandingPrincipal}
    }`;
    const newDrawDetails = await drawPreview(JSON.parse(drawPreviewReq));
    if (drawPreviewRes.ok) {
      updateDrawDetails(newDrawDetails);
    } else {
      setRevealPricing(false);
      createAlert('red', newDrawDetails?.rootException);
    }
    setSpinner(false);
  };
  return (
    <>
      {locDetails && (
        <Card>
          <CardHeader>
            <FormattedMessage id="drawFunds.selectDrawAmount" />
          </CardHeader>
          <FlexWrapper>
            <Outline revealPricing={revealPricing}>
              <FormattedMessage id="drawFunds.chooseAmount" />
              <div style={{ marginTop: '3rem' }}>
                <SliderInput
                  min={1000}
                  max={Math.trunc(locDetails?.availableCredit)}
                  value={drawAmount}
                  errorMessage={error}
                  onChange={onChangeHandler}
                />
              </div>
              <div style={{ fontSize: '12px', marginTop: '2rem' }}>
                {drawOverLimit && (
                  <FormattedMessage id="drawFunds.instantFunding.drawOverLimit" />
                )}
              </div>
              <Divider />
              <Button
                variant="spinner"
                onClick={onCalculate}
                showSpinner={spinner}
                disabled={
                  error || spinner || !drawAmount || locDetails?.drawOnHold
                }
                style={{ width: '100%' }}
              >
                {revealPricing && !drawDetails && !spinner
                  ? 'Recalculate'
                  : 'Calculate'}
              </Button>
            </Outline>
            {revealPricing && (
              <PurpleOutline>
                <FormattedMessage id="drawFunds.reviewPricing" />
                <div style={{ background: '#F3F6FA', marginTop: '2rem' }}>
                  <PricingInfo>
                    <span>
                      <FormattedMessage id="drawFunds.drawAmount" />
                    </span>
                    <span>
                      {drawDetails?.amount ? (
                        <FormattedMoney
                          value={drawDetails?.amount}
                          maximumFractionDigits={2}
                        />
                      ) : spinner ? (
                        <Skeleton width="9rem" />
                      ) : (
                        ''
                      )}
                    </span>
                  </PricingInfo>
                  <PricingInfo>
                    <span>
                      <FormattedMessage
                        id={
                          locDetails?.paymentFrequency.toUpperCase() ===
                          'MONTHLY'
                            ? 'drawFunds.monthlyAutoPayment'
                            : 'drawFunds.weeklyAutoPayment'
                        }
                      />
                    </span>
                    <span>
                      {drawDetails?.periodicPayment ? (
                        <FormattedMoney
                          value={drawDetails?.periodicPayment}
                          maximumFractionDigits={2}
                        />
                      ) : spinner ? (
                        <Skeleton width="8rem" />
                      ) : (
                        ''
                      )}
                    </span>
                  </PricingInfo>
                </div>
                <div style={{ marginTop: '2rem' }}>
                  <PricingInfoContent>
                    <FormattedMessage id="drawFunds.repaymentPeriod" />
                    {drawDetails?.numberOfPayments ? (
                      <span>
                        {drawDetails?.numberOfPayments}
                        {locDetails?.paymentFrequency.toUpperCase() ===
                        'MONTHLY'
                          ? ' months'
                          : ' weeks'}
                      </span>
                    ) : spinner ? (
                      <Skeleton width="8rem" />
                    ) : (
                      ''
                    )}
                  </PricingInfoContent>
                  <PricingInfoContent>
                    <span>
                      <FormattedMessage id="drawFunds.outstandingBalance" />
                      <span style={{ display: 'inline-block' }}>
                        <Tooltip tooltipWidth="257px" style={{ top: '3px' }}>
                          <FormattedMessage id="drawFunds.outstandingBalance.tooltip.msg" />
                        </Tooltip>
                      </span>
                    </span>
                    <span>
                      {drawDetails?.numberOfPayments ? (
                        <FormattedMoney
                          value={drawDetails.outstandingPrincipal}
                          maximumFractionDigits={2}
                        />
                      ) : spinner ? (
                        <Skeleton width="10rem" />
                      ) : (
                        ''
                      )}
                    </span>
                  </PricingInfoContent>
                </div>
              </PurpleOutline>
            )}
          </FlexWrapper>
        </Card>
      )}
    </>
  );
};

export default ReviewPricing;
