import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import { useTracking } from 'react-tracking';

interface RadioButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean;
  value?: string;
}

const Label = styled.label`
  display: flex;
  margin: 0 0 5px;
  align-items: start;
`;

const StyledRadioButton = styled.input`
  cursor: pointer;
  margin-right: 10px;
  accent-color: var(--color-blue);
`;

const RadioButton: React.FC<RadioButtonProps> = ({
  children,
  checked,
  value,
  onChange,
  ...props
}: RadioButtonProps): React.ReactElement => {
  const radioButtonRef = useRef<HTMLInputElement>(null);
  const { trackEvent } = useTracking({ event: 'Radio Button Clicked' });
  const onChangeHandler = useCallback(
    evt => {
      trackEvent({
        content: radioButtonRef?.current?.value
      });
      onChange && onChange(evt);
    },
    [onChange, trackEvent, radioButtonRef]
  );

  return (
    <Label>
      <div>
        <StyledRadioButton
          ref={radioButtonRef}
          value={value}
          type="radio"
          onChange={onChangeHandler}
          checked={checked}
          {...props}
        />
      </div>
      {children}
    </Label>
  );
};

export default RadioButton;
