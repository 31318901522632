import React, { useState, useEffect, useContext, useCallback } from 'react';
import auth0 from 'auth0-js';
import createAuth0Spa from '@auth0/auth0-spa-js';
import { useCriticalError } from './CriticalError';
import { datadogRum } from '@datadog/browser-rum';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);
export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
                                children,
                                domain,
                                audience,
                                clientID,
                                connection,
                                baseURL,
                                responseType,
                                scope
                              }) => {
  const [auth0SPA, setAuth0SPA] = useState({});
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState('');
  const [callbackResponse, setCallbackResponse] = useState({});
  const [changePasswordError, setChangePasswordError] = useState(null);
  const [resetPasswordError, setResetPasswordError] = useState(null);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
  const { handleError } = useCriticalError();
  const displayError = useCallback(
    err => {
      sessionStorage.removeItem('ignoreResponseCode');
      setCallbackResponse(
        Object.assign({}, callbackResponse, {
          code: err.code,
          description: err.description
        })
      );
    },
    [callbackResponse]
  );

  useEffect(() => {
    (async () => {
      setTimeout(() => {
        if (auth0SPA === undefined) {
          throw new Error('Auth0 server response error');
        }
      }, 8000);
      const auth0SPA = await createAuth0Spa({
        domain,
        client_id: clientID,
        audience
      });
      setAuth0SPA(auth0SPA);

      if (sessionStorage.getItem('ignoreResponseCode')) {
        sessionStorage.removeItem('ignoreResponseCode');
        return auth0SPA.loginWithRedirect({
          redirect_uri: baseURL,
          scope
        });
      } else if (window.location.search.includes('code=')) {
        await auth0SPA.handleRedirectCallback();
        DEFAULT_REDIRECT_CALLBACK();
      }

      const isAuthenticated = await auth0SPA.isAuthenticated();
      setIsAuthenticated(isAuthenticated);
      if (isAuthenticated) {
        const user = await auth0SPA.getUser();
        setUser(user);
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const signUp = async (email, password) => {
    setCallbackResponse({});
    try {
      const auth0JS = await new auth0.WebAuth({
        domain,
        clientID,
        audience
      });
      await auth0JS.redirect.signupAndLogin(
        {
          connection,
          redirectUri: baseURL,
          responseType,
          scope,
          email,
          password
        },
        displayError
      );
      sessionStorage.setItem('ignoreResponseCode', true);
    } catch (err) {
      sessionStorage.removeItem('ignoreResponseCode');
      if (process.env.NODE_ENV === 'development') {
        console.error(err);
      }
      datadogRum.addError(err);
      handleError(new Error('Auth0 sign up error'));
    }
  };

  const login = async (email, password) => {
    setCallbackResponse({});
    try {
      const auth0JS = await new auth0.WebAuth({
        domain,
        clientID,
        audience
      });
      await auth0JS.login(
        {
          realm: connection,
          redirectUri: baseURL,
          responseType,
          scope,
          username: email,
          password
        },
        async err => {
          if (err.code === 'access_denied') {
            const sentryResponse = await fetch(
              `${window.env.REACT_APP_SENTRY_API_URL}/users/authenticate`,
              {
                method: 'POST',
                body: JSON.stringify({
                  email,
                  password
                }),
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                }
              }
            );
            if (sentryResponse.ok) {
              await auth0JS.login(
                {
                  realm: connection,
                  redirectUri: baseURL,
                  responseType,
                  scope,
                  username: email,
                  password
                },
                displayError
              );
              return;
            } else {
              sentryResponse.json().then(res => {
                if (res.code === 'MZXOF') window.location.href = `/reset-password?email=${email}`;
              });
            }
          }
          displayError(err);
        }
      );
      sessionStorage.setItem('ignoreResponseCode', true);
    } catch (err) {
      sessionStorage.removeItem('ignoreResponseCode');
      if (process.env.NODE_ENV === 'development') {
        console.error(err);
      }
      datadogRum.addError(err);
      handleError(new Error('Auth0 login error'));
    }
  };

  const logout = (path = '/') => {
    auth0SPA.logout({
      client_id: clientID,
      returnTo: `${baseURL}${path}`
    });
  };

  const changePassword = async email => {
    setChangePasswordError(null);
    const auth0JS = await new auth0.WebAuth({
      domain: domain,
      clientID: clientID,
      audience: audience
    });

    await auth0JS.changePassword(
      {
        connection: connection,
        email: email
      },
      err => {
        if (process.env.NODE_ENV === 'development') {
          console.error(err);
        }
        datadogRum.addError(err); 
        setChangePasswordError(err);
      }
    );
  };

  const resetPassword = async (email, currentPassword, newPassword) => {
    setResetPasswordError(null);
   try {
     const sentryResponse = await fetch(
       `${window.env.REACT_APP_SENTRY_API_URL}/users/reset_password`,
       {
         method: 'POST',
         body: JSON.stringify({
           currentPassword,
           email,
           newPassword
         }),
         headers: {
           Accept: 'application/json',
           'Content-Type': 'application/json'
         }
       }
     );
     if (sentryResponse.ok) setResetPasswordSuccess(true);
     else {
       sentryResponse.json().then(res => {
         setResetPasswordError(res);
       });
     }
   }
   catch (e) {
    if (process.env.NODE_ENV === 'development') {
      console.error(e);
    }
    datadogRum.addError(e);
    setResetPasswordError(e);
   }
  };

  return (
    <Auth0Context.Provider
      value={{
        loading,
        isAuthenticated,
        signUp,
        login,
        logout,
        changePassword,
        changePasswordError,
        resetPassword,
        resetPasswordError,
        resetPasswordSuccess,
        getTokenSilently: (...p) => auth0SPA.getTokenSilently(...p),
        user,
        callbackResponse
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
