import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  align-items: flex-start;
  display: flex;
`;

const StyledBody = styled.div`
  flex-grow: 1;
  color: var(--media-body-color, inherit);
  font-size: var(--media-body-size, inherit);
`;

const StyledHeading = styled.div`
  margin: 0;
  color: var(--media-heading-color, inherit);
  font-size: var(--media-heading-size, inherit);
  font-weight: var(--media-heading-weight, 600);
  line-height: 1em;
  margin-bottom: 0.25em;
`;

const StyledImage = styled.img<MediaProps>`
  ${p => (p.right ? 'margin-left: 20px' : 'margin-right: 20px')};
`;

interface MediaProps {
  body?: boolean;
  children?: React.ReactNode;
  heading?: boolean;
  image?: boolean;
  right?: boolean;
  size?: number;
}

const Media: React.FC<
  MediaProps & React.ImgHTMLAttributes<HTMLImageElement>
> = ({
  body,
  heading,
  image,
  ...attributes
}: MediaProps &
  React.ImgHTMLAttributes<HTMLImageElement>): React.ReactElement => {
  let Tag;
  if (body) {
    Tag = StyledBody;
  } else if (image) {
    Tag = StyledImage;
  } else if (heading) {
    Tag = StyledHeading;
  } else {
    Tag = StyledWrapper;
  }

  return <Tag {...attributes} />;
};

export default Media;
