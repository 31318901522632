import React, { memo, useEffect, useState } from 'react';
import { useFetch, CachePolicies } from 'use-http';
import { RecentDocuments } from '@bazaar/components';
import { useTracking } from 'react-tracking';

import { handleDocumentDownloadJWT } from 'lib';

const DocumentsSection = ({
  done
}: {
  done: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [documents, setDocuments] = useState<RecentDocument[]>();

  const { get: getDocuments } = useFetch(
    `${window.env.REACT_APP_API_URL}/documents`
  );
  const { get: getDocumentToken } = useFetch(
    `${window.env.REACT_APP_API_URL}/documents/token`,
    {
      cachePolicy: CachePolicies.NO_CACHE
    }
  );
  const { trackEvent } = useTracking({ component: 'Documents' });

  useEffect(() => {
    const fetchDocuments = async () => {
      const documentsData: RecentDocument[] = await getDocuments();
      const agreementAndStatement = await Promise.all(
        documentsData
          .reduce<RecentDocument[]>(
            (
              displayedDocuments: RecentDocument[],
              currentDocument: RecentDocument
            ) => {
              const creationDate = new Date(currentDocument.createdOn);
              if (currentDocument.documentType === 'SIGNED_CONTRACT') {
                if (
                  !displayedDocuments[0] ||
                  new Date(displayedDocuments[0].createdOn) < creationDate
                ) {
                  displayedDocuments[0] = {
                    ...currentDocument,
                    fileName: 'Loan Agreement'
                  };
                }
              } else if (currentDocument.documentType === 'MONTHLY_STATEMENT') {
                let statements = displayedDocuments.slice(1);
                statements.push({
                  ...currentDocument,
                  fileName: currentDocument.fileName.split('.')[0]
                });
                statements.sort((a, b) => {
                  return (
                    new Date(b.createdOn).getTime() -
                    new Date(a.createdOn).getTime()
                  );
                });
                return [displayedDocuments[0], ...statements];
              }
              return displayedDocuments;
            },
            []
          )
          .slice(0, 2)
          .map(
            async (
              document: RecentDocument
            ): Promise<RecentDocumentWithDownload> => {
              const anchorHref = `${window.env.REACT_APP_API_URL}/documents/${document.fileName}.pdf`;
              const downloadDocument = handleDocumentDownloadJWT(
                document,
                getDocumentToken
              );
              return {
                onDownloadLinkClick: async e => {
                  e.preventDefault();
                  const startTime = Date.now();
                  await downloadDocument();
                  if (document.fileName.includes('Statement')) {
                    trackEvent({
                      event: 'Statement Download',
                      readyTime: (Date.now() - startTime) / 1000
                    });
                  } else {
                    trackEvent({
                      event: 'Loan Agreement Download',
                      readyTime: (Date.now() - startTime) / 1000
                    });
                  }
                },
                href: anchorHref,
                ...document
              };
            }
          )
      );
      done(true);
      setDocuments(agreementAndStatement);
    };
    fetchDocuments();
  }, [getDocuments, getDocumentToken, trackEvent, done]);

  return <RecentDocuments documents={documents} viewAllPath="/documents" />;
};

export default memo(DocumentsSection);
