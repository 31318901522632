import React from 'react';
import { datadogRum } from '@datadog/browser-rum';

declare function RenderFunction(
  props: ErrorBoundaryState
): React.ReactElement<
  unknown,
  string | React.FunctionComponent | typeof React.Component
> | null;

interface ErrorBoundaryState {
  error: Error | null;
}

interface ErrorBoundaryProps {
  component?: typeof React.Component | null;
  /** Useful for logging, analytics, or other side effects */
  onError?: (error: Error) => void;
  /** A render prop allows inline customization of what is displayed with a function
   * that accepts the boundary state e.g. {error} as its argument */
  render?: typeof RenderFunction;
}

const initialState: ErrorBoundaryState = {
  error: null
};

class ErrorBoundary extends React.Component<
  React.PropsWithChildren<ErrorBoundaryProps>,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = initialState;

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    if (process.env.NODE_ENV === 'development') {
      console.error(error);
    }
    datadogRum.addError(error, {
      message: error.message,
      stack: error.stack,
      errorInfo: errorInfo.componentStack
    });
    this.props.onError?.(error);
  }

  render(): React.ReactNode {
    const { error } = this.state;
    const { render, component: Component } = this.props;

    if (error !== null) {
      const props = { error };
      if (typeof render === 'function') {
        return render(props);
      } else if (Component) {
        return <Component {...props} />;
      } else {
        throw new Error(
          'Please provide a fallback UI via the render (function) or component props'
        );
      }
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
