import { Factory, Model, Serializer, Server, Response } from 'miragejs';

export const samplePartiesData = (apiURL: string) => {
  return {
    user: {
      id: '905722e8-a2f9-4daa-bbae-97859a6d635b',
      name: 'Abigail Ripley',
      href: `${apiURL}/persons/905722e8-a2f9-4daa-bbae-97859a6d635b`
    },
    parties: [
      {
        type: 'CUSTOMER',
        id: '34d76920-0fa1-40a8-b605-42ed27994e80',
        people: [
          {
            id: '905722e8-a2f9-4daa-bbae-97859a6d635b',
            name: 'Abigail Ripley',
            href: `${apiURL}/persons/905722e8-a2f9-4daa-bbae-97859a6d635b`
          }
        ],
        businesses: [
          {
            id: '320a748d-594b-431f-adbc-d415dcb9fbab',
            legalName: 'Xmen Neighborhood Market',
            href: `${apiURL}/businesses/320a748d-594b-431f-adbc-d415dcb9fbab`
          }
        ],
        loans: [
          {
            href: `${apiURL}/loans/7534985345734983`,
            id: '7534985345734983',
            loanType: 'FIXED_TERM'
          },
          {
            href: `${apiURL}/loans/7534985345734984`,
            id: '7534985345734984',
            loanType: 'FIXED_TERM'
          },
          {
            href: `${apiURL}/locs/4534985345734984`,
            id: '4534985345734984',
            loanType: 'LINE_OF_CREDIT'
          }
        ]
      }
    ]
  };
};

export const sampleLOCData: LOC = {
  creditLimit: 4400,
  balanceOutstanding: 1400,
  availableCredit: 16773.34,
  id: '4534985345734984',
  status: 'ACTIVE',
  collectionOnHold: false,
  drawOnHold: false,
  periodicPayment: 533,
  annualPercentageRate: 36,
  outstandingPrincipal: 300.34,
  paymentFrequency: 'WEEKLY',
  paymentDayOfWeek: 'WEDNESDAY',
  periodicFee: 5,
  term: 'P12M',
  type: 'LINE_OF_CREDIT',
  reamortizationStatus: null,
  instantFunding: {
    eligible: true,
    cardInfo: {
      registered: true,
      account: '1115',
      minimum: 0.01,
      maximum: 10000.0
    }
  }
};

export const sampleTermLoanData: TermLoan = {
  approvedAmount: 25000,
  balancePaid: 15934.12,
  estimatedPayoffDate: '2021-06-25',
  estimatedPaymentsRemaining: 4,
  type: 'FIXED_TERM',
  term: 'P6M',
  id: '7534985345734983',
  estimatedPayoffAmount: 12000,
  balanceOutstanding: 9065.88,
  percentagePaidDown: 63.7,
  status: 'CLOSED'
};

export const sampleTransactionData: LoanActivity = {
  amount: 100.01,
  beginningBalance: 25000.0,
  clearedDate: '2021-02-21',
  currency: 'USD',
  initiatedDate: '2021-01-21',
  interest: 12,
  loanId: '7534985345734983',
  principal: 100,
  remainingBalance: 24899.99,
  status: 'Completed',
  transactionSetId: 'ABC123456',
  type: 'Payment'
};

export const mockNextPayment: NextPayment = {
  loanId: '7534985345734983',
  loanStatus: 'NORMAL',
  onHold: false,
  frequency: 'MONTHLY',
  currency: 'USD',
  payment: {
    amount: 92.06,
    scheduledDate: '2021-04-12T00:00:00Z'
  },
  estimatedPaymentsRemaining: 4
};

export const profile: Profile = {
  firstName: 'Abigail',
  lastName: 'Ripley',
  fullName: 'Abigail Ripley',
  preferredName: 'Ms. Abigail Ripley',
  href:
    'https://spiceroad.ondeck.com/v1/persons/123e4567-e89b-12d3-a456-426614174000',
  id: '123e4567-e89b-12d3-a456-426614174000',
  addresses: [
    {
      id: 'aab1234e-1ac2-4f86-567-dac4b9b55d62',
      lines: ['259 N 14th St', 'Apt 2B'],
      city: 'Benneton',
      state: 'CO',
      postalCode: '00000',
      country: 'USA',
      type: 'BUSINESS'
    },
    {
      id: 'aab1234e-1ac2-4f86-567-dac4b9b55d63',
      lines: ['5876 Pendleton Blvd', 'Ste. 12'],
      city: 'Arvada',
      state: 'CO',
      postalCode: '64009',
      country: 'USA',
      type: 'PHYSICAL_ADDRESS'
    }
  ],
  contacts: [
    {
      id: 'pab1234e-1ac2-4f86-567-dac4b9b55d62',
      type: 'PHONE_NUMBER',
      value: '+01303555121'
    },
    {
      id: 'lab1234e-1ac2-4f86-567-dac4b9b55d62',
      type: 'EMAIL_ADDRESS',
      value: 'aripley+xmenmarket@roadrunner.com'
    },
    {
      id: 'fab1234e-1ac2-4f86-567-dac4b9b55d62',
      type: 'HOME_PHONE',
      value: '+07860835687'
    },
    {
      id: 'fab1234e-1ac2-4f86-567-dac4b9b55d63',
      type: 'HOME_PHONE',
      value: '+07845593883'
    }
  ]
};

const businessProfile = (apiURL: string) => {
  return {
    id: 'ABC-123-12345',
    legalName: 'Xmen Neighborhood Market, LLC',
    doingBusinessAs: 'Xmen Neighborhood Market',
    legalEntityType: 'LLC',
    href: `${apiURL}/v1/businesses/ABC-123-12345`,
    addresses: [
      {
        id: 'a743e54a-8df3-43d2-37db-1fabf1c7faba',
        lines: ['123 E. 4th St', 'STE. 123'],
        city: 'Arvada',
        state: 'CO',
        postalCode: '8000',
        country: 'USA',
        type: 'PHYSICAL_ADDRESS'
      }
    ],
    contacts: [
      {
        id: 'd743e54a-8df3-43d2-37db-1fabf1c7faaa',
        type: 'PHONE_NUMBER',
        value: '+15551118888'
      },
      {
        id: 'd743e54a-8df3-43d2-37db-1fabf1c7fabc',
        type: 'EMAIL_ADDRESS',
        value: 'aripley+xmenmarket@roadrunner.com'
      },
      {
        id: 'd743e54a-8df3-43d2-37db-1fabf1c7fdad',
        type: 'BUSINESS_PHONE',
        value: '+18881115555'
      }
    ],
    identifiers: [
      {
        type: 'TAX_ID',
        value: '******0719'
      },
      {
        type: 'ABN',
        value: '********9485'
      }
    ]
  };
};

const documentsData = [
  {
    documentId: 18867,
    documentType: 'MONTHLY_STATEMENT',
    fileName: 'OnDeckMonthlyStatement.pdf',
    createdOn: '2022-03-19T22:56:53.423+00:00',
    loanId: '7534985345734984',
    loanType: 'FIXED_TERM'
  },
  {
    documentId: 18868,
    documentType: 'MONTHLY_STATEMENT',
    fileName: 'OnDeckMonthlyStatement.pdf',
    createdOn: '2022-02-29T22:56:53.423+00:00',
    loanId: ''
  },
  {
    documentId: 18848,
    documentType: 'MONTHLY_STATEMENT',
    fileName: 'OnDeckMonthlyStatement.pdf',
    createdOn: '2022-02-23T22:56:53.423+00:00',
    loanId: '4534985345735965',
    loanType: 'LINE_OF_CREDIT'
  },
  {
    documentId: 18820,
    documentType: 'MONTHLY_STATEMENT',
    fileName: 'OnDeckMonthlyStatement.pdf',
    createdOn: '2022-02-14T22:56:53.423+00:00',
    loanId: '7534985345734984',
    loanType: 'FIXED_TERM'
  },
  {
    documentId: 18895,
    documentType: 'MONTHLY_STATEMENT',
    fileName: 'OnDeckMonthlyStatement.pdf',
    createdOn: '2021-06-10T22:56:53.423+00:00',
    loanId: '7534985345734984',
    loanType: 'FIXED_TERM'
  },
  {
    documentId: 18812,
    documentType: 'MONTHLY_STATEMENT',
    fileName: 'OnDeckMonthlyStatement.pdf',
    createdOn: '2021-06-10T22:56:53.423+00:00',
    loanId: '4534985345735965',
    loanType: 'LINE_OF_CREDIT'
  },
  {
    documentId: 18868,
    documentType: 'MONTHLY_STATEMENT',
    fileName: 'OnDeckMonthlyStatement.pdf',
    createdOn: '2021-05-10T22:56:53.423+00:00',
    loanId: '4534985345735965',
    loanType: 'LINE_OF_CREDIT'
  },
  {
    documentId: 18874,
    documentType: 'MONTHLY_STATEMENT',
    fileName: 'OnDeckMonthlyStatement.pdf',
    createdOn: '2021-04-03T22:56:53.423+00:00',
    loanId: '4534985345735965',
    loanType: 'LINE_OF_CREDIT'
  },
  {
    documentId: 18824,
    documentType: 'SIGNED_CONTRACT',
    fileName: 'OnDeckLoanContract.pdf',
    createdOn: '2021-02-23T15:11:42.516+00:00',
    loanId: '4534985345735965',
    loanType: 'LINE_OF_CREDIT'
  },
  {
    documentId: 18883,
    documentType: 'SIGNED_CONTRACT',
    fileName: 'OnDeckLoanContract.pdf',
    createdOn: '2021-03-30T21:03:14.815+00:00',
    loanId: '7534985345734984',
    loanType: 'FIXED_TERM'
  }
];

export function makeServer({ environment = 'development' } = {}) {
  let server = new Server({
    environment,
    models: {
      transaction: Model
    },
    factories: {
      transaction: Factory.extend({
        amount: 100.01,
        beginningBalance: 25000.0,
        clearedDate: '2021-02-21',
        currency: 'USD',
        initiatedDate: '2021-01-21',
        interest: 12,
        loanId: '7534985345734983',
        principal: 100,
        remainingBalance: 24899.99,
        status: 'Completed',
        transactionSetId(i: number) {
          return `${i}`;
        },
        type: 'Payment'
      })
    },
    seeds(server) {
      server.createList('transaction', 30);
    },
    serializers: {
      application: Serializer.extend(),
      transaction: Serializer.extend({
        embed: true,
        root: false
      })
    },
    routes() {
      this.urlPrefix = window.env.REACT_APP_API_URL;
      this.get('/persons/:personUUID', () => {
        return profile;
      });
      this.get('/businesses/:uuid', () => {
        return businessProfile(window.env.REACT_APP_API_URL);
      });
      this.get('/users/profile', () => {
        return {
          id: 12345,
          email: 'aripley+xmenmarket@roadrunner.com'
        };
      });
      this.get('/parties', () => {
        return samplePartiesData(window.env.REACT_APP_API_URL);
      });
      this.get(
        `${window.env.REACT_APP_BUTTERFLY_URL}/orchestration/current_application`,
        () => {
          return {
            id: '9243783457',
            type: 'RENEWAL',
            href: '/9243783457',
            userId: '12345236',
            status: 'APPROVED',
            sourcePartyId: 'Abc_13435',
            issuesExist: false,
            issues: 'no',
            data: {},
            metadata: {
              updatedAt: '2021-04-04T19:13:43.757Z'
            }
          };
        }
      );
      this.get(
        `${window.env.REACT_APP_OFFER_ORCHESTRATOR_V2_API_URL}/offers`,
        () => {
          return {
            offers: [
              {
                applicationId: '9243783457',
                presentationStatus: 'ACTIVE',
                maxLineAmount: 251000,
                pricedDetails: {
                  averageMonthlyPayment: 734.28,
                  disbursementAmount: 25000,
                  lender: 'OnDeck Capital, Inc',
                  lineAmount: 25000,
                  loanOriginationFee: 950,
                  modelId: '5fd29e2e64f8cb06f816209c',
                  numberOfPayments: 245,
                  payment: 967.77,
                  paymentFrequency: 'Daily',
                  term: 4,
                  totalAmountPaidBack: 1500,
                  totalLoanCost: 100.35,
                  trueAPR: 44.24,
                  trueCentsOnDollar: 1.16
                },
                userActionStatus: 'SELECTED'
              },
              {
                applicationId: '9243783458',
                presentationStatus: 'ACTIVE',
                maxLineAmount: 253000,
                pricedDetails: {
                  averageMonthlyPayment: 734.28,
                  disbursementAmount: 25000,
                  lender: 'OnDeck Capital, Inc',
                  lineAmount: 250000,
                  loanOriginationFee: 950,
                  modelId: '5fd29e2e64f8cb06f816209c',
                  numberOfPayments: 245,
                  payment: 967.77,
                  paymentFrequency: 'Daily',
                  term: 4,
                  totalAmountPaidBack: 1500,
                  totalLoanCost: 100.35,
                  trueAPR: 44.24,
                  trueCentsOnDollar: 1.16
                },
                userActionStatus: 'SELECTED'
              }
            ]
          };
        }
      );
      this.post(
        `${window.env.REACT_APP_API_URL}/locs/:id/draw/preview`,
        () => {
          return {
            amount: 1000,
            apr: 36,
            numberOfPayments: 52,
            outstandingPrincipal: 1233.3,
            periodicPayment: 344.2,
            totalInterest: 1234
          };
        },
        { timing: 3000 }
      );
      this.post(
        `${window.env.REACT_APP_API_URL}/locs/:id/draw/create`,
        () => {
          return {
            amount: 1000,
            apr: 36,
            numberOfPayments: 52,
            outstandingPrincipal: 1233.3,
            periodicPayment: 344.2,
            totalInterest: 1234
          };
        },
        { timing: 3000 }
      );
      this.patch('/persons/:personUUID/addresses/:addressId', () => {
        return profile;
      });
      this.patch('/persons/:personUUID/contacts/:contactId', () => {
        return profile;
      });
      this.patch('/businesses/:uuid/contacts/:contactId', () => {
        return businessProfile(window.env.REACT_APP_API_URL);
      });
      this.get('/loans/:id', (schema, request) => {
        if (request.params.id === '7534985345734984') {
          return {
            approvedAmount: 25000,
            balancePaid: 15934.12,
            estimatedPayoffDate: '2021-06-25',
            estimatedPaymentsRemaining: 4,
            loanType: 'FIXED_TERM',
            term: 'P6M',
            id: '7534985345734984',
            estimatedPayoffAmount: 12000,
            balanceOutstanding: 9065.88,
            percentagePaidDown: 63.7,
            status: 'ACTIVE'
          };
        } else {
          return { ...sampleTermLoanData, id: request.params.id };
        }
      });
      this.get('/loans/:id/payment/next', (schema, request) => {
        return mockNextPayment;
      });
      this.get('/otpPreliminaryData/loan/:id', (schema, request) => {
        if (request.params.id === '7534985345734984') {
          return {
            bankName: 'Real Bank',
            // eslint-disable-next-line prettier/prettier
            remainingBalance: 23421.30,
            // eslint-disable-next-line prettier/prettier
            upperDraw: 5030.10,
            // eslint-disable-next-line prettier/prettier
            lowerDraw: 1.10,
            pastDue: 0,
            bankAccount: '12312421342131',
            // eslint-disable-next-line prettier/prettier
            nextPaymentAmount: 1323.10,
            nextPaymentDate: '2023-06-08T00:00:00Z',
            nextBusinessDay: '2023-11-24'
          };
        }
        return {
          bankName: 'Real Bank',
          remainingBalance: 1430.41,
          upperDraw: 1000,
          lowerDraw: 1,
          pastDue: 0,
          bankAccount: '12312421342131',
          nextPaymentAmount: 193,
          nextPaymentDate: '2023-06-08T00:00:00Z',
          nextBusinessDay: '2023-11-24'
        };
      });
      this.get('/loans/transactions', (schema, request) => {
        return [
          {
            loanId: '3198618732718467',
            loanType: 'FIXED_TERM',
            currency: 'USD',
            transactionSetId: '46532015',
            initiatedDate: '2021-03-11T00:00:00Z',
            clearedDate: null,
            type: 'Loan Payment',
            amount: 92.06,
            status: 'P',
            beginningBalance: 9189.26,
            remainingBalance: 9097.2,
            principal: 72.473877,
            interest: 19.586123
          },
          {
            loanId: '3198618732718467',
            loanType: 'FIXED_TERM',
            currency: 'USD',
            transactionSetId: '46531926',
            initiatedDate: '2021-03-04T00:00:00Z',
            clearedDate: '2021-03-04T00:00:00Z',
            type: 'Loan Payment',
            amount: 92.06,
            status: 'P',
            beginningBalance: 9281.32,
            remainingBalance: 9189.26,
            principal: 72.300401,
            interest: 19.759599
          },
          {
            loanId: '3198618732718467',
            loanType: 'LINE_OF_CREDIT',
            currency: 'USD',
            transactionSetId: '46531881',
            initiatedDate: '2021-02-26T00:00:00Z',
            clearedDate: '2021-02-26T00:00:00Z',
            type: 'Draw',
            amount: 92.06,
            status: 'C',
            beginningBalance: 9373.38,
            remainingBalance: 9281.32,
            principal: 72.12734,
            interest: 19.93266
          },
          {
            loanId: '3198618732718467',
            loanType: 'FIXED_TERM',
            currency: 'USD',
            transactionSetId: '46531872',
            initiatedDate: '2021-02-26T00:00:00Z',
            clearedDate: '2021-02-26T00:00:00Z',
            type: 'Loan Payment',
            amount: 150.0,
            status: 'C',
            beginningBalance: 9523.38,
            remainingBalance: 9373.38,
            principal: 117.144177,
            interest: 32.855823
          },
          {
            loanId: '3198618732718467',
            loanType: 'LINE_OF_CREDIT',
            currency: 'USD',
            transactionSetId: '46531865',
            initiatedDate: '2021-02-25T00:00:00Z',
            clearedDate: '2021-02-25T00:00:00Z',
            type: 'Draw',
            amount: 150.0,
            status: 'E',
            beginningBalance: 9673.38,
            remainingBalance: 9523.38,
            principal: 116.711203,
            interest: 33.288797
          },
          {
            loanId: '3198618732718467',
            loanType: 'FIXED_TERM',
            currency: 'USD',
            transactionSetId: '46531858',
            initiatedDate: '2021-02-24T00:00:00Z',
            clearedDate: '2021-02-24T00:00:00Z',
            type: 'Loan Payment',
            amount: 150.0,
            status: 'C',
            beginningBalance: 9823.38,
            remainingBalance: 9673.38,
            principal: 116.229607,
            interest: 33.770393
          },
          {
            loanId: '3198618732718467',
            loanType: 'FIXED_TERM',
            currency: 'USD',
            transactionSetId: '46531850',
            initiatedDate: '2021-02-23T00:00:00Z',
            clearedDate: '2021-02-23T00:00:00Z',
            type: 'Loan Payment',
            amount: 92.06,
            status: 'C',
            beginningBalance: 9915.44,
            remainingBalance: 9823.38,
            principal: 71.116718,
            interest: 20.943282
          },
          {
            loanId: '3198618732718467',
            loanType: 'FIXED_TERM',
            currency: 'USD',
            transactionSetId: '46531841',
            initiatedDate: '2021-02-23T00:00:00Z',
            clearedDate: '2021-02-23T00:00:00Z',
            type: 'Loan Payment',
            amount: 150.0,
            status: 'E',
            beginningBalance: 10065.44,
            remainingBalance: 9915.44,
            principal: 115.509829,
            interest: 34.490171
          },
          {
            loanId: '3198618732718467',
            loanType: 'LINE_OF_CREDIT',
            currency: 'USD',
            transactionSetId: '46531834',
            initiatedDate: '2021-02-22T00:00:00Z',
            clearedDate: '2021-02-22T00:00:00Z',
            type: 'Draw',
            amount: 150.0,
            status: 'C',
            beginningBalance: 10215.44,
            remainingBalance: 10065.44,
            principal: 115.076946,
            interest: 34.923054
          },
          {
            loanId: '3198618732718467',
            loanType: 'LINE_OF_CREDIT',
            currency: 'USD',
            transactionSetId: '46531827',
            initiatedDate: '2021-02-19T00:00:00Z',
            clearedDate: '2021-02-19T00:00:00Z',
            type: 'Draw',
            amount: 150.0,
            status: 'C',
            beginningBalance: 10365.44,
            remainingBalance: 10215.44,
            principal: 114.595053,
            interest: 35.404947
          },
          {
            loanId: '3198618732718467',
            loanType: 'LINE_OF_CREDIT',
            currency: 'USD',
            transactionSetId: '46531820',
            initiatedDate: '2021-02-18T00:00:00Z',
            clearedDate: '2021-02-18T00:00:00Z',
            type: 'Draw',
            amount: 150.0,
            status: 'P',
            beginningBalance: 10515.44,
            remainingBalance: 10365.44,
            principal: 114.171554,
            interest: 35.828446
          },
          {
            loanId: '3198618732718467',
            loanType: 'FIXED_TERM',
            currency: 'USD',
            transactionSetId: '46531803',
            initiatedDate: '2021-02-17T00:00:00Z',
            clearedDate: '2021-02-17T00:00:00Z',
            type: 'Loan Payment',
            amount: 150.0,
            status: 'C',
            beginningBalance: 10665.44,
            remainingBalance: 10515.44,
            principal: 113.724978,
            interest: 36.275022
          },
          {
            loanId: '3198618732718467',
            loanType: 'LINE_OF_CREDIT',
            currency: 'USD',
            transactionSetId: '46531790',
            initiatedDate: '2021-02-16T00:00:00Z',
            clearedDate: '2021-02-16T00:00:00Z',
            type: 'Draw',
            amount: 150.0,
            status: 'X',
            beginningBalance: 10815.44,
            remainingBalance: 10665.44,
            principal: 113.267406,
            interest: 36.732594
          },
          {
            loanId: '3198618732718467',
            loanType: 'LINE_OF_CREDIT',
            currency: 'USD',
            transactionSetId: '46531783',
            initiatedDate: '2021-02-15T00:00:00Z',
            clearedDate: '2021-02-15T00:00:00Z',
            type: 'Draw',
            amount: 150.0,
            status: 'P',
            beginningBalance: 10965.44,
            remainingBalance: 10815.44,
            principal: 112.848773,
            interest: 37.151227
          },
          {
            loanId: '3198618732718467',
            loanType: 'LINE_OF_CREDIT',
            currency: 'USD',
            transactionSetId: '46531766',
            initiatedDate: '2021-02-12T00:00:00Z',
            clearedDate: '2021-02-12T00:00:00Z',
            type: 'Draw',
            amount: 150.0,
            status: 'C',
            beginningBalance: 11115.44,
            remainingBalance: 10965.44,
            principal: 112.38889,
            interest: 37.61111
          },
          {
            loanId: '3198618732718467',
            loanType: 'FIXED_TERM',
            currency: 'USD',
            transactionSetId: '46531759',
            initiatedDate: '2021-02-11T00:00:00Z',
            clearedDate: '2021-02-11T00:00:00Z',
            type: 'Loan Payment',
            amount: 150.0,
            status: 'C',
            beginningBalance: 11265.44,
            remainingBalance: 11115.44,
            principal: 111.955128,
            interest: 38.044872
          },
          {
            loanId: '3198618732718467',
            loanType: 'FIXED_TERM',
            currency: 'USD',
            transactionSetId: '46531751',
            initiatedDate: '2021-02-10T00:00:00Z',
            clearedDate: '2021-02-10T00:00:00Z',
            type: 'Loan Payment',
            amount: 92.06,
            status: 'E',
            beginningBalance: 11357.5,
            remainingBalance: 11265.44,
            principal: 68.496511,
            interest: 23.563489
          },
          {
            loanId: '3198618732718467',
            loanType: 'LINE_OF_CREDIT',
            currency: 'USD',
            transactionSetId: '46531744',
            initiatedDate: '2021-02-10T00:00:00Z',
            clearedDate: '2021-02-10T00:00:00Z',
            type: 'Draw',
            amount: 150.0,
            status: 'X',
            beginningBalance: 11507.5,
            remainingBalance: 11357.5,
            principal: 111.274316,
            interest: 38.725684
          },
          {
            loanId: '3198618732718467',
            loanType: 'LINE_OF_CREDIT',
            currency: 'USD',
            transactionSetId: '46531727',
            initiatedDate: '2021-02-09T00:00:00Z',
            clearedDate: '2021-02-09T00:00:00Z',
            type: 'Draw',
            amount: 92.06,
            status: 'X',
            beginningBalance: 11599.56,
            remainingBalance: 11507.5,
            principal: 68.066205,
            interest: 23.993795
          },
          {
            loanId: '3198618732718467',
            loanType: 'LINE_OF_CREDIT',
            currency: 'USD',
            transactionSetId: '46531648',
            initiatedDate: '2021-02-05T00:00:00Z',
            clearedDate: '2021-02-05T00:00:00Z',
            type: 'Draw',
            amount: 250.0,
            status: 'E',
            beginningBalance: 11599.56,
            remainingBalance: 11599.56,
            principal: 0.0,
            interest: 0.0
          },
          {
            loanId: '3198618732718467',
            loanType: 'LINE_OF_CREDIT',
            currency: 'USD',
            transactionSetId: '46531643',
            initiatedDate: '2021-02-05T00:00:00Z',
            clearedDate: '2021-02-05T00:00:00Z',
            type: 'Draw',
            amount: 9750.0,
            status: 'C',
            beginningBalance: 11599.56,
            remainingBalance: 11599.56,
            principal: 0.0,
            interest: 0.0
          }
        ];
      });
      this.get('/locs/:id', (schema, request) => {
        return { ...sampleLOCData, id: request.params.id };
      });
      this.get('/locs/:id/payment/next', (schema, request) => {
        return {
          frequency: 'WEEKLY',
          nextPaymentAmount: 500,
          nextPaymentDate: '2021-12-12T00:00:00Z',
          onHold: false,
          loanStatus: 'NORMAL',
          loanId: '4534985345734984'
        };
      });
      this.get('/documents', () => {
        return documentsData;
      });
      this.get('documents/token/:id', () => {
        return new Response(
          200,
          undefined,
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
        );
      });
      this.get('documents/jwt/:token', () => {
        return new Response(
          200,
          {
            'content-type': 'application/pdf'
          },
          new Blob(['This will be streaming data'])
        );
      });
      this.get('/documents/:id.pdf', function (db, request) {
        return new Response(
          200,
          {
            'content-type': 'application/pdf'
          },
          new Blob(['This will be streaming data'])
        );
      });
      this.get(`${window.env.REACT_APP_API_URL}/renewal`, () => {
        return {
          loceligible: false,
          termLoanEligible: true
        };
      });
      this.post(`${window.env.REACT_APP_ODO_URL}/apex/renewal/login`, () => {
        return new Response(401);
      });
      this.passthrough();
    }
  });
  return server;
}
