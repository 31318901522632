import React, { useCallback, useEffect, useState } from 'react';
import useFetch from 'use-http';
import { useIntl } from 'react-intl';
import { FormattedMoney, RenewalNotification } from '@bazaar/components';
import { usePartiesAPI } from '../../../contexts/PartiesAPI';
import { datadogRum } from '@datadog/browser-rum';

const NotificationSection = () => {
  const { partiesData } = usePartiesAPI();
  const intl = useIntl();
  const [application, setApplication] = useState<Application>();
  const [applicationId, setApplicationId] = useState<string>();
  const [offer, setOffer] = useState<Offer>();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationDismissed, setNotificationDismissed] = useState<string>();

  /* this won't scale past the first renewal, so this will need to be revisited. */
  const firstLoanId = partiesData?.parties?.[0].loans?.[0]?.id;
  const currentApplicationHref = `${window.env.REACT_APP_BUTTERFLY_URL}/orchestration/current_application`;
  const offersHref = `${window.env.REACT_APP_OFFER_ORCHESTRATOR_V2_API_URL}/offers?applicationId=${applicationId}`;

  const { get: getCurrentApplication } = useFetch(currentApplicationHref);
  const { get: getOffers } = useFetch(offersHref);

  const fetchApplication = async () => {
    const currentApplicationData: Application = await getCurrentApplication();
    try {
      setApplication(currentApplicationData);
      setApplicationId(currentApplicationData.id);
    } catch (error) {
      setShowNotification(true);
      if (process.env.NODE_ENV === 'development') {
        console.error(error);
      }
      datadogRum.addError(error);
    }
  };

  const fetchOffers = async () => {
    const offersData = await getOffers();
    try {
      if (offersData.offers.length > 1) {
        let maxOffers = offersData.offers.reduce(
          (prev: IOffer, curr: IOffer) => {
            return prev.maxLineAmount > curr.maxLineAmount ? prev : curr;
          }
        );
        setOffer(maxOffers);
      } else setOffer(offersData.offers[0]);
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error(error);
      }
      datadogRum.addError(error);
      setShowNotification(true);
    }
  };

  useEffect(() => {
    // check if user clicked dismiss button
    const storedDismissed = sessionStorage.getItem('notificationDismissed');
    storedDismissed && setNotificationDismissed(storedDismissed);
  }, []);

  useEffect(() => {
    fetchApplication();
  }, [getCurrentApplication]);

  useEffect(() => {
    applicationId && fetchOffers();
  }, [applicationId, getOffers]);

  useEffect(() => {
    offer && setShowNotification(true);
  }, [offer]);

  useEffect(() => {
    notificationDismissed &&
      sessionStorage.setItem('notificationDismissed', notificationDismissed);
  }, [notificationDismissed]);

  const redirectToCheckout = useCallback(() => {
    window.location.href = `${window.env.REACT_APP_CHECKOUT_UX_URL}/redirect/auth?applicationId=${applicationId}&loanId=${firstLoanId}`;
  }, [applicationId, firstLoanId]);

  const onNotificationDismiss = () => setNotificationDismissed('dismissed');

  const NotifyApprovedApplicationWithOffer = () => (
    <RenewalNotification
      bgColor="blue"
      cta={intl.formatMessage({
        id: 'renewalNotification.callToAction.openOffer'
      })}
      dismissMessage={intl.formatMessage({
        id: 'renewalNotification.dismissMessage.openOffer'
      })}
      onClick={redirectToCheckout}
      onDismiss={onNotificationDismiss}
      title={intl.formatMessage(
        {
          id: 'renewalNotification.title.openOffer'
        },
        {
          lineAmount: (
            <FormattedMoney
              value={offer?.maxLineAmount}
              maximumFractionDigits={0}
            />
          )
        }
      )}
      height="270px"
    ></RenewalNotification>
  );

  //prettier-ignore
  if (showNotification && !notificationDismissed) {
    if (offer?.presentationStatus === 'ACTIVE'
        && application?.status === 'APPROVED'
        && application.type === 'RENEWAL') {
      return <NotifyApprovedApplicationWithOffer/>;
    }
  }
  return null;
};

export default NotificationSection;
