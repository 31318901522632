import { css, createGlobalStyle } from 'styled-components';
import { breakpoints, mediaQuery } from './lib';
import GalanoGrotesqueBold from './assets/fonts/GalanoGrotesque-Bold.woff';
import GalanoGrotesqueSemiBold from './assets/fonts/GalanoGrotesque-SemiBold.woff';
import GalanoGrotesqueMedium from './assets/fonts/GalanoGrotesque-Medium.woff';
import GalanoGrotesqueRegular from './assets/fonts/GalanoGrotesque-Regular.woff';

// styles for the React Day Picker library used in the Date Picker component
import 'react-day-picker/dist/style.css';

const typeScale = [
  '1rem',
  '1.2rem',
  '1.4rem',
  '1.6rem',
  '1.8rem',
  '2rem',
  '2.4rem'
];
const breakpointsCss = `
  --viewportSm: ${breakpoints.sm};
  --viewportMd: ${breakpoints.md};
  --viewportLg: ${breakpoints.lg};
`;
const buttonColors = `
  --button-primary-bg: #006beb;
  --button-primary-bg-hover: #0444db;
  --button-primary-content: #fff;
  --button-light-bg: #fff;
  --button-light-border: #006beb;
  --button-light-border-hover: #006beb;
  --button-light-content: #006beb;
`;
const colors = `
  --color-accent-avatar: #00a089;
  --color-accent: #12009f;
  --color-anchor-hover: #006beb;
  --color-anchor: #006beb;
  --color-blue-bg: rgba(0, 150, 255, 0.05);
  --color-blue-outline: #006beb;
  --color-blue-light: #0096ff;
  --color-saturated-blue: #575b76;
  --color-blue: #006beb;
  --color-body: #000;
  --color-border-button: #e8e8ed;
  --color-brand: #000082;
  --color-gray: #f3f6fa;
  --color-green-bg: rgba(131, 238, 206, 0.1);
  --color-green: #2cb596;
  --color-mint: #83eece;
  --color-muted: #585a76;
  --color-primary-dark: #010135;
  --color-primary: #000082;
  --color-red-bg: rgba(255, 71, 74, 0.1);
  --color-red: #ff474a;
  --color-separator: #e8e8ed;
  --color-yellow: #e6a901;
  --color-white: #fff;
`;
const labelColors = `
  --label-alertBlue-border: #C2DBFA;
  --label-alertBlue-bg: #F3F6FA;
  --label-alertBlue: #0055BC;
  --label-blue-bg: #f2faff;
  --label-blue: #585a76;
  --label-green-bg: #f3fffb;
  --label-green-border: #83eece;
  --label-green: #31816f;
  --label-red-bg: #fdeeee;
  --label-red-border: #f8bac3;
  --label-red: #da162b;
  --label-yellow-bg: #FEFAF2;
  --label-yellow-border: #F8E2B3;
  --label-yellow: #895E01;
`;
const fontSizes = css`
  --fontSize7: ${typeScale[0]};
  --fontSize6: ${typeScale[1]};
  --fontSize5: ${typeScale[2]};
  --fontSize4: ${typeScale[3]};
  --fontSize3: ${typeScale[4]};
  --fontSize2: ${typeScale[5]};
  --fontSize1: ${typeScale[6]};
  --bodyFontSize: var(--fontSize6);
  --mediumFontSize: 1.7rem;
  ${mediaQuery.sm`
    --bodyFontSize: var(--fontSize5);
  `}
`;
const fontWeights = `
  --fontWeightBody: normal;
  --fontWeightMedium: 500;
  --fontWeightSemiBold: 600;
  --fontWeightBold: 700;
`;

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Galano Grotesque';
    src: local('GalanoGrotesque-Regular'), url(${GalanoGrotesqueRegular}) format('woff');
  }
  @font-face {
    font-family: 'Galano Grotesque';
    font-weight: 500;
    src: local('GalanoGrotesque-Medium'), url(${GalanoGrotesqueMedium}) format('woff');
  }
  @font-face {
    font-family: 'Galano Grotesque';
    font-weight: 600;
    src: local('GalanoGrotesque-SemiBold'), url(${GalanoGrotesqueSemiBold}) format('woff');
  }
  @font-face {
    font-family: 'Galano Grotesque';
    font-weight: 700;
    src: local('GalanoGrotesque-Bold'), url(${GalanoGrotesqueBold}) format('woff');
  }
  @font-face {
    font-family: 'Galano Grotesque Medium';
    src: local('GalanoGrotesque-Medium'), url(${GalanoGrotesqueMedium}) format('woff');
  }
  @font-face {
    font-family: 'Galano Grotesque SemiBold';
    src: local('GalanoGrotesque-SemiBold'), url(${GalanoGrotesqueSemiBold}) format('woff');
  }
  @font-face {
    font-family: 'Galano Grotesque Bold';
    src: local('GalanoGrotesque-Bold'), url(${GalanoGrotesqueBold}) format('woff');
  }

  * {
    box-sizing: border-box;
  }

  :root {
    ${buttonColors};
    ${colors};
    ${labelColors};
    ${fontWeights};
    ${fontSizes};
    ${breakpointsCss};
    font-size: 62.5%;
  }

  body {
    color: var(--color-body);
    font-family: "Galano Grotesque", system-ui, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, sans-serif;
    font-size: var(--bodyFontSize);
  }
`;
