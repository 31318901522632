import React from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import {
  Button,
  GenericMessage,
  getMediaQuery,
  defaultBreakpoints
} from '@ondeck/silkworm';
import { FormattedMessage, useIntl } from 'react-intl';
import Page404 from '../../assets/images/page404.svg';
import Link from '../Link/Link';

const mQ = getMediaQuery(defaultBreakpoints);

const PageWrapper = styled.div`
  margin: 60px auto;
  width: 100%;
`;

const PhoneLink = styled(Link)`
  color: var(--fontColorsBrand);
  text-decoration: none;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
`;

const NotFoundPage: React.FC = (): React.ReactElement => {
  const intl = useIntl();
  const phoneNumber = intl.formatMessage({ id: 'common.contactNumber' });
  return (
    <PageWrapper>
      <GenericMessage
        image={Page404}
        title={intl.formatMessage({ id: 'notFoundPage.title' })}
      >
        <p>
          <FormattedMessage id="notFoundPage.content.paragraph.1" />
          <PhoneLink href={`tel:${phoneNumber}`} target="_self">
            <NumberFormat
              displayType="text"
              value={phoneNumber}
              format={intl.formatMessage({ id: 'common.numberFormat' })}
            />
          </PhoneLink>
          <FormattedMessage id="notFoundPage.content.paragraph.2" />
        </p>
        <ReturnHomeButton
          text={intl.formatMessage({ id: 'notFoundPage.button.returnHome' })}
        />
      </GenericMessage>
    </PageWrapper>
  );
};

type ButtonProps = { text?: string; href?: string };

export const ReturnHomeButton: React.FC<ButtonProps> = ({
  text
}: ButtonProps) => {
  return <Button onClick={() => window.location.replace('/')}>{text}</Button>;
};

export default NotFoundPage;
