import { useEffect, useState } from 'react';

export default function useMedia(
  queries: Array<string>,
  values: Array<unknown>,
  defaultValue: unknown
): unknown {
  const mediaQueryLists =
    typeof window !== 'undefined' ? queries.map(q => window.matchMedia(q)) : [];

  const getValue = () => {
    const index = mediaQueryLists.findIndex(mql => mql.matches);
    return typeof values[index] !== 'undefined' ? values[index] : defaultValue;
  };

  const [value, setValue] = useState(getValue);

  useEffect(
    () => {
      const handler = () => setValue(getValue);
      mediaQueryLists.forEach(mql =>
        mql.hasOwnProperty('addEventListener')
          ? mql.addEventListener('change', handler)
          : mql.addListener(handler)
      );
      return () =>
        mediaQueryLists.forEach(mql =>
          mql.hasOwnProperty('removeEventListener')
            ? mql.removeEventListener('change', handler)
            : mql.removeListener(handler)
        );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return value;
}
