import { createGlobalStyle } from 'styled-components';
import GalanoGrotesqueBold from './static/fonts/GalanoGrotesque-Bold.woff';
import GalanoGrotesqueSemiBold from './static/fonts/GalanoGrotesque-SemiBold.woff';
import GalanoGrotesqueMedium from './static/fonts/GalanoGrotesque-Medium.woff';
import GalanoGrotesqueRegular from './static/fonts/GalanoGrotesque-Regular.woff';

const ondeckBlue = '#0074ff';
const ondeckDarkBlue = '#000082';
const ondeckHoverBlue = '#0064E9';

export const breakpoints = {
  lg: '992px',
  md: '768px',
  sm: '576px'
};

export const ondeckTheme = {
  backgroundColors: {
    aside: '#f7f7f7',
    brandHoverColor: ondeckHoverBlue,
    callOut: '#f8f8f8',
    dragNDrop: '#f0f0f0',
    error: '#d41818',
    footer: '#293546',
    hoverLight: '#f2f2f2',
    inactive: '#aaa',
    light: '#fff',
    primary: ondeckBlue,
    progressBar: '#e5e5e5',
    secondary: ondeckDarkBlue
  },
  borderColors: {
    card: '#979797',
    dark: '#0f1129',
    inactive: '#aaa',
    dragNDrop: '#b9b7ba',
    error: '#d0021b',
    field: '#ccc',
    primary: ondeckBlue,
    separator: '#e0e0e1',
    aside: '#e0e0e1',
    success: '#00bd77',
    footer: 'rgba(192, 196, 201, 0.4)'
  },
  breakpoints,
  colors: {
    anchorHover: '#000099',
    anchorLight: '#fff',
    anchorNormal: '#000082',
    anchorVisited: '#000082',
    bodyAlt: '#4a4a4a',
    brand: ondeckBlue,
    callout: '#000082',
    dark: '#000000',
    hint: '#7b7b7b',
    inactive: '#aaa',
    error: '#d0021b',
    field: '#4a4a4a',
    navbarLight: '#717171',
    light: '#fff',
    success: '#00bd77',
    visitedLink: '#005ccc',
    titleGrid: '#0f1129',
    onHoverBlue: ondeckHoverBlue
  },
  fonts: {
    bold: 'Galano Grotesque Bold',
    semibold: 'Galano Grotesque Semi-Bold',
    regular: 'Galano Grotesque'
  },
  fontSizes: {
    body: '1.4rem',
    button: '1.6rem',
    field: '1.8rem',
    heading: '3.6rem',
    subtext: '1.2rem',
    title: '2.2rem',
    msgtext: '1.4rem'
  },
  lineHeights: {
    body: '1.5em',
    field: '1.5em',
    heading: '1.167em'
  },
  zIndexes: {
    calendar: 2,
    contextMenu: 100,
    overlay: 500,
    page: 1,
    tooltip: 100
  }
};

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Galano Grotesque';
    src: local('GalanoGrotesque-Regular'), url(${GalanoGrotesqueRegular}) format('woff');
  }

  @font-face {
    font-family: 'Galano Grotesque';
    font-weight: 500;
    src: local('GalanoGrotesque-Medium'), url(${GalanoGrotesqueMedium}) format('woff');
  }

  @font-face {
    font-family: 'Galano Grotesque';
    font-weight: 600;
    src: local('GalanoGrotesque-SemiBold'), url(${GalanoGrotesqueSemiBold}) format('woff');
  }

  @font-face {
    font-family: 'Galano Grotesque';
    font-weight: 700;
    src: local('GalanoGrotesque-Bold'), url(${GalanoGrotesqueBold}) format('woff');
  }

  @font-face {
    font-family: 'Galano Grotesque SemiBold';
    src: local('GalanoGrotesque-SemiBold'), url(${GalanoGrotesqueSemiBold}) format('woff');
  }

  @font-face {
    font-family: 'Galano Grotesque Bold';
    src: local('GalanoGrotesque-Bold'), url(${GalanoGrotesqueBold}) format('woff');
  }

  @font-face {
    font-family: 'Galano Grotesque';
    src: local('GalanoGrotesque-Bold'), url(${GalanoGrotesqueBold}) format('woff');
    unicode-range: U+00B7;
  }

  * {
    box-sizing: border-box;
  }

  html {
    background: #f5f8fc;
    font-size: 62.5%;
  }

  body {
    font-family: ${ondeckTheme.fonts.regular}, Arial,'Helvetica', sans-serif;
    font-size: ${ondeckTheme.fontSizes.body};
    line-height: normal;
    margin: 0;
    padding: 0;
  }

  html, body, #root {
    height: 100%;
  }

  label, input, select, option {
    font-size: ${ondeckTheme.fontSizes.field};
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
  }

  small {
    font-size: 75%;
  }

`;
