import React, { useCallback } from 'react';
import styled from 'styled-components';

import DownCarrot from '../../assets/images/arrow-down.svg';

interface AccordionHeadingProps {
  title: string;
  subtitle?: string;
  index: number;
  isActive?: boolean;
  toggleItemActive: (index: number) => void;
}

const CarrotWrapper = styled.div<{ isActive?: boolean }>`
  transform: ${props => (props.isActive ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s linear;
`;

const CarrotImage = styled.img`
  width: 17px;
  height: 14px;
`;

const TitleWrapper = styled.div`
  font-family: Galano Grotesque Bold;
  font-size: var(--fontSize4);
  margin-left: 8px;
`;

const SubTitle = styled.div`
  margin-left: auto;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  cursor: pointer;
`;

const AccordionHeading: React.FC<AccordionHeadingProps> = ({
  title,
  subtitle,
  index,
  isActive,
  toggleItemActive
}: AccordionHeadingProps) => {
  const handleOnClick = useCallback(() => {
    toggleItemActive(index);
  }, [index, toggleItemActive]);
  return (
    <Wrapper
      role="button"
      tabIndex={0}
      aria-expanded={isActive}
      aria-label={title}
      onClick={handleOnClick}
    >
      <CarrotWrapper isActive={isActive}>
        <CarrotImage alt="Down Carrot" src={DownCarrot} />
      </CarrotWrapper>
      <TitleWrapper>{title}</TitleWrapper>
      <SubTitle>{subtitle}</SubTitle>
    </Wrapper>
  );
};

export default AccordionHeading;
