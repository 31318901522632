import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { usePartiesAPI } from '../../../contexts/PartiesAPI';
import { FormattedMessage, useIntl } from 'react-intl';
import useFetch from 'use-http';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  mediaQuery as mQ,
  FormattedMoney,
  Button,
  Card,
  Link,
  RenewalNotification
} from '@bazaar/components';
import FundingMethod from './FundingMethod';
import ReviewPricing from './ReviewPricing';
import Skeleton from 'react-loading-skeleton';
import { useAuth } from '../../../contexts/Auth';
import { FormatForPhoneNumber } from 'router/functions/FormatForPhoneNumber';
import { get } from '../../../lib';

const GridContainer = styled.div`
  display: grid;
  grid-row-gap: 2em;
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  font-size: var(--fontSize4);
  font-weight: var(--fontWeightBold);
  > div:last-child {
    font-size: var(--fontSize6);
    font-weight: var(--fontWeightBody);
    padding-top: 0.4em;
  }
  ${mQ.sm`
     flex-direction: row;
     justify-content: space-between;
  `}
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5rem 0 2rem;
  align-items: center;
  justify-content: space-between;
  ${mQ.md`
     flex-direction: row;
  `}
`;

const InfoWrapper = styled.div`
  line-height: 2rem;
  font-size: var(--fontSize6);
`;

const StyledButton = styled(Button)`
  width: 300px;
  margin-bottom: 2rem;
  :disabled {
    background: #dddddd;
    color: #616161;
    border: none;
  }
`;

interface AlertProps {
  color?: string;
  children: React.ReactNode;
}

const DrawFunds = () => {
  const [locDetails, setLOCDetails] = useState<ILOC>();
  const [fundingMethod, setFundingMethod] = useState<string>('ACH');
  const [drawDetails, setDrawDetails] = useState<IDraw>();
  const [drawAmount, setDrawAmount] = useState<number>(1000);
  const [error, setError] = useState<string>('');
  const [drawSpinner, setDrawSpinner] = useState(false);
  const [alert, setAlert] = useState<AlertProps>();
  const navigate = useNavigate();
  const { partiesData } = usePartiesAPI();
  const { addCredentials, loginServerEnabled } = useAuth();
  const intl = useIntl();

  const fetchLoanDetails = useCallback(
    (href: string) => get(href, loginServerEnabled, addCredentials),
    [addCredentials, loginServerEnabled]
  );

  const getActiveLoan = useCallback(
    async (loans: any) => {
      const results: LOC[] = await Promise.all(
        loans.map(async (loan: any) => fetchLoanDetails(loan.href))
      );
      const activeLoanIdx: number = results.findIndex(
        (loan: LOC) => loan.status === 'ACTIVE'
      );
      if (loans[activeLoanIdx]) {
        setLOCDetails(results[activeLoanIdx]);
      }
    },
    [fetchLoanDetails]
  );

  useEffect(() => {
    const loc = partiesData.parties[0].loans.filter(
      (loan: any) => loan.loanType === 'LINE_OF_CREDIT'
    );
    getActiveLoan(loc);
  }, []);

  useEffect(() => {
    setDrawDetails(undefined);
    setError('');
    if (locDetails && drawAmount > locDetails.availableCredit) {
      setError('Unable to draw more than max value');
    }
    if (drawAmount < 1000) {
      setError('Unable to draw less than min value');
    }
  }, [drawAmount, locDetails]);

  const createAlert = (color: string, alertText: string) => {
    let errorMsg = null;
    if (alertText && alertText.includes('draw on hold')) {
      errorMsg = (
        <FormattedMessage
          id={'drawFunds.loc.drawOnHold'}
          values={{
            tel: FormatForPhoneNumber
          }}
        />
      );
    } else {
      errorMsg = alertText ? (
        alertText
          .replace('LSI call returned error(s): ', '')
          .replace('(code 206)', '')
      ) : (
        <FormattedMessage
          id={'drawFunds.loc.helpline'}
          values={{
            tel: FormatForPhoneNumber
          }}
        />
      );
    }
    setAlert({ color: color, children: errorMsg });
  };

  const updateFundingMethod = (value: string) => {
    setFundingMethod(value);
  };

  const updateDrawAmount = (value: number) => {
    setDrawAmount(value);
  };

  const updateDrawDetails = (value: IDraw) => {
    setDrawDetails(value);
  };

  const drawCreateHref = `${window.env.REACT_APP_API_URL}/locs/${locDetails?.id}/draw/create`;
  const { post: drawCreate, response: drawCreateRes } = useFetch(
    drawCreateHref
  );
  const createDraw = async () => {
    setDrawSpinner(true);
    let createDrawReq = `{
      "apr": ${locDetails?.annualPercentageRate},
      "drawAmount": ${drawAmount},
      "fundingMethod": "${fundingMethod}",
      "outstandingPrincipal": ${locDetails?.outstandingPrincipal}
    }`;
    const newDrawDetails = await drawCreate(JSON.parse(createDrawReq));
    if (drawCreateRes.ok) {
      navigate('/', {
        state: {
          color: 'green',
          children: intl.formatMessage({
            id: 'drawFunds.loc.success'
          })
        }
      });
    }
    setDrawSpinner(false);
    createAlert('red', newDrawDetails?.rootException);
  };
  return (
    <>
      <RenewalNotification
        bgColor="blue"
        title={'Draw Funds'}
        dismissMessage=""
        height="150px"
      />
      {alert?.children && (
        <Alert color={alert?.color} hideOnDismiss>
          {alert?.children}
        </Alert>
      )}
      <GridContainer>
        <Card>
          <HeaderContent>
            <div>
              {locDetails?.availableCredit ? (
                <FormattedMessage
                  id="drawFunds.availableCredit"
                  values={{
                    val: (
                      <FormattedMoney
                        value={locDetails?.availableCredit}
                        maximumFractionDigits={2}
                      />
                    )
                  }}
                />
              ) : (
                <Skeleton width="14em" />
              )}
            </div>
            <div>
              {locDetails?.id ? (
                <FormattedMessage
                  id="drawFunds.loanId"
                  values={{ id: locDetails?.id }}
                />
              ) : (
                <Skeleton width="12em" />
              )}
            </div>
          </HeaderContent>
        </Card>
        <ReviewPricing
          drawAmount={drawAmount}
          fundingMethod={fundingMethod}
          updateDrawAmount={updateDrawAmount}
          updateFundingMethod={updateFundingMethod}
          updateDrawDetails={updateDrawDetails}
          error={error}
          locDetails={locDetails}
          drawDetails={drawDetails}
          createAlert={createAlert}
        />
        <FundingMethod
          drawAmount={drawAmount}
          fundingMethod={fundingMethod}
          updateFundingMethod={updateFundingMethod}
          locDetails={locDetails}
        />
      </GridContainer>
      <FlexWrapper>
        <StyledButton
          variant="light"
          style={{ background: 'transparent' }}
          onClick={() => (window.location.href = '/')}
        >
          <FormattedMessage id="drawFunds.button.cancel" />
        </StyledButton>
        <StyledButton
          variant="spinner"
          disabled={!drawDetails || error || drawSpinner}
          onClick={createDraw}
          showSpinner={drawSpinner}
        >
          <FormattedMessage id="drawFunds.button.confirmDraw" />
        </StyledButton>
      </FlexWrapper>
      <InfoWrapper>
        <p>
          <FormattedMessage id="drawFunds.instantFunding.withdrawals.info" />
        </p>
        <p>
          <FormattedMessage id="drawFunds.ach.withdrawals.info" />
        </p>
        <p>
          <FormattedMessage id="drawFunds.withdrawals.tc" />
        </p>
      </InfoWrapper>
    </>
  );
};
export default DrawFunds;
