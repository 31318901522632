import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  LoanBalance,
  ContactUs,
  defaultBreakpoints,
  getMediaQuery,
  LoadingSpinner
} from '@ondeck/silkworm';
import { useCriticalError } from '@bazaar/components';
import { useAuth } from '../../contexts/Auth';
import useFetch from 'use-http';
import { datadogRum } from '@datadog/browser-rum';
const mQ = getMediaQuery(defaultBreakpoints);

const Wrapper = styled.div`
  margin: 0 auto;
  width: 320px;
  ${mQ.md`
    display: flex;
    margin: 0;
    width: auto;
  `}
`;

const StyledLoanBalance = styled(LoanBalance)`
  margin: 16px;
  width: 100%;
  ${mQ.md`
    margin: 8px 15px 8px 0;
  `}
`;

const StyledContactUs = styled(ContactUs)`
  font-size: 14px;
  margin: 16px;
  width: 100%;
  ${mQ.md`
    margin: 8px 0;  
  `}
  ${mQ.lg`
    width: 265px;
  `}
`;

interface ILoan {
  readonly forgivenAmount: number;
  readonly lenderName: string;
  readonly lenderUrl: string;
  readonly loanAmount: number;
  readonly loanId: string;
  readonly outstandingPrincipal: number;
}

const ForgivenessPage: React.FC = () => {
  const [loan, setLoan] = useState({
    forgivenessAmount: 0,
    lender: '',
    lenderName: '',
    lenderUrl: '',
    loanAmount: 0,
    loanId: '',
    remainingPrincipal: 0
  });
  const { loading, isAuthenticated } = useAuth();
  const { setHasError } = useCriticalError();
  const { get } = useFetch(window.env.REACT_APP_API_URL);

  useEffect(() => {
    (async () => {
      if (!loading && isAuthenticated) {
        try {
          // Fetch parties
          const partiesRes = await get('parties');
          if (partiesRes.ok) {
            const data = await partiesRes.json();
            const { id } = data?.parties[0].loans[0];
            // Fetch loan
            const loanRes = await get(`forgivenesses/${id}`);
            if (loanRes.ok) {
              const loan: ILoan = await loanRes.json();
              setLoan({
                ...loan,
                lender: loan.lenderName,
                forgivenessAmount: loan.forgivenAmount,
                remainingPrincipal: loan.outstandingPrincipal
              });
            } else {
              const status = loanRes.status;
              setHasError(status);
              datadogRum.addError(status);
              if (process.env.NODE_ENV === 'development') {
                console.error(`'forgivenesses' status is ${status}`);
              }
              throw status;
            }
          } else {
            throw partiesRes.status;
          }
        } catch (e) {
          if (process.env.NODE_ENV === 'development') {
            console.error(e);
          }
          datadogRum.addError(e);
          setHasError(true);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, isAuthenticated]);

  if (loading) {
    return <LoadingSpinner>Loading...</LoadingSpinner>;
  }

  return (
    <Wrapper>
      <StyledLoanBalance
        loan={loan}
        description={`Your SBA PPP Loan was issued by ${loan.lenderName}, our Banking Partner. Payments will be made directly to ${loan.lenderName} on any outstanding balances, including any accrued interest.`}
      />
      <StyledContactUs
        callToAction="How can we help?"
        customerServiceText="Our team of customer service specialists are here to answer any questions you may have."
        customerServiceHeading="Customer Service"
        phone="1-888-998-6759"
        phoneFormat="#-###-###-####"
        email="PPPForgiveness@ondeck.com"
        hours="M-F 9am-8pm ET"
      />
    </Wrapper>
  );
};

export default ForgivenessPage;
