import React, { createContext, useContext, useEffect, useState } from 'react';
import useFetch from 'use-http';

const PartiesAPIContext = createContext({});

export const PartiesAPIProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [partiesData, setPartiesData] = useState<{} | null | undefined>(null);
  const { get, response } = useFetch(window.env.REACT_APP_API_URL, {
    cacheLife: 5000
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  async function fetchData() {
    const initialData = await get('/parties');
    setPartiesData(initialData);
    const { ok, status, statusText } = response;
    if (!ok) {
      throw new Error(
        `The Parties API returned an error ${status}: ${statusText}`
      );
    }
  }

  return (
    <PartiesAPIContext.Provider value={{ partiesData, setPartiesData }}>
      {children}
    </PartiesAPIContext.Provider>
  );
};

export const usePartiesAPI = (): any => useContext(PartiesAPIContext);
