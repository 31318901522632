import React, { useEffect, useReducer, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useFetch, CachePolicies } from 'use-http';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  DropDown,
  IconDownload,
  Label,
  Link,
  mediaQuery as mQ,
  PageTitle,
  Table
} from '@bazaar/components';
import { useIntl } from 'react-intl';

import { handleDocumentDownloadJWT } from 'lib';
import { RecentDocumentType } from 'lib/constants';
import { filterByDate, filterByLoanId, filterByType } from './filters';

const Grid = styled.div`
  display: grid;
  gap: 3em;
  margin-top: 3em;
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 3em;
`;

const StyledLabel = styled(Label)`
  text-transform: uppercase;
`;

const CustomDiv = styled.div`
  >span: first-child {
    display: none;
  }
  ${mQ.md`
    > span: first-child {
      display: inline;
    }
  `}
`;

const documentsReducer = (
  state: {
    displayedDocuments: number;
    filterValues: string[];
  },
  action: { type: 'FILTER' | 'LOAD_MORE'; payload?: any }
) => {
  switch (action.type) {
    case 'LOAD_MORE':
      return {
        ...state,
        displayedDocuments: state.displayedDocuments + 10
      };
    case 'FILTER':
      const filterValues = [
        ...state.filterValues.slice(0, action.payload.index),
        action.payload.value,
        ...state.filterValues.slice(action.payload.index + 1)
      ];
      return {
        ...state,
        filterValues
      };
    default:
      return state;
  }
};

const DocumentsCard = ({
  documents,
  filters = [],
  title
}: {
  documents: DocumentRow[];
  filters?: DocumentFilter<any>[];
  title: string;
}) => {
  const initialState = {
    displayedDocuments: 5,
    filterValues: filters.map(filter => filter.value)
  };
  const intl = useIntl();
  const [state, dispatch] = useReducer(documentsReducer, initialState);
  const filteredDocuments = filters.reduce(
    (
      filteredDocs: DocumentRow[],
      filter: DocumentFilter<any>,
      index: number
    ) => {
      return filter.func(filteredDocs, state.filterValues[index]);
    },
    documents
  );

  return (
    <Card>
      <CardHeader>
        {title}
        <div style={{ display: 'flex' }}>
          {filters.map((filter: DocumentFilter<any>, index: number) => {
            return (
              <div key={index} style={{ marginRight: '13px' }}>
                <DropDown
                  key={index}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    dispatch({
                      type: 'FILTER',
                      payload: {
                        index,
                        value: e.target.value
                      }
                    })
                  }
                  value={state.filterValues[index]}
                  options={filter.options}
                />
              </div>
            );
          })}
        </div>
      </CardHeader>
      <CardContent>
        <Table
          rows={
            filteredDocuments &&
            filteredDocuments.slice(0, state.displayedDocuments)
          }
        />
        {state.displayedDocuments < filteredDocuments?.length && (
          <ButtonWrapper>
            <Button
              onClick={() => dispatch({ type: 'LOAD_MORE' })}
              variant="light"
            >
              {intl.formatMessage({ id: 'documents.loadMore.action' })}
            </Button>
          </ButtonWrapper>
        )}
      </CardContent>
    </Card>
  );
};

const Documents = () => {
  const { get: getDocuments } = useFetch(
    `${window.env.REACT_APP_API_URL}/documents`
  );
  const { get: getDocumentToken } = useFetch(
    `${window.env.REACT_APP_API_URL}/documents/token`,
    {
      cachePolicy: CachePolicies.NO_CACHE
    }
  );
  const intl = useIntl();
  const [statementsData, setStatementsData] = useState<Array<DocumentRow>>();
  const [otherDocumentsData, setOtherDocumentsData] = useState<
    Array<DocumentRow>
  >();

  useEffect(() => {
    const fetchDocuments = async () => {
      const documentsData: RecentDocument[] = await getDocuments();
      const statements: DocumentRow[] = [];
      const otherDocuments: DocumentRow[] = [];
      const anchorColor = getComputedStyle(
        window.document.documentElement
      ).getPropertyValue('--color-anchor');
      documentsData.forEach(document => {
        const newLabel =
          moment().startOf('day').diff(moment(document.createdOn), 'days') < 30;
        const downloadDocument = handleDocumentDownloadJWT(
          document,
          getDocumentToken
        );
        let loanType,
          loanId = '';
        if (document.loanType) {
          if (document.loanType === 'FIXED_TERM') loanType = 'Term Loan';
          else loanType = 'Line of Credit';
        }
        if (document.loanId) loanId = `(..${document.loanId.slice(-4)})`;
        if (document.documentType === 'MONTHLY_STATEMENT') {
          statements.push([
            <CustomDiv key={document.documentId}>
              <span>{loanType}</span>
              <span>{loanId}</span>
            </CustomDiv>,
            document.fileName.split('.')[0],
            intl.formatDate(document.createdOn),
            newLabel ? (
              <StyledLabel key={document.documentId} color="green">
                {intl.formatMessage({ id: 'common.new' })}
              </StyledLabel>
            ) : (
              ''
            ),
            <Link
              title={`Download ${document.fileName}`}
              href={`${window.env.REACT_APP_API_URL}/documents/${document.fileName}`}
              key={`link-${document.documentId}`}
              onClick={downloadDocument}
            >
              <IconDownload fill={anchorColor} />
            </Link>
          ]);
        } else {
          otherDocuments.push([
            <CustomDiv key={document.documentId}>
              <span>{loanType}</span>
              <span>{loanId}</span>
            </CustomDiv>,
            RecentDocumentType[document.documentType],
            intl.formatDate(document.createdOn),
            newLabel ? (
              <StyledLabel key={document.documentId} color="green">
                {intl.formatMessage({ id: 'common.new' })}
              </StyledLabel>
            ) : (
              ''
            ),
            <Link
              title={`Download ${document.fileName}`}
              href={`${window.env.REACT_APP_API_URL}/documents/${document.fileName}`}
              onClick={downloadDocument}
              key={`link-${document.documentId}`}
            >
              <IconDownload fill={anchorColor} />
            </Link>
          ]);
        }
      });
      setStatementsData(statements);
      setOtherDocumentsData(otherDocuments);
    };
    fetchDocuments();
  }, [getDocuments, getDocumentToken, intl]);
  return (
    <>
      <PageTitle title={intl.formatMessage({ id: 'documents.title' })} />
      <Grid>
        {statementsData && (
          <DocumentsCard
            documents={statementsData}
            filters={[
              filterByLoanId(statementsData),
              filterByDate(statementsData)
            ]}
            title={intl.formatMessage({
              id: 'documents.monthlyStatements.title'
            })}
          />
        )}
        {otherDocumentsData && (
          <DocumentsCard
            documents={otherDocumentsData}
            filters={[filterByLoanId(otherDocumentsData), filterByType]}
            title={intl.formatMessage({ id: 'documents.otherDocuments.title' })}
          />
        )}
      </Grid>
    </>
  );
};
export default Documents;
