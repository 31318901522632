import React from 'react';
import styled from 'styled-components';

import Label from '../Label/Label';

const ProgressWrapper = styled.div`
  --label-font-size: 1.6rem;
  --label-font-weight: 400;
  position: relative;
  width: 100%;
`;

const ProgressLabel = styled(Label)<ProgressProps>`
  display: inline-block;
  position: relative;
  bottom: 10px;
  min-width: 60px;
  text-align: center;
  transform: translateX(-50%);
  font-weight: var(--fontWeightBold);
  ${p => `left: ${p.value}%;`}
`;

const Meter = styled.div`
  background-color: #e7e7f4;
  border-radius: 8px;
  display: flex;
  height: 17px;
  overflow: hidden;
  padding: 3px;
  width: 100%;
`;

const Bar = styled.div<ProgressProps>`
  background-color: var(--color-brand);
  border-radius: 4px;
  height: 100%;
  ${p => `width: ${p.value}%;`}
`;

interface ProgressProps {
  label?: boolean;
  value: number;
}

const ProgressBar: React.FC<ProgressProps> = ({
  label,
  value
}: ProgressProps): React.ReactElement => {
  value = isNaN(value) ? 0 : value;
  return (
    <ProgressWrapper>
      <div>
        {label && (
          <ProgressLabel color="green" value={value} variant="bubble">
            {`${value}%`}
          </ProgressLabel>
        )}
      </div>
      <Meter>
        <Bar value={value} />
      </Meter>
    </ProgressWrapper>
  );
};

export default ProgressBar;
