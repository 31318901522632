import { css, FlattenSimpleInterpolation } from 'styled-components';

interface Breakpoints {
  [key: string]: string;
  lg: string;
  md: string;
  sm: string;
}

export const breakpoints = {
  lg: '992px',
  md: '768px',
  sm: '576px'
} as Breakpoints;

interface MediaQueryMixin {
  [key: string]: (x: TemplateStringsArray) => FlattenSimpleInterpolation;
  lg(x: TemplateStringsArray): FlattenSimpleInterpolation;
  md(x: TemplateStringsArray): FlattenSimpleInterpolation;
  sm(x: TemplateStringsArray): FlattenSimpleInterpolation;
}

export const mediaQuery = Object.keys(breakpoints).reduce(
  (accumulator: MediaQueryMixin, label: string) => {
    accumulator[label] = (args: TemplateStringsArray) => css`
      @media (min-width: ${breakpoints[label]}) {
        ${css(args)};
      }
    `;
    return accumulator;
  },
  {} as MediaQueryMixin
);

export async function downloadWithAuthentication(
  href: string,
  filename: string,
  authToken: string
): Promise<void> {
  const response = await fetch(href, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + authToken
    }
  });
  const blob = await response.blob();
  const objURL = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = objURL;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  link.remove();
}
