import React, { memo, useEffect, useState } from 'react';
import useFetch from 'use-http';
import { LOCDetails, UpcomingPayments } from '@bazaar/components';
import styled from 'styled-components';

const Divider = styled.div`
  border-top: 1px solid #e7e7f4;
  height: 1px;
  margin: 0 20px;
`;

const LOCView = ({
  done,
  loanByIdHref,
  locDetails
}: {
  done: React.Dispatch<React.SetStateAction<boolean>>;
  loanByIdHref?: string;
  locDetails?: LOC;
}) => {
  const [upcomingPayments, setUpcomingPayments] = useState<NextPayment>();
  const { get: getUpcomingPayments, response: upcomingPaymentsRes } = useFetch(
    `${loanByIdHref}/payment/next`
  );
  useEffect(() => {
    const fetchUpcomingPayments = async () => {
      const hrefResponse = await getUpcomingPayments();
      if (upcomingPaymentsRes.ok) {
        done(true);
        const modifiedHref = {
          payment: {
            amount: hrefResponse.nextPaymentAmount,
            scheduledDate: hrefResponse.nextPaymentDate
          },
          ...hrefResponse
        };
        setUpcomingPayments(modifiedHref);
      }
    };
    if (loanByIdHref) {
      fetchUpcomingPayments();
    }
  }, [getUpcomingPayments, done, loanByIdHref]);

  return (
    <div>
      <LOCDetails {...locDetails} />
      <Divider />
      <UpcomingPayments {...upcomingPayments} />
    </div>
  );
};

export default memo(LOCView);
