import React, { cloneElement, useCallback, useState } from 'react';
import styled from 'styled-components';
import AccordionItem from './AccordionItem';

interface AccordionProps {
  children: typeof AccordionItem;
  defaultActiveItems?: number[];
  onItemToggling?: (index: number, obj: any) => void;
  multiple?: boolean;
  scrollIntoView?: boolean;
}

const Wrapper = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 17px #0000000d;
  border-radius: 10px;
  opacity: 1;
`;

const Accordion: React.FC<AccordionProps> = ({
  children,
  defaultActiveItems = [],
  onItemToggling = () => null,
  multiple = false,
  ...rest
}: AccordionProps) => {
  const [activeItems, setActiveItems] = useState(defaultActiveItems);

  const toggleItemActive = useCallback(
    index => {
      const isActive = activeItems.includes(index);

      if (isActive) {
        setActiveItems(activeItems.filter(i => i !== index));
        onItemToggling(index, { active: false });
      } else {
        setActiveItems(multiple ? [...activeItems, index] : [index]);
        onItemToggling(index, { active: true });
      }
    },
    [activeItems, multiple, onItemToggling]
  );

  const childrenArray = Array.isArray(children) ? children : [children];
  return (
    <Wrapper {...rest}>
      {childrenArray.map((item, index) =>
        cloneElement(item, {
          ...rest,
          ...item.props,
          key: `${item.props.title}_${index}`,
          index,
          toggleItemActive,
          isActive: activeItems.includes(index)
        })
      )}
    </Wrapper>
  );
};

export default Accordion;
