import React, { memo, ReactElement, useEffect, useState } from 'react';
import useFetch, { CachePolicies } from 'use-http';
import { datadogRum } from '@datadog/browser-rum';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import styled from 'styled-components';
import {
  Card,
  CardHeader,
  CardContent,
  DatePicker,
  Alert,
  CheckBox
} from '@bazaar/components';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Input } from '@bazaar/components';
import { mediaQuery } from '@bazaar/components';
import { format } from 'date-fns';
import Skeleton from 'react-loading-skeleton';
import { RadioButton } from '@bazaar/components';
import { Link } from '@bazaar/components';
import { FormatDate } from 'router/functions/FormatDate';
import { DollarFormat } from 'router/functions/DollarFormat';
import { TrimAccountNumber } from 'router/functions/TrimAccountNumber';
import { FormatForPhoneNumber } from 'router/functions/FormatForPhoneNumber';

const CustomInput = styled(Input)`
  margin-top: 0px;
  padding-left: 25px;
  .prefix {
    background: #000082 0% 0% no-repeat padding-box;
    border-radius: 5px 0px 0px 5px;
    color: #ffffff;
    border: 1px solid #000082;
    width: 52px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 0;
  }
  .styled-input {
    width: 200px;
    border-radius: 0px 5px 5px 0px;
  }
  .styled-input:focus {
    outline: 1px solid #000082;
  }
  .label {
    display: none;
  }
`;

const StyledCard = styled(Card)`
  --card-content-padding: 0.5em 1.5em 1.5em;
  box-shadow: none;
`;

const StyledCardHeader = styled(CardHeader)`
  padding: 1.3em 1.5em 0em 1.5em;
`;

const HeaderContent = styled.div`
  font-size: 0.75em;
  font-weight: var(--fontWeightBody);
  ${mediaQuery.md`
     margin: 0.6em 0;
  `}
`;

const Divider = styled.div`
  border-top: 1px solid #e7e7f4;
  height: 1px;
  margin: 0 20px;
`;

const PaymentDetails = styled.div`
  display: grid;
  grid-gap: 0em 3em;

  > div {
    display: flex;
    align-items: center;
  }

  > header {
    font-weight: var(--fontWeightBold);
    padding-top: 1.3em;
  }

  ${mediaQuery.md`
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  `}
`;

const Legal = styled.div`
  display: grid;
  grid-template-columns: 1fr 11fr;

  margin: 1em 0em;
`;

const Confirmation = styled.div`
  font-size: 1.7rem;
  margin-bottom: 1.2em;
  font-weight: var(--fontWeightBody);
  ${mediaQuery.md`
     margin: 0.6em 0;
  `}
`;

const ErrorAmountMessage = styled.div`
  font-size: 1.4rem;
  color: #d0021b;
  width: 245px;
  margin-left: 25px;
  padding-bottom: 15px;
`;

const DelayMessage = styled.div`
  font-size: 1.3rem;
  padding-top: 20px;
`;

const Font4 = styled.div`
  font-size: 1.4rem;
`;

const Font3 = styled.div`
  font-size: 1.3rem;
`;

export const FlexWrapper = styled.div`
  display: flex;
  > div {
    padding-top: 1px;
  }
`;

interface AlertProps {
  color?: string;
  children: React.ReactNode;
  title?: React.ReactNode;
}

interface PrelimData {
  bankName: string;
  remainingBalance: number;
  upperDraw: number;
  lowerDraw: number;
  pastDue: number;
  bankAccount: string;
  nextPaymentAmount: number;
  nextPaymentDate: string;
  nextBusinessDay: string;
}

// mask used by Input fields to format string as money
const currencyMask = createNumberMask({
  allowDecimal: true,
  decimalLimit: 2,
  integerLimit: 7,
  prefix: ''
});

const LoanOTPView = ({
  loanId,
  loanType,
  setCustomPaymentAmount,
  setCustomPaymentDate,
  setShowSubmitButton,
  setAlert
}: {
  loanId?: string;
  loanType: string;
  setCustomPaymentAmount: React.Dispatch<React.SetStateAction<number>>;
  setCustomPaymentDate: React.Dispatch<React.SetStateAction<string>>;
  setShowSubmitButton: React.Dispatch<React.SetStateAction<boolean>>;
  setAlert: React.Dispatch<React.SetStateAction<AlertProps | undefined>>;
}) => {
  // const [amtRadio, setAmtRadio] = useState<string>('customAmtRadio');
  const [prelimData, setPrelimData] = useState<PrelimData>();
  const [overPayment, setOverPayment] = useState<boolean>(false);
  const [underPayment, setUnderPayment] = useState<boolean>(false);
  const [paymentEdited, setPaymentEdited] = useState<boolean>(false);
  const [overRemainingBalance, setOverRemainingBalance] = useState<boolean>(
    false
  );
  const [acknowledged, setAcknowledged] = useState<boolean>(false);
  const [nextPaymentAmount, setNextPaymentAmount] = useState<string>('');

  const prelimDataHref = `${window.env.REACT_APP_API_URL}/otpPreliminaryData/loan/${loanId}`;
  const { get: getPrelimData, response: prelimDataRes } = useFetch(
    prelimDataHref,
    {
      data: [],
      cachePolicy: CachePolicies.NO_CACHE
    },
    []
  );

  /**
   * Fetches all data to populate page
   */
  const getLoanInformation = async () => {
    const newDrawDetails = await getPrelimData();
    if (prelimDataRes.ok) {
      newDrawDetails.bankAccount = TrimAccountNumber(
        newDrawDetails.bankAccount
      );
      setNextPaymentAmount(DollarFormat(newDrawDetails.nextPaymentAmount));
      setPrelimData(newDrawDetails);
      // if this ever works, remove alert
      setAlert(undefined);
    } else if (prelimData === undefined) {
      datadogRum.addError('Connection error. Please try again later');
      setAlert({
        color: 'red',
        children: 'Connection error. Please try again later'
      });
    }
  };

  useEffect(() => {
    const setUpDate = async () => {
      await getLoanInformation();
    };
    if (loanId) {
      setUpDate();
    }
  }, []);

  /**
   * Sets the submit buttons visibility
   * Run after acknowledgment and value change
   */
  useEffect(() => {
    setShowSubmitButton(
      acknowledged && paymentEdited && !overPayment && !underPayment
    );
  }, [
    acknowledged,
    overPayment,
    underPayment,
    paymentEdited,
    setShowSubmitButton
  ]);

  const bankAccountStub =
    (prelimData?.bankName ?? '') + ' (' + (prelimData?.bankAccount ?? '') + ')';
  const scheduledPaymentDate = FormatDate(prelimData?.nextPaymentDate);
  const nextBusinessDate = FormatDate(prelimData?.nextBusinessDay);

  const acknowledgeHandler = (evt: any) => {
    setAcknowledged(evt.target.checked);
  };

  /**
   * Checks submitted value and checks based on limits
   * Runs ever value change
   * @param evt
   */
  const customAmountChangeHandler = (evt: any) => {
    setPaymentEdited(true);
    // convert string from input to number
    const customPaymentAmountNumber = Number(
      evt.target.value.replaceAll(',', '')
    );
    //check to make sure payment isn't above upper limit
    customPaymentAmountNumber > (prelimData?.upperDraw ?? 0)
      ? setOverPayment(true)
      : setOverPayment(false);

    //check to make sure payment isn't above remaining balance
    //this is only used to decide to show a message on LOCs
    customPaymentAmountNumber > (prelimData?.remainingBalance ?? 0)
      ? setOverRemainingBalance(true)
      : setOverRemainingBalance(false);

    //check to make sure payment isn't under lower limit
    customPaymentAmountNumber < (prelimData?.lowerDraw ?? 0)
      ? setUnderPayment(true)
      : setUnderPayment(false);

    setCustomPaymentAmount(customPaymentAmountNumber);
  };

  return (
    <>
      <StyledCard>
        <StyledCardHeader>
          {prelimData?.remainingBalance ? (
            <Confirmation id="OTP_RemainingBalanceContainer">
              <FormattedNumber
                value={prelimData?.remainingBalance}
                maximumFractionDigits={2}
                minimumFractionDigits={2}
              >
                {(remainingBalance: string) => (
                  <FormattedMessage
                    id="payment.content.remainingBalance"
                    values={{ remainingBalance }}
                  />
                )}
              </FormattedNumber>
            </Confirmation>
          ) : (
            <div style={{ width: '35%' }}>
              <Skeleton />
            </div>
          )}
          <HeaderContent id="OTP_LoanIdContainer">
            {loanId ? (
              <FormattedMessage
                id="loanDetails.loanId.display"
                values={{
                  id: loanId
                }}
              />
            ) : (
              <div style={{ width: '150px', height: '20px' }}>
                <Skeleton />
              </div>
            )}
          </HeaderContent>
        </StyledCardHeader>
        <CardContent>
          {loanType !== 'term' && (
            <Font3>
              <FormattedMessage id="payment.content.payoffInfo" />
              <sup>*</sup>
            </Font3>
          )}
        </CardContent>
      </StyledCard>
      <Divider />

      <StyledCard>
        <StyledCardHeader>
          <FormattedMessage id="payment.content.header" />
        </StyledCardHeader>
        <CardContent>
          <FlexWrapper>
            <RadioButton
              id="OTP_PaymentOption"
              checked={true}
              value={'PAYMENT_OPTION'}
            />
            <div>{'Custom Amount'}</div>
          </FlexWrapper>
          <CustomInput
            id="OTP_CustomPayment"
            onChange={customAmountChangeHandler}
            prefix={(<span>{'$'}</span>) as ReactElement & string}
            mask={currencyMask}
            placeholder="0.00"
            error={overPayment || underPayment}
          />
          {overPayment && (
            <ErrorAmountMessage>
              {loanType === 'term' ? (
                <FormattedMessage id="payment.overpayment.term.msg" />
              ) : (
                <FormattedMessage id="payment.overpayment.loc.msg" />
              )}
            </ErrorAmountMessage>
          )}
          {underPayment && (
            <ErrorAmountMessage>
              <FormattedMessage
                id="payment.underpayment.msg"
                values={{
                  lowerLimit: prelimData?.lowerDraw
                }}
              />
            </ErrorAmountMessage>
          )}
          {overPayment && loanType === 'term' && !overRemainingBalance && (
            <Alert>
              <Font4>
                <FormattedMessage
                  id="payment.overpayment.term.alert.msg.pt1"
                  values={{
                    tel: FormatForPhoneNumber
                  }}
                />
                &mdash;
                <FormattedMessage id="payment.overpayment.term.alert.msg.pt2" />
              </Font4>
            </Alert>
          )}
          {loanType === 'loc' && (
            <DelayMessage>
              <FormattedMessage id="payment.content.paymentDelayMessage" />
            </DelayMessage>
          )}
        </CardContent>
      </StyledCard>
      <Divider />

      <StyledCard>
        <CardContent>
          {/* date of payment and account source details */}
          {prelimData?.nextBusinessDay ? (
            <PaymentDetails>
              <header>
                <FormattedMessage id="payment.date" />
              </header>
              <div id="OTP_NextBusinessDay">Next Business Day</div>
              {/* <DatePicker
              name="date"
              popPosition="bottom-start"
              ref={register({ required: true })}
              /> */}
              <header>
                <FormattedMessage id="payment.bank.header" />
              </header>
              <div id="OTP_Bank">{bankAccountStub}</div>
            </PaymentDetails>
          ) : (
            <div style={{ width: '50%' }}>
              <Skeleton />
            </div>
          )}

          {/* reminder of additional upcoming payments */}
          {prelimData?.nextPaymentDate ? (
            <Alert color="alertBlue">
              <Font4 id="OTP_BlueBanner">
                {loanType === 'term' ? (
                  <FormattedMessage
                    id="payment.content.nextAutoPayment.term"
                    values={{
                      nextPaymentAmount: nextPaymentAmount,
                      nextPaymentDate: scheduledPaymentDate
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id="payment.content.nextAutoPayment.loc"
                    values={{
                      nextPaymentAmount: nextPaymentAmount,
                      nextPaymentDate: scheduledPaymentDate
                    }}
                  />
                )}
              </Font4>
            </Alert>
          ) : (
            <>
              <div style={{ width: '75%' }}>
                <Skeleton />
              </div>
              <div style={{ width: '50%' }}>
                <Skeleton />
              </div>
            </>
          )}

          {/* legal disclosures and important information that must be confirmed by user */}
          <Font3>
            {loanType === 'term' ? (
              <FormattedMessage id="payment.content.disclaimer.term" />
            ) : (
              <>
                <sup>*</sup>
                <FormattedMessage id="payment.content.disclaimer.loc" />
              </>
            )}
            <Legal>
              <CheckBox id="OTP_LegalCheckBox" onChange={acknowledgeHandler} />
              <FormattedMessage id="payment.content.confirmation" />
            </Legal>
          </Font3>
        </CardContent>
      </StyledCard>
    </>
  );
};

export default memo(LoanOTPView);
