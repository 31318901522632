import React from 'react';
import styled from 'styled-components';
import { FormattedDate, FormattedMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import Button from '../Button/Button';
import InfoBox from '../InfoBox/InfoBox';
import Link from '../Link/Link';
import Card, { CardHeader, CardContent } from '../Card/Card';
import FormattedMoney from '../FormattedMoney/FormattedMoney';
import Label from '../Label/Label';
import { useTracking } from 'react-tracking';
import { mediaQuery as mQ } from '../../lib';

const StyledCard = styled(Card)`
  --card-content-padding: 1em 1.5em 1.5em;
  box-shadow: none;
`;

const ContentRow = styled.div`
  display: flex;
  font-size: var(--fontSize4);
  justify-content: space-between;
  flex-direction: column;
  ${mQ.md`
     flex-direction: row;
  `}
`;

const StyledLabel = styled(Label)`
  text-transform: uppercase;
  min-width: 80px;
`;

const Bordered = styled.div`
  display: flex;
  margin-bottom: 1em;
`;

const Border = styled.div`
  ${p => `background-color: var(--color-${p.color})`};
  align-self: stretch;
  border-radius: 8px;
  width: 4px;
`;

const BorderedContent = styled.div`
  margin-left: 10px;
`;

const Paragraph = styled.p`
  margin: 0;
  color: #000;
`;

const BottomContent = styled.div`
  margin-top: 12px;
  color: var(--color-muted);
  font-size: var(--fontSize5);
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

enum ELoanStatus {
  ARRANGEMENT = 'Arrangement',
  NORMAL = 'Normal',
  PAST_DUE = 'Past Due'
}

const loanStatusColor = new Map<string, string>([
  [ELoanStatus.ARRANGEMENT, 'yellow'],
  [ELoanStatus.PAST_DUE, 'red']
]);

interface UpcomingPaymentsProps {
  currency: string;
  frequency: 'DAILY' | 'WEEKLY' | 'MONTHLY';
  loanId: string;
  loanStatus: keyof typeof ELoanStatus;
  onHold: boolean;
  payment: {
    amount: number;
    scheduledDate: string;
  };
  estimatedPaymentsRemaining: number;
}

const UpcomingPayments: React.FC<UpcomingPaymentsProps> = ({
  frequency,
  loanId,
  loanStatus,
  onHold,
  payment
}: UpcomingPaymentsProps): React.ReactElement => {
  let color = 'mint';
  //converting string to date has dumb time zone issue, so we must use a specific format
  const newDateString = payment?.scheduledDate
    ?.replace(/-/g, '/')
    .replace(/T.+/, '');

  const date = newDateString && new Date(newDateString);
  if (loanStatus && loanStatus !== 'NORMAL') {
    color = loanStatusColor.get(ELoanStatus[loanStatus]) || color;
  }
  const { Track } = useTracking({ component: 'Upcoming Payments' });
  return (
    <Track>
      <StyledCard>
        <CardHeader>
          {loanId ? (
            <h4>
              <FormattedMessage id="upcomingPayments.heading" />
            </h4>
          ) : (
            <div style={{ width: '30%' }}>
              <Skeleton />
            </div>
          )}
          {loanStatus !== 'NORMAL' && (
            <StyledLabel color={color}>{ELoanStatus[loanStatus]}</StyledLabel>
          )}
        </CardHeader>
        <CardContent>
          <ContentRow>
            {loanId ? (
              <Bordered>
                <Border color={color} />
                <BorderedContent>
                  <Paragraph>
                    <FormattedMoney value={payment?.amount} />
                  </Paragraph>
                  <Paragraph>
                    {onHold ? (
                      <FormattedMessage id="upcomingPayments.info.onHold" />
                    ) : (
                      date && (
                        <FormattedDate
                          value={date}
                          year="numeric"
                          month="long"
                          day="2-digit"
                          weekday="short"
                        />
                      )
                    )}
                  </Paragraph>
                  <BottomContent>
                    <Paragraph>
                      {onHold ? (
                        <FormattedMessage id="upcomingPayments.autoPay.notEnrolled" />
                      ) : (
                        <FormattedMessage
                          id="upcomingPayments.autoPay.enrolled"
                          values={{
                            frequency: frequency?.toLowerCase()
                          }}
                        />
                      )}
                    </Paragraph>
                  </BottomContent>
                </BorderedContent>
              </Bordered>
            ) : (
              <div style={{ width: '35%', marginBottom: '12px' }}>
                <Skeleton count={2} />
                <BottomContent>
                  <Skeleton count={1} />
                </BottomContent>
              </div>
            )}
            {/*{loanId && (*/}
            {/*  <ButtonWrapper>*/}
            {/*    <Button variant="light">*/}
            {/*      <FormattedMessage id="upcomingPayments.makePayment" />*/}
            {/*    </Button>*/}
            {/*  </ButtonWrapper>*/}
            {/*)}*/}
          </ContentRow>
          <InfoBox loading={!loanId}>
            <FormattedMessage
              id={
                ['PAST_DUE', 'ARRANGEMENT'].includes(loanStatus)
                  ? 'upcomingPayments.info.pastDue'
                  : 'upcomingPayments.info.additionalPayments'
              }
              values={{
                tel: function telFormat(txt: string) {
                  return (
                    <Link href={`tel:${txt}`} target="_self">
                      {txt}
                    </Link>
                  );
                }
              }}
            />
          </InfoBox>
        </CardContent>
      </StyledCard>
    </Track>
  );
};

export default UpcomingPayments;
