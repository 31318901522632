import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Button, Input } from '@bazaar/components';
import { Row, Col } from 'react-grid-system';
import { useIntl } from 'react-intl';
import { usePartiesAPI } from '../../../contexts/PartiesAPI';
import useFetch from 'use-http';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { phoneMaskedInput, phoneNumberRegex } from '../../../lib/constants';

interface EditBusinessInputs {
  phone: string;
}

const InputWrapper = styled.div`
  margin-bottom: 0.5em;
`;

const CustomInput = styled(Input)`
  font-size: 14px;
  width: 100%;
  label {
    margin-bottom: 8px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 1.2em;
  justify-content: center;
`;

const EditBtn = styled(Button)`
  width: 215px;
  height: 44px;
`;

const ErrorBox = styled.div`
  padding: 0px 5px;
`;

const ErrorCode = styled.p`
  color: #d0021b;
  margin: 0px;
  word-break: break-word;
`;
const EditBusinessProfileForm = (props: {
  done: () => void;
  legalName?: string;
  taxId?: string;
  legalEntityType?: string;
  phone?: IContact;
  businessAddress?: IAddress;
  bankAcc?: string;
  setBusinessDetails: React.Dispatch<
    React.SetStateAction<BusinessProfile | undefined>
  >;
}) => {
  const { partiesData } = usePartiesAPI();
  const patchContactHref = `${partiesData.parties[0].businesses[0].href}/contacts`;
  const { patch: patchBusinessContact } = useFetch(patchContactHref);
  const intl = useIntl();
  const schema = yup.object({
    phone: yup
      .string()
      .required(intl.formatMessage({ id: 'common.required' }))
      .matches(
        phoneNumberRegex,
        intl.formatMessage({ id: 'common.incorrectFormat' })
      )
  });
  const {
    register,
    handleSubmit,
    formState: { dirtyFields, errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { phone: props.phone?.value }
  });
  const patchUserContact = async (
    id?: string,
    type?: string,
    value?: string
  ) => {
    let jsonContact = `{
      "id": "${id}",
      "type": "${type}",
      "value": "${value}"
    }`;
    const newBusinessDetails = await patchBusinessContact(
      `/${id}`,
      JSON.parse(jsonContact)
    );
    if (newBusinessDetails) {
      props.setBusinessDetails(newBusinessDetails);
    }
  };
  const onSubmit = async (data: EditBusinessInputs) => {
    if (dirtyFields.phone) {
      await patchUserContact(props.phone?.id, props.phone?.type, data.phone);
    }
    props.done();
  };
  const address = () => {
    let street = '';
    props.businessAddress?.lines.map(name => {
      street = street.concat(name + ', ');
    });
    return `${street}${props.businessAddress?.city}, ${props.businessAddress?.state}, ${props.businessAddress?.postalCode}`;
  };
  const Error = ({ errorMsg }: { errorMsg: string }) => {
    return (
      <ErrorBox>
        <ErrorCode>{errorMsg}</ErrorCode>
      </ErrorBox>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputWrapper>
        <CustomInput
          id="legalName"
          legalName="email"
          type="text"
          value={props.legalName}
          disabled
        >
          {intl.formatMessage({ id: 'business.inputLabel.legalName' })}
        </CustomInput>
        <Row>
          <Col xs={12} sm={6}>
            <CustomInput
              id="taxId"
              name="taxId"
              type="text"
              value={props.taxId}
              disabled
            >
              {intl.formatMessage({ id: 'business.inputLabel.taxId' })}
            </CustomInput>
          </Col>
          <Col xs={12} sm={6}>
            <CustomInput
              id="entityType"
              name="entityType"
              type="text"
              value={props.legalEntityType}
              disabled
            >
              {intl.formatMessage({ id: 'business.inputLabel.entityType' })}
            </CustomInput>
          </Col>
        </Row>
        <CustomInput
          id="phone"
          name="phone"
          type="text"
          ref={register}
          mask={phoneMaskedInput}
          error={errors.phone}
        >
          {intl.formatMessage({ id: 'business.inputLabel.phone' })}
        </CustomInput>
        {errors.phone?.message && <Error errorMsg={errors.phone.message} />}
        <CustomInput
          id="businessAddress"
          name="businessAddress"
          type="businessAddress"
          value={address()}
          disabled
        >
          {intl.formatMessage({ id: 'business.inputLabel.businessAddress' })}
        </CustomInput>
        <CustomInput
          id="bankAcc"
          name="bankAcc"
          type="bankAcc"
          value={props.bankAcc}
          disabled
        >
          {intl.formatMessage({ id: 'business.inputLabel.bankAcc' })}
        </CustomInput>
      </InputWrapper>
      <ButtonWrapper>
        <EditBtn variant="square" unfilled onClick={props.done}>
          {intl.formatMessage({ id: 'common.cancel' })}
        </EditBtn>
        <EditBtn variant="square" type="submit">
          {intl.formatMessage({ id: 'common.save' })}
        </EditBtn>
      </ButtonWrapper>
    </form>
  );
};

export default EditBusinessProfileForm;
