import React from 'react';
import styled from 'styled-components';
import useMedia from '../../hooks/useMedia';
import { breakpoints, mediaQuery as mQ } from '../../lib';
import Logo from '../../assets/Logo';
import HamburgerBarIcon from '../../assets/images/hamburger-static.svg';
import Link from '../Link/Link';
import { useTracking } from 'react-tracking';

const Wrapper = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  max-width: var(--viewportLg);
  padding: 16px;
  position: relative;
  box-shadow: 0px 8px 21px -2px rgba(0, 0, 0, 0.07);
  > * + * {
    margin-left: 24px;
  }
  ${mQ.lg`
    align-items: start;
    padding: 24px;
  `}
`;

const Hamburger = styled.div.attrs(props => ({
  tabIndex: 0,
  role: 'button'
}))`
  display: inline-block;
  cursor: pointer;
  margin-top: 5px;
`;

interface PageHeaderProps {
  toggleDrawer?: (e: React.MouseEvent) => void;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  toggleDrawer
}: PageHeaderProps) => {
  const isLarge = useMedia([`(max-width: ${breakpoints.lg})`], [false], true);
  const { Track } = useTracking({ component: 'Page Header' });
  const logo = <Logo height="24px" width="113px" />;
  if (isLarge) {
    return null;
  }
  return (
    <Track>
      <Wrapper>
        <Hamburger onClick={toggleDrawer}>
          <img alt="Hamburger Bar" src={HamburgerBarIcon} />
        </Hamburger>
        <Link target="_self" href="/">
          {logo}
        </Link>
      </Wrapper>
    </Track>
  );
};

export default PageHeader;
